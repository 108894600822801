import React, { useEffect, useState, useRef } from "react";

import { Loading } from "../components/Loading";
import { VendorsHeader } from "../components/Layouts/Vendors/VendorsHeader";
import { VendorsTable } from "../components/Layouts/Vendors/VendorsTable";

import { getSuppliers, getVendors, Supplier, Vendor, VendorWithSuppliers } from "../backend-v3/suppliers";
import { useFeedback } from "../hooks/useFeedbackTs";

const Vendors = () => {
  const isMounted = useRef(true);
  // const [suppliers, setSuppliers] = useState<Supplier[]>();
  const [vendors, setVendors] = useState<VendorWithSuppliers[]>();
  const { setError } = useFeedback();

  useEffect(() => {
    getData();

    return () => {
      isMounted.current = false;
    };
  }, []);

  async function getData() {
    // const suppliersRes = await getSuppliers();
    const vendorsRes = await getVendors();

    if (!isMounted.current) return;
    // if (suppliersRes.err) setError(suppliersRes.val.message);
    if (vendorsRes.err) setError(vendorsRes.val.message);

    if (vendorsRes.ok) {
      // setSuppliers(suppliersRes.val);
      setVendors(vendorsRes.val);
    }
  }

  return !vendors ? (
    <Loading />
  ) : (
    <div className="max-w-site mb-8">
      <VendorsHeader />
      <VendorsTable
        // vendor_suppliers={suppliers}
        vendors={vendors}
      />
    </div>
  );
};

export { Vendors };
