import { useState, useCallback, useRef } from "react";

import { useDocumentEvent } from "./useDocumentEvent";

type ModalState<T> = {
  ref: React.MutableRefObject<null>;
  overlay: T;
  setOverlay: (m: T) => void;
};

function useOverlay<T>() {
  const ref = useRef(null);
  const [overlay, setOverlay] = useState<T>();

  const handleClickOutside = useCallback(
    (event: any) => {
      if (!overlay || (ref.current && (ref.current as any).contains(event.target))) return;
      setOverlay(undefined);
    },
    [ref, overlay]
  );

  const handleHideOverlay = useCallback(
    (event: any) => {
      if (event.key === "Escape") setOverlay(undefined);
    },
    [overlay]
  );

  useDocumentEvent([
    { type: "mousedown", callback: handleClickOutside },
    { type: "keydown", callback: handleHideOverlay },
  ]);

  return { ref, overlay, setOverlay };
}

export { useOverlay, ModalState };
