import { useEffect, useState } from "react";
import { FaFile, FaPlus } from "react-icons/fa";
import { Err, Ok } from "ts-results";
import { getAllProducts, ProductVariant, ProductVariantSupplier } from "../../../backend-v3/orders";
import {
  deleteSupplierProduct,
  getSupplierAssignedProducts,
  insertSupplierPvBySku,
  updateSupplierCode,
  updateSupplierCodeReturningPv,
} from "../../../backend-v3/suppliers";
import { useFeedback } from "../../../hooks/useFeedbackTs";
import { ModalState, useOverlay } from "../../../hooks/useOverlayTs";
import { useSupplierCode } from "../../../hooks/useSupplierCodeTs";
import { AddProductModal } from "../../AddProductModal";
import { Button } from "../../ButtonTs";
import { Loading } from "../../Loading";
import { Modal } from "../../Modal";
import { UploadCsvProducts } from "../../UploadCsvProducts";
import { SupplierProductsTable } from "./SupplierProductsTable";

type Props = {
  supplierId: number;
};

const SupplierProducts = ({ supplierId }: Props) => {
  const [supplierVariants, setSupplierVariants] = useState<ProductVariantSupplier[]>();
  const [allProducts, setAllProducts] = useState<ProductVariant[]>();
  const { ref, overlay, setOverlay }: ModalState<"addProducts" | "addProductsFromCsv" | undefined> = useOverlay();
  const [csvData, setCsvData] = useState<{ sku: string; code: string | null }[]>([]);
  const { setError, setSaved, setToast } = useFeedback();

  async function addProducts(productVariantIds: number[]) {
    setSaved(false);
    const res = await updateSupplierCodeReturningPv(
      productVariantIds.map(x => ({ supplier_id: supplierId, product_variant_id: x }))
    );
    if (res.err) {
      setError(res.val.message);
    } else {
      setSupplierVariants(x => [...res.val, ...(x ?? [])]);
    }
    setOverlay(undefined);
    setSaved(true);
  }

  async function addProductsBySku(data: { sku: string; code: string | null }[]) {
    setSaved(false);
    const res = await insertSupplierPvBySku(supplierId, data);
    if (res.err) {
      setError(res.val.message);
    } else {
      setToast(`Added ${res.val.length} products`);

      setSupplierVariants(x => [...res.val, ...(x?.filter(p => !res.val.find(p2 => p2.id === p.id)) ?? [])]);
    }
    setCsvData([]);
    setOverlay(undefined);
    setSaved(true);
  }
  // insertSupplierPvBySku

  async function removeProducts(productVariantId: number) {
    setSaved(false);
    const res = await deleteSupplierProduct(supplierId, productVariantId);
    if (res.err) {
      setError(res.val.message);
    } else {
      setSupplierVariants(x => {
        if (x) {
          return x.filter(each => each.id !== productVariantId);
        }
      });
    }

    setSaved(true);
  }

  useEffect(() => {
    async function getData() {
      const [res, allProdsRes] = await Promise.all([getSupplierAssignedProducts(supplierId), getAllProducts()]);

      if (res.err) setError(res.val.message);
      if (res.ok) {
        setSupplierVariants(res.val);
      }
      if (allProdsRes.err) setError(allProdsRes.val.message);
      if (allProdsRes.ok) {
        setAllProducts(allProdsRes.val);
      }
    }

    getData();
  }, [supplierId]);

  return !supplierVariants || !allProducts ? (
    <Loading />
  ) : (
    <div>
      {overlay == "addProductsFromCsv" && (
        <div ref={ref} className="fixed top-0 right-0 w-full h-screen z-50">
          <Modal
            closeModal={() => setOverlay(undefined)}
            disabled={csvData.length === 0}
            heading="Add Products From CSV"
            isOpen={overlay === "addProductsFromCsv"}
            maxWidth="4xl"
            onSubmit={() => {
              console.log(csvData);
              addProductsBySku(csvData);
            }}
            submitIcon={<FaPlus />}
            submitValue="Add Products"
          >
            <UploadCsvProducts csvData={csvData} setCsvData={setCsvData} />
          </Modal>
        </div>
      )}

      {overlay === "addProducts" && (
        // todo: this works without ref
        <div ref={ref} className="fixed top-0 right-0 w-full h-screen z-50">
          <AddProductModal
            isOpen={overlay === "addProducts"}
            loading={false}
            onClose={() => setOverlay(undefined)}
            onSubmit={productIds => {
              addProducts(productIds);
            }}
            currentProductsVariantIds={supplierVariants.map(v => v.id)}
            allProducts={allProducts}
          />
        </div>
      )}
      <div className="mt-4">
        <div className="flex">
          <Button
            iconBefore={<FaPlus />}
            onClick={(e: any) => {
              e.stopPropagation();
              setOverlay("addProducts");
            }}
            size="small"
            value="Add Products"
            variant="primary"
          />

          <Button
            className="ml-2"
            iconBefore={<FaFile />}
            onClick={(e: any) => {
              e.stopPropagation();
              setOverlay("addProductsFromCsv");
            }}
            size="small"
            value="Upload Products From CSV"
            variant="primary"
          />
        </div>
      </div>

      <SupplierProductsTable
        remove={removeProducts}
        loading={false}
        products={supplierVariants}
        supplierId={supplierId}
      />
    </div>
  );
};

export { SupplierProducts };
