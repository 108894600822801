import React from "react";
import PropTypes from "prop-types";
import { FaPlus } from "react-icons/fa";

import { Button } from "../../ButtonTs";
import { Header } from "../../Header";

const StocktakesHeader = ({ onCreateClick }) => (
  <Header
    actions={
      onCreateClick ? <Button iconBefore={<FaPlus />} onClick={onCreateClick} value="New" variant="primary" /> : null
    }
    crumbs={[{ to: "/stocktakes", value: "Stocktakes" }]}
  />
);

StocktakesHeader.propTypes = {
  onCreateClick: PropTypes.func,
};

StocktakesHeader.defaultProps = {
  onCreateClick: null,
};

export { StocktakesHeader };
