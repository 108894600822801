import React from 'react'
import PropTypes from 'prop-types'
import { FaSyncAlt } from 'react-icons/fa'

import { Modal } from '../../Modal'

const TransferFulfillModal = ({ onClose, loading, isOpen, onSubmit }) => (
  <Modal
    closeModal={onClose}
    heading="Fulfill Transfer"
    loading={loading}
    isOpen={isOpen}
    onSubmit={() => onSubmit()}
    submitIcon={<FaSyncAlt />}
    submitValue="Confirm"
  >
    <div className="mt-4">
      <p>
        This will subtract the stock quantities in Shopify from the origin location and mark the tranfer as{' '}
        <code className="text-orange">Fulfilled</code>.
      </p>
    </div>
  </Modal>
)

TransferFulfillModal.propTypes = {
  isOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

TransferFulfillModal.defaultProps = {
  isOpen: false,
  loading: false,
}

export { TransferFulfillModal }
