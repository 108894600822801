import { Result, Ok, Err } from "ts-results";
import { gql } from "graphql-request";

import { request, post, getUrls, client, result } from "./helpers";

type Jwt = {
  expiry: number;
  user_id: string;
  token: string;
};

type Auth = {
  jwt: Jwt;
  user: {
    id: string;
    shop?: {
      id: number;
      name: string;
      bin_locations_active: boolean;
    };
  };
};

type Credentials = {
  email: string;
  pwd: string;
  shop: string;
};

type ResetPwd = {
  pwd: string;
  token: string;
};

type ResetPwdRequest = {
  email: string;
  shop: string;
};

const getAndSaveUser = async (jwt: Jwt): Promise<Result<Auth, Error>> => {
  const data = await result((await client(jwt)).GetUser({ user_id: jwt.user_id }));

  if (data.err) return data;

  if (!data.val.users_by_pk) return Err(Error("User not found"));
  // if (!data.val.users_by_pk.shop) return Err(Error('Shop not found'))

  let shop;
  if (data.val.users_by_pk?.shop) {
    shop = {
      id: data.val.users_by_pk.shop.id,
      name: data.val.users_by_pk.shop.shop,
      bin_locations_active: data.val.users_by_pk.shop.bin_locations_active,
    };
  } else {
    shop = undefined;
  }

  const auth = {
    jwt: jwt,
    user: {
      id: data.val.users_by_pk.id,
      shop,
    },
  };

  (window as any).auth = auth;

  return Ok(auth);
};

const refreshUser = async (): Promise<Result<Auth, Error>> => {
  let jwt = await post<Jwt>(getUrls().auth("refresh"), null);

  if (jwt.err) return jwt;
  return await getAndSaveUser(jwt.val.parsedBody);
};

const loginUser = async (credentials: Credentials): Promise<Result<Auth, Error>> => {
  let jwt = await post<Jwt>(getUrls().auth("login"), credentials);

  if (jwt.err) return jwt;
  return await getAndSaveUser(jwt.val.parsedBody);
};

const createUser = async (credentials: Credentials): Promise<Result<Auth, Error>> => {
  let jwt = await post<Jwt>(getUrls().auth("register"), credentials);
  if (jwt.err) return jwt;
  return await getAndSaveUser(jwt.val.parsedBody);
};

const resetPwd = async (resetData: ResetPwd): Promise<Result<Auth, Error>> => {
  let jwt = await post<Jwt>(getUrls().auth("reset_password"), resetData);
  if (jwt.err) return jwt;
  return await getAndSaveUser(jwt.val.parsedBody);
};

const resetPwdRequest = async (resetData: ResetPwdRequest): Promise<Result<null, Error>> => {
  let req = await fetch(
    new Request(getUrls().auth("reset_password_request"), {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(resetData),
    })
  );

  if (!req.ok) {
    return Err(Error(req.statusText));
  }

  return Ok(null);
};

const logout = async (): Promise<Result<void, Error>> => {
  let jwt = await post<Jwt>(getUrls().auth("logout"), null);

  if (jwt.err) return jwt;
  return Ok(undefined);
};

async function getAuth(): Promise<Result<Auth, Error>> {
  let auth: Auth;
  const authSaved = (window as any).auth;
  if (authSaved) {
    const expiresIn = (authSaved.jwt.expiry * 1000 - Date.now()) / 1000;
    if (expiresIn < 120) {
      let data = await refreshUser();
      if (data.err) return data;
      auth = data.val;
    } else {
      auth = authSaved;
    }
  } else {
    let data = await refreshUser();
    if (data.err) return data;
    auth = data.val;
  }
  return Ok(auth);
}

export { Jwt, Auth, Credentials, getAuth, refreshUser, loginUser, logout, createUser, resetPwd, resetPwdRequest };
