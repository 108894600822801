import React from "react";

import { Button } from "../../ButtonTs";
import { Header } from "../../Header";
import { Status } from "../../Status";

import { formatDate } from "../../../helpersTs";
import { Transfer } from "../../../backend-v3/transfers";
import * as type from "../../../common/types";
import { OverlayState } from "../../../pages/TransferTs";

type Props = {
  transfer: Transfer;
  parent: type.Parent;
  setModal: (m: OverlayState) => void;
};

const TransferHeader = ({ parent, transfer, setModal }: Props) => {
  const isDraft = transfer.status === "draft";
  const isRequested = transfer.status === "requested";
  const isFulfilled = transfer.status === "fulfilled";

  const transferStatus = transfer.status === "done" ? "received" : transfer.status;

  return (
    <Header
      actions={
        <>
          {isDraft && (
            <Button
              className="ml-2"
              onClick={(e: any) => {
                e.stopPropagation();
                setModal("request");
              }}
              value="Mark as requested"
            />
          )}
          {isRequested && (
            <Button
              className="ml-2"
              onClick={(e: any) => {
                e.stopPropagation();
                setModal("fulfill");
              }}
              value="Mark as fulfilled"
            />
          )}
          {isFulfilled && (
            <Button
              className="ml-2"
              onClick={(e: any) => {
                e.stopPropagation();
                setModal("receive");
              }}
              value="Mark as received"
            />
          )}
        </>
      }
      crumbs={[
        parent && { to: parent.to, value: parent.value },
        {
          to: `${parent?.to}/${transfer.id}`,
          value: (
            <div>
              {transfer.id} <Status className="mx-2 text-sm" status={transferStatus} />{" "}
              <span className="text-black inline-block text-sm font-bold">Created at:</span>{" "}
              <span className="text-black inline-block text-sm">{formatDate(transfer.created_at)}</span>
            </div>
          ),
        },
      ]}

      // {...props}
    />
  );
};

export { TransferHeader };
