import { useContext } from "react";

import { FeedbackContext, Feedback } from "../providers/FeedbackProviderTs";

function useFeedback() {
  const { setError, setSaved, setToast, setLoading, saved, loading } = useContext<Feedback>(FeedbackContext);
  return { setError, setSaved, setToast, setLoading, saved, loading };
}

export { useFeedback };
