/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { SetStateAction } from "react";
import { Dispatch } from "react";
import { Invoice } from "../../../backend-v3/invoices";
import { Search } from "../../../hooks/useOrderTs";
import { DebouncedInput } from "../../DebouncedComponent";
import ClipLoader from "react-spinners/ClipLoader";
import { theme } from "../../../../tailwind.config";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type Props = {
  orderInvoices: Invoice[];
  currentInvoice: Invoice | undefined;
  loadInvoice: (invoiceId?: number) => void;
  createInvoice: (invoiceN?: string) => void;
  searchInvoice: (invoiceN: string) => void;
  search: Search;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  addExistingInvoice: (invoiceId: number) => void;
  deleteOrderInvoice: (invoiceId: number) => void;
  editable: boolean;
};

const SearchInput = ({
  onChange,
  value,
  searching,
  createNewInvoice,
}: {
  onChange: (x: string) => void;
  searching: boolean;
  createNewInvoice: (invoiceNo: string) => void;
  value?: string;
}) => {
  return (
    <div className="relative">
      <input
        type="text"
        value={value}
        className="placeholder:text-gray-300 focus:outline-none px-4 py-2 block w-full sm:text-sm"
        placeholder={"Search or create invoice (press enter)"}
        onChange={e => onChange(e.target.value)}
        onKeyPress={event => {
          if (event.key === "Enter" && value !== undefined) {
            createNewInvoice(value);
          }
        }}
      />

      <span className="absolute inset-y-0 right-0 flex items-center pr-3">
        {searching && <ClipLoader size={14} color={theme.colors["gray-200"]} />}
      </span>
    </div>
  );
};

const DropdownItem = (props: any) => {
  return (
    <DropdownMenu.Item
      tabIndex="-1"
      aria-hidden="true"
      // disables aria tabbing
      textValue=""
      className="cursor-pointer focus:bg-gray-100 focus:text-gray-900 focus:outline-none block px-4 py-2 text-sm"
      {...props}
    />
  );
};

const SCROLLBAR_SIZE = 10;

const StyledScrollArea = styled(ScrollAreaPrimitive.Root, {
  height: 300,
  overflow: "hidden",
});

const StyledViewport = styled(ScrollAreaPrimitive.Viewport, {
  width: "100%",
  height: "100%",
  borderRadius: "inherit",
});

const StyledScrollbar = styled(ScrollAreaPrimitive.Scrollbar, {
  display: "flex",
  // ensures no selection
  userSelect: "none",
  // disable browser handling of all panning and zooming gestures on touch devices
  touchAction: "none",
  padding: 2,
  // background: blackA.blackA6,
  transition: "background 160ms ease-out",
  // "&:hover": { background: blackA.blackA8 },
  '&[data-orientation="vertical"]': { width: SCROLLBAR_SIZE },
  '&[data-orientation="horizontal"]': {
    flexDirection: "column",
    height: SCROLLBAR_SIZE,
  },
});
import { styled } from "@stitches/react";

import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";

const StyledThumb = styled(ScrollAreaPrimitive.Thumb, {
  flex: 1,
  background: "#000",
  borderRadius: SCROLLBAR_SIZE,
  // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    minWidth: 44,
    minHeight: 44,
  },
});

const StyledCorner = styled(ScrollAreaPrimitive.Corner, {
  // background: blackA.blackA8,
});

// Exports
export const ScrollArea = StyledScrollArea;
export const ScrollAreaViewport = StyledViewport;
export const ScrollAreaScrollbar = StyledScrollbar;
export const ScrollAreaThumb = StyledThumb;
export const ScrollAreaCorner = StyledCorner;

const Box = styled("div", {});

export const InvoiceButton = ({
  searchInvoice,
  search,
  createInvoice,
  currentInvoice,
  orderInvoices,
  loadInvoice,
  setSearchTerm,
  searchTerm,
  addExistingInvoice,
  editable,
}: Props) => {
  const [open, setOpen] = useState(false);
  let header: string;

  if (currentInvoice) {
    header = `Invoice ${currentInvoice.invoice_number ?? currentInvoice.id}`;
  } else {
    header = orderInvoices.length > 0 ? "Select invoice" : "Start receiving";
  }

  const createNewInvoice = (invoiceN: string) => {
    createInvoice(invoiceN);
    setOpen(false);
  };

  const orderInvoicesIds = orderInvoices.map(i => i.id);

  return (
    <>
      <div className="mr-2">
        <DropdownMenu.Root
          open={open}
          onOpenChange={open => {
            setSearchTerm("");
            searchInvoice("");
            setOpen(open);
          }}
        >
          <DropdownMenu.Trigger className="inline-flex justify-center w-full rounded-md border border-gray-800 shadow-sm bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 px-4 py-2 font-bold">
            {header}
            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" />
          </DropdownMenu.Trigger>
          <DropdownMenu.Content
            align="start"
            className="-mt-8 w-72 border border-gray-100 rounded-md shadow-md bg-white focus:outline-none"
          >
            {currentInvoice !== undefined && (
              <DropdownItem onSelect={() => loadInvoice()}>Show all invoices</DropdownItem>
            )}
            <DropdownMenu.Group>
              {orderInvoices
                .filter(invoice => invoice.id !== currentInvoice?.id)
                .map(invoice => {
                  return (
                    <DropdownItem key={invoice.id} onSelect={() => loadInvoice(invoice.id)}>
                      {invoice.invoice_number ?? invoice.id}
                    </DropdownItem>
                  );
                })}
            </DropdownMenu.Group>
            {editable && (
              <div className="border-t border-gray-100">
                <DropdownItem asChild={true}>
                  <DebouncedInput
                    Component={SearchInput}
                    props={{
                      createNewInvoice: createNewInvoice,
                      value: searchTerm,
                      searching: search.status === "searching" && searchTerm !== "",
                    }}
                    requestHandler={value => searchInvoice(value)}
                    setValue={value => {
                      setSearchTerm(value);
                    }}
                  />
                </DropdownItem>
              </div>
            )}
            <DropdownMenu.Group className="text-gray-700">
              {search.status === "found" && search.invoices.length > 0 && searchTerm !== "" && (
                <ScrollArea className="">
                  <ScrollAreaViewport css={{ backgroundColor: "" }}>
                    <Box style={{ padding: "" }}>
                      {search.invoices
                        .filter(i => !orderInvoicesIds.includes(i.id))
                        .map(invoice => {
                          return (
                            <DropdownItem key={invoice.id} onSelect={() => addExistingInvoice(invoice.id)}>
                              {invoice.invoice_number}
                            </DropdownItem>
                          );
                        })}
                    </Box>
                  </ScrollAreaViewport>
                  <ScrollAreaScrollbar orientation="vertical">
                    <ScrollAreaThumb />
                  </ScrollAreaScrollbar>
                  <ScrollAreaScrollbar orientation="horizontal">
                    <ScrollAreaThumb />
                  </ScrollAreaScrollbar>
                  <ScrollAreaCorner />
                </ScrollArea>
              )}

              {search.status === "found" && search.invoices.length === 0 && (
                <div className="pl-4 text-gray-300 pb-2 text-sm">Invoice not found</div>
              )}
            </DropdownMenu.Group>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>
    </>
  );
};
