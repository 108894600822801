import React, { FunctionComponent, MouseEventHandler, ReactElement } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import { TableCellInput, Type } from "./TableCellInputTs";

import { theme } from "../../tailwind.config";

type ElementType = {
  type: "element";
  initial?: ReactElement;
};

type Props = {
  alignment?: "left" | "right" | "center";
  capitalize?: boolean;
  loading?: boolean;
  nullable?: boolean;
  slim?: boolean;
  tabIndex?: number;
  width?: string;
  value?: ElementType | Type;
  url?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onChange?: (data: string) => void;
  cacheDeps?: [any];
  flagged?: boolean;
};

const TableCell = ({
  alignment = "left",
  capitalize = true,
  loading = false,
  onChange,
  slim = false,
  tabIndex = 1,
  width = "20",
  value,
  url,
  cacheDeps,
  onClick,
  nullable = true,
  flagged,
}: Props) => {
  function getContent() {
    if (!value) return undefined;

    return url ? (
      <a
        className="underline"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => e.stopPropagation()}
        tabIndex={tabIndex}
      >
        {value.initial}
      </a>
    ) : onClick ? (
      <span className="underline" onClick={onClick} tabIndex={tabIndex}>
        {value.initial}
      </span>
    ) : (
      value.initial
    );
  }

  return (
    <td
      className={`${onChange || slim ? "px-1" : "pl-2"} py-2 ${
        capitalize ? "capitalize" : ""
      } w-${width} text-${alignment}`}
    >
      {loading && <ClipLoader size={14} color={theme.colors["gray-300"]} />}
      {onChange && value && value?.type != "element" && (
        <TableCellInput
          flagged={flagged}
          disabled={loading}
          nullable={nullable}
          onChange={onChange}
          tabIndex={tabIndex}
          type={value}
          cacheDeps={cacheDeps}
        />
      )}
      {!onChange && !loading && getContent()}
    </td>
  );
};

export { TableCell };
