import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { navigate } from "@reach/router";

import { Loading } from "../components/Loading";
import { NotFound } from "./NotFound";
import { SupplierAddVendorModal } from "../components/Layouts/Supplier/SupplierAddVendorModal";
import { SupplierHeader } from "../components/Layouts/Supplier/SupplierHeader";
import { SupplierDetails } from "../components/Layouts/Supplier/SupplierDetails";
import { SupplierVendorsTable } from "../components/Layouts/Supplier/SupplierVendorsTable";
import { Tabs } from "../components/Tabs";

import { useSupplier } from "../hooks/useSupplier";
import { SupplierRemoveVendorModal } from "../components/Layouts/Supplier/SupplierRemoveVendorModal";
import { SupplierProducts } from "../components/Layouts/Supplier/SupplierProducts";

const Supplier = ({ location, parent, supplierId }: any) => {
  const [modal, setModal] = useState<"removeVendor" | "addVendor">();
  const [currentTab, setCurrentTab] = useState("vendors");
  const [currentVendor, setCurrentVendor] = useState();
  const {
    addSupplierVendor,
    isFetchingSupplier,
    isUpdatingSupplier,
    isUpdatingSupplierVendor,
    removeSupplierVendor,
    supplier,
    supplierNotFound,
    updateSupplier,
  } = useSupplier(supplierId);

  useEffect(() => {
    const parsed = queryString.parse(location.search);

    if (parsed.tab) setCurrentTab(parsed.tab as any);
  }, []);

  useEffect(() => {
    if (supplier && !isUpdatingSupplierVendor) setModal(undefined);
  }, [isUpdatingSupplierVendor]);

  function handleRemoveVendor(vendorId: any) {
    setCurrentVendor((supplier as any).vendors.find((v: any) => v.id === vendorId));
    setModal("removeVendor");
  }

  function handleSetTab(tab: any) {
    navigate(`/suppliers/${supplierId}?tab=${tab.toLowerCase()}`);
    setCurrentTab(tab);
  }

  if (supplierNotFound) return <NotFound />;
  if (!supplier || isFetchingSupplier) return <Loading />;

  return (
    <>
      <SupplierHeader onAddVendor={() => setModal("addVendor")} parent={parent} supplier={supplier as any} />
      <div className="text-sm mt-6">
        <div>
          <span className="font-bold">Email:</span> {(supplier as any).email}
        </div>
        {((supplier as any).contactFirstName || (supplier as any).contactLastName) && (
          <div className="mt-2">
            <span className="font-bold">Contact person:</span> {(supplier as any).contactFirstName}{" "}
            {(supplier as any).contactLastName}
          </div>
        )}
      </div>
      <Tabs
        className="w-full mt-8"
        content={
          currentTab === "vendors" ? (
            <SupplierVendorsTable handleRemove={handleRemoveVendor} supplier={supplier as any} />
          ) : currentTab === "details" ? (
            <SupplierDetails loading={isUpdatingSupplier} supplier={supplier as any} updateSupplier={updateSupplier} />
          ) : (
            <SupplierProducts supplierId={(supplier as any).id} />
          )
        }
        currentTab={currentTab}
        maxWidth="none"
        onTabClick={handleSetTab}
        tabs={[
          {
            id: "vendors",
            label: "Vendors",
          },
          {
            className: "mr-2",
            id: "details",
            label: "Details",
          },
          {
            className: "mr-2",
            id: "products",
            label: "Products",
          },
        ]}
      />
      {modal === "addVendor" && (
        <SupplierAddVendorModal
          existingVendors={(supplier as any).vendors}
          isOpen
          loading={isUpdatingSupplierVendor}
          onClose={setModal}
          onSubmit={addSupplierVendor}
        />
      )}
      <SupplierRemoveVendorModal
        isOpen={currentVendor && modal === "removeVendor"}
        loading={isUpdatingSupplierVendor}
        onClose={setModal}
        onSubmit={vendorId => removeSupplierVendor(vendorId)}
        vendor={currentVendor}
      />
    </>
  );
};

Supplier.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  parent: PropTypes.shape({
    to: PropTypes.string,
    value: PropTypes.string,
  }),
  supplierId: PropTypes.string,
};

Supplier.defaultProps = {
  location: { search: "" },
  parent: null,
  supplierId: "",
};

export { Supplier };
