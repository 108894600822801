import React from 'react'

import { Table } from '../../TableTs'
import { TableCell } from '../../TableCellTs'
import { TableHeadCell } from '../../TableHeadCell'
import { TableHeadRow } from '../../TableHeadRow'
import { TableRow } from '../../TableRowTs'

import { roundToTwo } from '../../../helpersTs'
import { StocktakeProductUpdate, StocktakeWithProducts } from '../../../backend-v3/stocktakes'


type Props = {
  printable?: boolean,
  stocktake: StocktakeWithProducts,
  updateStocktakeProduct: (u: StocktakeProductUpdate) => void
}

const StocktakeTable = ({ printable = false, stocktake, updateStocktakeProduct } : Props) => {
  const isDraft = stocktake.stocktake.status === 'draft'
  const isCounted = stocktake.stocktake.status === 'counted'
  return (
    <Table
      childIds={stocktake.products.map((p) => p.product_variant.id)}
      className="mt-6 overflow-hidden"
      renderHead={() => (
        <TableHeadRow>
          <TableHeadCell value="Product" width="1/3" />
          <TableHeadCell value="SKU" width="32" />
          <TableHeadCell value="Qty Expected" />
          {!isDraft && !printable && (
            <>
              <TableHeadCell value="Qty Counted" />
              <TableHeadCell value="Cost Of Goods" />
              <TableHeadCell value="Change" />
              <TableHeadCell value="Value $" />
            </>
          )}
          {printable && <TableHeadCell value="Count" />}
        </TableHeadRow>
      )}

      renderBody={() => (
        <>
          {stocktake.products.map(({ product_variant: {id, sku, full_title, url}, qty_counted, qty_expected, cost }) => {
            return (
              <TableRow key={id}>
                <TableCell width="1/2" value={{type: 'string', initial: full_title}} url={url} tabIndex={-1} />
                <TableCell width="32" capitalize={false} value={{type: 'string', initial: sku}} />
                <TableCell value={{type: 'number', initial: qty_expected}} />
                {!isDraft && !printable && (
                  <>
                    <TableCell
                      
                      onChange={
                        !isCounted && !printable
                          ? value => updateStocktakeProduct({
                            product_variant_id: id,
                            stocktake_id: stocktake.stocktake.id,
                            count: Number(value)
                          })
                          : undefined
                      }
                      value={
                        {
                          type: 'number',
                          nullable: true,
                          initial: qty_counted === 0 || qty_counted ? qty_counted : undefined
                        }}

                    />
                    <TableCell value={{
                      type: 'string',
                      initial: 
                      
                      roundToTwo(cost)
                      
                      
                      }}/>
                    <TableCell value={{
                      type: 'number',
                      initial: qty_counted === 0 || qty_counted ? qty_counted - qty_expected  : 0
                     }}/>
                    <TableCell
                      value={{
                        type: 'string',
                        initial: 
                        (qty_counted === 0 || qty_counted) ? 
                        roundToTwo((qty_counted - qty_expected) * (cost ? cost : 0))
                        : '0.00'
                      }
                      }
                    />
                  </>
                )}
                {printable && <TableCell value={{
                  type: 'string',
                  initial: ''
                }} />}
              </TableRow>
            )
          })}
        </>
      )}
    />
  )
}


export { StocktakeTable }
