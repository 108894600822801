import React from "react";
import PropTypes from "prop-types";

const TableHeadCell = ({ alignment, slim, value, width }) => (
  <th className={`pb-3 ${slim ? "px-1" : "pl-2"} text-${alignment} ${width ? `w-${width}` : ""}`}>{value}</th>
);

TableHeadCell.propTypes = {
  alignment: PropTypes.oneOf(["left", "right", "center"]),
  slim: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node]),
  width: PropTypes.string,
};

TableHeadCell.defaultProps = {
  alignment: "left",
  slim: false,
  value: null,
  width: "20",
};

export { TableHeadCell };
