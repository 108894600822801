import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import { navigate } from '@reach/router'

import { CreateTransferModal } from '../components/Layouts/Transfers/CreateTransferModal'
import { DeleteTransferModal } from '../components/Layouts/Transfers/DeleteTransferModal'
import { Loading } from '../components/Loading'
import { TransfersHeader } from '../components/Layouts/Transfers/TransfersHeader'
import { TransfersTable } from '../components/Layouts/Transfers/TransfersTable'
import { Tabs } from '../components/Tabs'

import { useTransfers } from '../hooks/useTransfersTs';

import { useLocations } from '../hooks/useLocationsTs'
import { useSuppliers } from '../hooks/useSuppliersTs'

import { location } from './common'
import { TransferStatus } from '../backend-v3/transfers'

type ModalState = undefined | 'delete' | 'create'


const Transfers = (location: location) => {
  const [currentTab, setCurrentTab] = useState('draft' as TransferStatus)
  const [currentTransferId, setCurrentTransferId] = useState(undefined as number | undefined)
  const [modal, setModal] = useState(undefined as ModalState)

  const {
    createTransfer,
    deleteTransfer,
    isFetchingTransfers,
    isUpdatingTransfers,
    transfers
  } = useTransfers()

  const { locations, isFetchingLocations } = useLocations()
  const { suppliers, isFetchingSuppliers } = useSuppliers()

  useEffect(() => {
    if (transfers && !isUpdatingTransfers) setModal(undefined)
  }, [isUpdatingTransfers])

  useEffect(() => {
    const parsed: {
      tab?: string
    } = queryString.parse(location.search)

    if (parsed.tab) setCurrentTab(parsed.tab as TransferStatus)
  }, [])

  function handleSetTab(tab: TransferStatus) {
    navigate(`/transfers?tab=${tab.toLowerCase()}`)
    setCurrentTab(tab)
  }

  function handleDeleteOrder(transferId: number) {
    setCurrentTransferId(transferId)
    setModal('delete')
  }

  if (!transfers || !locations || !suppliers || isFetchingTransfers || isFetchingLocations || isFetchingSuppliers)
    return <Loading />

  return (
    <>
      <TransfersHeader onCreateClick={locations.length > 1 ? () => setModal('create') : null} />
      <Tabs
        className="w-full"
        content={
          <TransfersTable
            deleteTransfer={handleDeleteOrder}
            transfers={transfers ? transfers.filter(({ status }) => status === currentTab) : []}
            locations={locations}
            status={currentTab}
          />
        }
        currentTab={currentTab}
        maxWidth="none"
        onTabClick={handleSetTab}
        tabs={[
          {
            id: 'draft',
            label: 'Draft',
          },
          {
            id: 'requested',
            label: 'Requested',
          },
          {
            id: 'fulfilled',
            label: 'Fulfilled',
          },
          {
            id: 'done',
            label: 'Received',
          }
        ]}
      />
      {locations.length > 1 && (
        <CreateTransferModal
          locations={locations}
          isOpen={modal === 'create'}
          loading={isFetchingTransfers}
          onClose={() => setModal(undefined)}
          onSubmit={createTransfer}
        />
      )}
      <DeleteTransferModal
        isOpen={modal === 'delete'}
        loading={isUpdatingTransfers}
        onClose={() => setModal(undefined)}
        onSubmit={() => 
          {if (currentTransferId) deleteTransfer(currentTransferId)}
        }
      />
    </>
  )
}

export { Transfers }
