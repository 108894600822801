import React from "react";
import PropTypes from "prop-types";
import { FaPlus } from "react-icons/fa";

import { Modal } from "../../Modal";

import { PROP_TYPES } from "../../../common/propTypes";
import { InvoiceExpense } from "../../../backend-v3/invoices";

type Props = {
  expense?: InvoiceExpense;
  isOpen?: boolean;
  loading?: boolean;
  onClose: () => void;
  onSubmit: (expenseId: number) => void;
};

const DeleteExpenseModal = ({ expense, isOpen = false, loading = false, onClose, onSubmit }: Props) =>
  expense ? (
    <Modal
      closeModal={onClose}
      heading="Delete Fixed Expense"
      loading={loading}
      isOpen={isOpen}
      onSubmit={() => onSubmit(expense.id)}
      submitIcon={<FaPlus />}
      submitValue="Delete"
    >
      <p className="mt-4">
        This will delete <code className="text-orange">{expense.description}</code> from fixed expenses.
      </p>
    </Modal>
  ) : null;

export { DeleteExpenseModal };
