import React, { useState, useEffect } from 'react'
import { Dropdown } from '../../DropdownTs'
import { Button } from '../../ButtonTs'
import { Location } from '../../../backend-v3/locations'
import { Transfer } from '../../../backend-v3/transfers'


type FilterKey = {
  label: string,
  value: keyof Transfer
}


type Props = {
  locations: Location[],
  transfers: Transfer[],
  setFilteredTransfers: (x: Transfer[]) => void,
  filterKeys: FilterKey[]
}


type FilterKeyValue = 'none' | keyof Transfer

const TransfersFilter = ({ filterKeys, locations, transfers, setFilteredTransfers }: Props) => {
  const [filterKey, setFilterKey] = useState<FilterKeyValue>('none')
  const [filterValue, setFilterValue] = useState<'none' | number>('none')

  useEffect(() => {
    setFilteredTransfers(getFilteredTransfers(filterValue, transfers))
  }, [transfers])

  function getFilteredTransfers(selectedLocation: number | "none", transfersToFilter: Transfer[]) {
    if (filterKey === 'none' || selectedLocation === 'none') return transfers
    const key = filterKeys.find(({ value }) => value === filterKey)?.value
    if (!key)  return transfers
    return transfersToFilter.filter(t => t[key].toString() === selectedLocation.toString())
  }

  function reset() {
    setFilterKey("none")
    setFilterValue("none")
    setFilteredTransfers(transfers)
  }

  function onFilterKeyChange(newKey: string) {
    // ugly, but for now works. in the future refactor for stricter typing
    let newKeyTyped = newKey as FilterKeyValue
    if (newKeyTyped === "none") reset()

    setFilterKey(newKeyTyped)
  }

  function onValueChange(selectedLocation: string | 'none') {

    let value: number | 'none';

    if (selectedLocation !== 'none') {
      value = Number(selectedLocation)
    } else {
      value = 'none'
    }
    setFilteredTransfers(getFilteredTransfers(value, transfers))
    setFilterValue(value)
  }

  return (
    <div className="flex items-end">
      <Dropdown
        className="mr-2 w-48"
        id="filter-key"
        label="Filter Type"
        onChange={onFilterKeyChange}
        options={[{ name: "None", value: 'none' }, ...filterKeys.map(({ label, value}) => ({ name: label, value  }))]}
        value={filterKey}
      />
      <Dropdown
        className="mr-2 w-48"
        disabled={filterKey === 'none'}
        id="filter-value"
        label="Filter Location"
        onChange={onValueChange}
        options={[
          { name: "None", value: "none" },
          ...locations.map(({ id, name }) => ({ value: id.toString(), name })),
        ]}
        value={filterValue.toString()}
      />
      <div>
        <Button onClick={reset} value="Reset Filter" variant="secondary" />
      </div>
    </div>
  )
}

export { TransfersFilter }
