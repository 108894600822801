import React from "react";
import PropTypes from "prop-types";
import { navigate, Link } from "@reach/router";

import { Table } from "../../TableTs";
import { TableCell } from "../../TableCellTs";
import { TableHeadCell } from "../../TableHeadCell";
import { TableHeadRow } from "../../TableHeadRow";
import { TableRow } from "../../TableRowTs";

import { Vendor, VendorWithSuppliers } from "../../../backend-v3/suppliers";

type Props = {
  vendors: VendorWithSuppliers[];
};

const VendorsTable = ({ vendors }: Props) => (
  <Table
    childIds={vendors.map(({ id }) => id)}
    className="mt-6"
    renderHead={() => (
      <TableHeadRow>
        <TableHeadCell value="Vendor Name" width="3/12" />
        <TableHeadCell value="Suppliers" width="4/12" />
      </TableHeadRow>
    )}
    renderBody={({ bulkChecked, onBulkChange }) => (
      <>
        {vendors.map(vendor => {
          return (
            <TableRow key={vendor.id} onClick={() => navigate(`/vendors/${vendor.id}`)}>
              <TableCell
                value={{
                  type: "element",
                  initial: <Link to={`/vendors/${vendor.id}`}>{vendor.name}</Link>,
                }}
              />
              <TableCell
                value={{
                  type: "element",
                  initial: (
                    <>
                      {vendor.suppliers.reduce(
                        (acc, cur, i) => [
                          ...acc,
                          <Link
                            key={cur.id}
                            className="hover:underline"
                            onClick={e => e.stopPropagation()}
                            to={`/suppliers/${cur.id}`}
                          >
                            {cur.name}
                          </Link>,
                          ...[i < vendor.suppliers.length - 1 ? [<span key={`_${cur.id}`}>, </span>] : []],
                        ],
                        [] as any
                      )}
                    </>
                  ),
                }}
              />
            </TableRow>
          );
        })}
      </>
    )}
  />
);

export { VendorsTable };

{
}
