import React, { useState } from "react";
import { navigate } from "@reach/router";
import { FaTrash } from "react-icons/fa";

import { StocktakesFilter } from "./StocktakesFilter";
import { Table } from "../../TableTs";
import { TableCell } from "../../TableCellTs";
import { TableHeadCell } from "../../TableHeadCell";
import { TableSortableColumnHeading } from "../../TableSortableColumnHeading";
import { TableRow } from "../../TableRowTs";
import { TableRowActions } from "../../TableRowActions";

import { formatDate, sortArrayByObjectKey } from "../../../helpersTs";
import { Stocktake, StocktakeStatus } from "../../../backend-v3/stocktakes";
import { Vendor } from "../../../backend-v3/suppliers";
import { Location } from "../../../backend-v3/locations";

import { roundToTwo } from "../../../helpersTs";

type Props = {
  currentTab: StocktakeStatus | "all";
  deleteStocktake: (id: number) => void;
  locations: Location[];
  stocktakes: Stocktake[];
  vendors: Vendor[];
};

const StocktakesTable = ({ currentTab, deleteStocktake, locations, stocktakes, vendors }: Props) => {
  const [filteredStocktakes, setFilteredStocktakes] = useState(
    sortArrayByObjectKey(stocktakes, "stocktake_number", true)
  );
  const [sorting, setSorting] = useState<{
    key: keyof Stocktake;
    ascending: boolean;
  }>({
    key: "updated_at",
    ascending: true,
  });

  const isDraft = currentTab === "draft";

  function sortedStocktakes() {
    const sorted = sortArrayByObjectKey(filteredStocktakes, sorting.key, true);
    return sorting.ascending ? sorted : sorted.reverse();
  }

  function handleSort(key: keyof Stocktake) {
    setSorting({
      key,
      ascending: key === sorting.key ? !sorting.ascending : sorting.ascending,
    });
  }

  return (
    <>
      <StocktakesFilter
        filterKeys={[
          { data: locations.map(l => ({ id: l.id, label: l.name })), label: "Location", value: "location_id" },
          { label: "Stocktake No.", value: "stocktake_number" },
          { data: vendors.map(v => ({ id: v.name, label: v.name })), label: "vendor", value: "vendor_name" },
        ]}
        stocktakes={stocktakes}
        setFilteredStocktakes={setFilteredStocktakes}
      />
      <Table
        className="mt-6 max-w-6xl"
        renderHead={() => (
          <TableRow>
            <TableHeadCell value="Stocktake No." width="32" />
            <TableHeadCell value="Location" width="72" />
            {currentTab === "all" && (
              <TableHeadCell
                value={<TableSortableColumnHeading text="Status" onClick={() => handleSort("status")} />}
                width="72"
              />
            )}
            <TableHeadCell
              value={<TableSortableColumnHeading text="Vendor" onClick={() => handleSort("vendor_name")} />}
              width="72"
            />
            <TableHeadCell
              value={<TableSortableColumnHeading text="Created" onClick={() => handleSort("created_at")} />}
              width="32"
            />

            <TableHeadCell
              value={<TableSortableColumnHeading text="Updated" onClick={() => handleSort("updated_at")} />}
              width="32"
            />

            <TableHeadCell value="Total Value" width="48" />
            {isDraft && <TableHeadCell alignment="right" width="72" value="Actions" />}
          </TableRow>
        )}
        renderBody={() => (
          <>
            {sortedStocktakes().map(stocktake => {
              const { id, created_at, total, updated_at, location, status, stocktake_number, vendor_name } = stocktake;

              const actions = {
                delete: {
                  id: "delete",
                  iconBefore: <FaTrash />,
                  onClick: (e: Event) => {
                    e.stopPropagation();
                    deleteStocktake(id);
                  },
                },
              };

              function getActions() {
                if (isDraft) return [actions.delete];
                return [];
              }

              return (
                <TableRow key={id} onClick={() => navigate(`/stocktakes/${id}`)}>
                  <TableCell url={`/stocktakes/${id}`} value={{ type: "number", initial: stocktake_number }} />
                  <TableCell value={{ type: "string", initial: locations.find(l => l.id === location.id)?.name }} />
                  {currentTab === "all" && <TableCell value={{ type: "string", initial: status.toLowerCase() }} />}
                  <TableCell value={{ type: "string", initial: vendor_name }} />
                  <TableCell value={{ type: "string", initial: formatDate(created_at) }} />
                  <TableCell value={{ type: "string", initial: formatDate(updated_at) }} />
                  <TableCell value={{ type: "string", initial: `$${roundToTwo(total)}` }} />
                  {isDraft && <TableRowActions actions={getActions()} />}
                </TableRow>
              );
            })}
          </>
        )}
      />
    </>
  );
};

export { StocktakesTable };
