import React from 'react'
import PropTypes from 'prop-types'
import { FaSyncAlt } from 'react-icons/fa'

import { Modal } from '../../Modal'

const TransferRequestModal = ({ isOpen, loading, onClose, onSubmit }) => (
  <Modal
    closeModal={onClose}
    heading="Change Transfer Status"
    loading={loading}
    isOpen={isOpen}
    onSubmit={() => onSubmit()}
    submitIcon={<FaSyncAlt />}
    submitValue="Confirm"
  >
    <div className="mt-4">
      <p>
        This will change your transfer status to <code className="text-orange">Requested</code>.
      </p>
    </div>
  </Modal>
)

TransferRequestModal.propTypes = {
  isOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

TransferRequestModal.defaultProps = {
  isOpen: false,
  loading: false,
}

export { TransferRequestModal }
