import React, { useEffect } from "react";
import { FaEye, FaTrash } from "react-icons/fa";

import { Checkbox } from "../../Checkbox";
import { Table } from "../../TableTs";
import { TableCell } from "../../TableCellTs";
import { TableHeadCell } from "../../TableHeadCell";
import { TableHeadRow } from "../../TableHeadRow";
import { TableRow } from "../../TableRowTs";
import { TableRowActions } from "../../TableRowActions";

import { roundToTwo } from "../../../helpersTs";
import { useSupplierCode } from "../../../hooks/useSupplierCodeTs";
import { OrderExtended } from "../../../backend-v3/orders";
import { OrderProductUpdate } from "../../../hooks/useOrderTs";
import { SupplierCodeData } from "../../../backend-v3/suppliers";
import { InvoiceWithProductsExpanded, UpdateOrderProductInvoice } from "../../../backend-v3/invoices";
import { Mapped, mapProducts } from "../../../hooks/useOrderTs";
import { OrderProductsInvoiceFragment } from "../../../generated/graphql";
import { navigate } from "@reach/router";

type Props = {
  invoice: InvoiceWithProductsExpanded;
  loading: boolean;
};

const InvoiceTable = ({ invoice, loading }: Props) => {
  return (
    invoice && (
      <Table
        loading={loading}
        className="mt-6 overflow-hidden"
        renderHead={({ bulkChecked, onBulkChange }) => (
          <TableHeadRow>
            <TableHeadCell value="Product" width="1/3" />
            <TableHeadCell value="Qty Received" />
            <TableHeadCell value="Paid $" />
            <TableHeadCell value="Total $" />

            {<TableHeadCell alignment="right" width="32" value="Actions" />}
          </TableHeadRow>
        )}
        renderBody={({ bulkChecked, onBulkChange }) => (
          <>
            {invoice.products.map(i => {
              return (
                <TableRow key={`${i.product_variant_id}-${i.order_id}`}>
                  <TableCell width="1/3" value={{ type: "string", initial: i.product_variant.display_name }} />
                  <TableCell loading={false} value={{ type: "number", initial: i.qty_received }} />
                  <TableCell loading={false} value={{ type: "number", initial: i.paid_per_unit }} />
                  <TableCell
                    loading={false}
                    value={{ type: "number", initial: Number(roundToTwo(i.qty_received * (i.paid_per_unit ?? 0))) }}
                  />

                  {
                    <TableRowActions
                      actions={[
                        {
                          id: "view_order",
                          iconBefore: <FaEye />,
                          onClick: () => navigate(`/orders/${i.order_id}`),
                          tabIndex: "-1",
                          value: `Order No. ${i.order.order_number}`,
                        },
                      ]}
                    />
                  }
                </TableRow>
              );
            })}
          </>
        )}
      />
    )
  );
};

InvoiceTable.displayName = "InvoiceTable";

export { InvoiceTable };
