import { useEffect, useState, useRef } from "react";
import * as api from "../backend-v3/orders";
import * as reports from "../backend-v3/reports";
import { useFeedback } from "./useFeedbackTs";
import { GetProductHistoryQuery } from "../generated/graphql";

export const isActive = (product: api.ProductVariant): boolean => {
  return (
    (product.product.status === "ACTIVE" &&
      product.deleted_at === undefined &&
      product.product.tags.includes("discontinued") === false) ||
    (product.product.status === "DRAFT" &&
      product.deleted_at === undefined &&
      product.product.tags.includes("discontinued") === false &&
      product.product.tags.includes("incoming") === true)
  );
};

const useProducts = () => {
  const [allProducts, setAllProducts] = useState([] as api.ProductVariant[]);
  const [activeProducts, setActiveProducts] = useState([] as api.ProductVariant[]);

  function setUpProducts(prods: api.ProductVariant[]) {
    setAllProducts(prods);
    setActiveProducts(prods.filter(p => isActive(p)));
  }

  return {
    allProducts,
    setUpProducts,
    setAllProducts,
    activeProducts,
  };
};

const useProductsHistory = (pvid: number) => {
  const isMounted = useRef(true);

  const [isFetchingHistory, setIsFetchingHistory] = useState(false);
  const [productsHistory, setProductsHistory] = useState(undefined as GetProductHistoryQuery | undefined);
  const { setError, setToast } = useFeedback();

  async function getData() {
    setIsFetchingHistory(true);
    const res = await reports.getProductsHistory(pvid);
    if (res.err) {
      setError(res.val.message);
      return;
    }
    setProductsHistory(res.val);
    setIsFetchingHistory(false);
  }

  useEffect(() => {
    getData();
    return () => {
      isMounted.current = false;
    };
  }, []);

  return {
    productsHistory,
    isFetchingHistory,
  };
};

export { useProducts, useProductsHistory };
