import React from "react";
import PropTypes from "prop-types";

const HorizontalTableCell = ({ className, bold, large, value }) => (
  <td
    className={`block py-2 border-b border-gray-100 capitalize
      ${bold ? " font-bold " : " "}
      ${large ? " text-normal " : "text-sm "}
      ${className}`}
  >
    {value}
  </td>
);

HorizontalTableCell.propTypes = {
  className: PropTypes.string,
  bold: PropTypes.bool,
  large: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node]).isRequired,
};

HorizontalTableCell.defaultProps = {
  className: "",
  bold: false,
  large: false,
};

export { HorizontalTableCell };
