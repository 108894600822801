import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'

import { Button } from '../components/ButtonTs'
import { DateRangePicker } from '../components/DateRangePickerTs'
import { Loading } from '../components/Loading'
import { Table } from '../components/TableTs'
import { TableCell } from '../components/TableCellTs'
import { TableHeadCell } from '../components/TableHeadCell'
import { TableHeadRow } from '../components/TableHeadRow'
import { TableRow } from '../components/TableRowTs'
import { roundToTwo, sortArrayByObjectKey } from '../helpersTs'
import { useFeedback } from '../hooks/useFeedbackTs'
import { Range } from '../hooks/useOrderTs'
import { getReports, Report } from '../backend-v3/reports'

type ReportGroupedPerLocation = {
  location: string
  days: Report[]
}

const Reports = () => {
  const initialStartDate = moment().subtract(30, 'days')
  const initialEndDate = moment()
  const isMounted = useRef(true)
  const [loading, setLoading] = useState(false)
  const [dateRange, setDateRange] = useState<Range>({ from: initialStartDate, to: initialEndDate })
  const [report, setReport] = useState<ReportGroupedPerLocation[]>([])
  const { setError } = useFeedback()

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  function processReports(data: Report[]): ReportGroupedPerLocation[] {
    let reports: { [x: string]: Report[] } = {}

    data.forEach(data => {
      let existingData = reports[data.location!.name]
      let reportsAggregated = existingData === undefined ? [] : existingData
      reports[data.location!.name] = [...reportsAggregated, data]
    })

    let reportGroupedPerLocation = Object.keys(reports).map(location => {
      return {
        location,
        days: reports[location]!,
      }
    })

    let reportAggregated: { [date: string]: Report } = {}

    reportGroupedPerLocation.forEach(data => {
      data.days.forEach(day => {
        let current = reportAggregated[day.date.toString()]
        reportAggregated[day.date.toString()] = {
          date: day.date,
          in_transfer: (current?.in_transfer ?? 0) + day.in_transfer,
          in_stock: (current?.in_stock ?? 0) + day.in_stock,
          value_in_transfer: (current?.value_in_transfer ?? 0) + day.value_in_transfer,
          value_on_hand: (current?.value_on_hand ?? 0) + day.value_on_hand,
        }
      })
    })

    return [...reportGroupedPerLocation, { location: 'All locations', days: Object.values(reportAggregated) }]
  }

  async function handleGetReport() {
    setLoading(true)
    const res = await getReports(dateRange)
    if (!isMounted.current) return
    if (res.err) setError(res.val.message)
    else {
      setReport(processReports(res.val))
    }
    setLoading(false)
  }

  return (
    <div className="my-8">
      <h2 className="font-bold">Generate a report</h2>
      <div className="mt-4">
        <label className="font-bold text-sm" htmlFor="positionDateRange">
          Report Date Range
        </label>
        <div className="flex items-center mt-1">
          <div className="mr-2">
            <DateRangePicker id="reportDateRange" maxDate={new Date()} onChange={setDateRange} value={dateRange} />
          </div>
          <Button disabled={loading} onClick={() => handleGetReport()} value="Generate" variant="secondary" />
        </div>
      </div>
      {(loading || report) && (
        <div className="mt-6 max-w-2xl">
          {!loading ? (
            <>
              {report.map(({ location, days }, i) => (
                <div key={location} className={i !== 0 ? 'mt-6' : ''}>
                  <h2>{location}</h2>
                  <Table
                    className="mt-2"
                    emptyState={!days.length && 'Nothing here...'}
                    renderHead={() => (
                      <TableHeadRow>
                        <TableHeadCell value="Date" width="32" />
                        <TableHeadCell alignment="right" value="In Transfer" width="32" />
                        <TableHeadCell alignment="right" value="Transfer Value" width="32" />
                        <TableHeadCell alignment="right" value="On Hand" width="32" />
                        <TableHeadCell alignment="right" value="Stock Value" width="32" />
                      </TableHeadRow>
                    )}
                    renderBody={() => (
                      <>
                        {sortArrayByObjectKey(days, 'date', true).map(
                          ({ date, in_transfer, in_stock, value_in_transfer, value_on_hand }) => (
                            <TableRow key={date.toString()}>
                              <TableCell value={{ type: 'string', initial: date.toString() }} />
                              <TableCell alignment="right" value={{ type: 'number', initial: in_transfer }} />
                              <TableCell
                                alignment="right"
                                value={{ type: 'string', initial: `$${roundToTwo(value_in_transfer)}` }}
                              />
                              <TableCell alignment="right" value={{ type: 'number', initial: in_stock }} />
                              <TableCell
                                alignment="right"
                                value={{ type: 'string', initial: `$${roundToTwo(value_on_hand)}` }}
                              />
                            </TableRow>
                          ),
                        )}
                      </>
                    )}
                  />
                </div>
              ))}
              {report.length < 1 && <div className="mt-4">No data for those dates...</div>}
            </>
          ) : (
            <Loading />
          )}
        </div>
      )}
    </div>
  )
}

export { Reports }
