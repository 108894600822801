import { useEffect, useState, useRef } from "react";
import { navigate } from "@reach/router";

import { sortArrayByObjectKey } from "../helpers";
import { useFeedback } from "./useFeedbackTs";

import {
  removeVendor,
  addVendor,
  getSupplier,
  updateSupplier as update,
  getXeroContacts as getXero,
  findXeroContact as findXero,
  XeroContact,
} from "../backend-v3/suppliers";

const useSupplier = (supplierId: any) => {
  const isMounted = useRef(true);
  const [isFetchingSupplier, setIsFetchingSupplier] = useState<boolean>();
  const [isUpdatingSupplier, setIsUpdatingSupplier] = useState<boolean>();
  const [isUpdatingSupplierVendor, setIsUpdatingSupplierVendor] = useState<boolean>();
  const [supplier, setSupplier] = useState();
  const [supplierNotFound, setSupplierNotFound] = useState();
  const [xeroContacts, setXeroContacts] = useState();
  const { setError, setToast } = useFeedback();
  const [isSearchingXero, setIsSearchingXero] = useState(false);
  const [foundXeroContacts, setFoundXeroContacts] = useState<XeroContact[]>([]);

  useEffect(() => {
    if (supplierId) getData();

    return () => {
      isMounted.current = false;
    };
  }, []);

  async function getData() {
    setIsFetchingSupplier(true);

    const supplierRes = await getSupplier(supplierId);

    if (!isMounted.current) return;
    if (!supplierRes.success) {
      setError(supplierRes as any);
      navigate(-1);
    }
    if (supplierRes.success) setSupplier(supplierRes.data);

    setIsFetchingSupplier(undefined);
  }

  async function addSupplierVendor(vendorId: any) {
    setIsUpdatingSupplierVendor(true);

    const res = await addVendor(supplierId, vendorId);

    if (!isMounted.current) return;
    if (!res.success) setError(res as any);
    if (res.success) {
      setToast("Vendor added!");
      getData();
    }

    setIsUpdatingSupplierVendor(undefined);
  }

  async function getXeroContacts() {
    const res = await getXero();
    if (!isMounted.current) return;
    if (!res.success) setError(res as any);
    if (res.success) setXeroContacts(sortArrayByObjectKey(res.data, "name"));
  }

  async function removeSupplierVendor(vendorId: any) {
    setIsUpdatingSupplierVendor(true);
    const res = await removeVendor(supplierId, vendorId);

    if (!isMounted.current) return;
    if (!res.success) setError(res as any);
    if (res.success) {
      setToast("Vendor removed!");
      setSupplier(prevSupplier => ({
        ...(prevSupplier as any),
        vendors: (prevSupplier as any).vendors.filter((v: any) => v.id !== vendorId),
      }));
    }

    setIsUpdatingSupplierVendor(undefined);
  }

  async function updateSupplier(updatedSupplier: any) {
    setIsUpdatingSupplier(true);

    const { id, vendors, updatedAt, ...supplierData } = updatedSupplier;
    const res = await update(id, supplierData);

    if (!isMounted.current) return;
    if (!res.success) setError(res as any);
    if (res.success) {
      setSupplier({ ...res.data, vendors });
      setToast("Supplier updated!");
    }

    setIsUpdatingSupplier(undefined);
  }

  async function findXeroContact(query: string) {
    if (query === "") {
      setFoundXeroContacts([]);
    } else {
      setIsSearchingXero(true);

      const res = await findXero(query);
      if (!isMounted.current) return;

      if (res.err) {
        setError(res.val.message);
      } else {
        setFoundXeroContacts(res.val);
      }
      setIsSearchingXero(false);
    }
  }

  return {
    addSupplierVendor,
    getXeroContacts,
    isFetchingSupplier,
    isUpdatingSupplier,
    isUpdatingSupplierVendor,
    removeSupplierVendor,
    supplier,
    supplierNotFound,
    xeroContacts,
    updateSupplier,
    findXeroContact,
    isSearchingXero,
    foundXeroContacts,
  };
};

export { useSupplier };
