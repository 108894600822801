import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FaEye, FaFileInvoiceDollar, FaPen, FaPlus, FaTrash } from "react-icons/fa";

import { AddProductModal } from "../components/AddProductModal";
import { Button } from "../components/ButtonTs";
import { DeleteProductModal } from "../components/Layouts/OrderTs/DeleteProductModal";
import { Loading } from "../components/Loading";
import { LoadingGlobal } from "../components/LoadingGlobal";
import { NotFound } from "./NotFound";
import { OrderDetails } from "../components/Layouts/OrderTs/OrderDetails";
import { OrderExpensesTable } from "../components/Layouts/OrderTs/OrderExpensesTable";
import { OrderHeader } from "../components/Layouts/OrderTs/OrderHeader";
import { OrderNotes } from "../components/Layouts/OrderTs/OrderNotes";
import { OrderReceiveModal } from "../components/Layouts/OrderTs/OrderReceiveModal";
import { OrderSendModal } from "../components/Layouts/OrderTs/OrderSendModal";
import { OrderStockPosition } from "../components/Layouts/OrderTs/OrderStockPosition";
import { OrderSyncModal } from "../components/Layouts/OrderTs/OrderSyncModal";
import { OrderTable } from "../components/Layouts/OrderTs/OrderTable";
import { getSubtotal, OrderTotals } from "../components/Layouts/OrderTs/OrderTotals";
import { OrderUpdateStatusModal } from "../components/Layouts/OrderTs/OrderUpdateStatusModal";
import { Tabs } from "../components/Tabs";
import { DatePicker } from "../components/DatePicker";

import { CONSTANTS } from "../common/constants";
import { useLocations } from "../hooks/useLocationsTs";
import { Range, useOrder as useOrderTs, OrderProductUpdate } from "../hooks/useOrderTs";
import { useOverlay, ModalState } from "../hooks/useOverlayTs";
import * as types from "../common/types";
import { OverlayState } from "../components/Layouts/OrderTs/types";
import { DebouncedInput } from "../components/DebouncedComponent";
import { Input } from "../components/InputTs";
import { DeleteInvoiceModal } from "../components/Layouts/OrderTs/DeleteInvoiceModal";
import { UpdateOrderProductInvoice } from "../backend-v3/invoices";
import moment from "moment";
import { dateString, roundToTwo } from "../helpersTs";

import Picker from "react-date-picker/dist/entry.nostyle";
import { navigate } from "@reach/router";
import { HorizontalTable } from "../components/HorizontalTable";
import { HorizontalTableCell } from "../components/HorizontalTableCell";
import { openInvoiceInXero } from "../components/Layouts/Invoice/common";
import { getInvoicesAction } from "../components/Layouts/OrderTs/common";
import { Orders } from "./Orders";
import { getAuth } from "../backend-v3/auth";

const Order = ({ orderId, parent }: { orderId: number; parent: types.Parent }) => {
  const [currentProductId, setCurrentProductId] = useState<number>();
  const [qtyReceivedTotal, setQtyReceivedTotal] = useState(0);
  const [currentTab, setCurrentTab] = useState("details");
  const [isEditing, setIsEditing] = useState(false);
  const { ref, overlay, setOverlay }: ModalState<OverlayState> = useOverlay();

  const { locations, isFetchingLocations } = useLocations();

  const {
    isDeletingSingleProduct,
    isFetchingOrder,
    isOrderModalLoading,
    isUpdatingOrder,
    notFound,
    order,
    sendToXero,
    isUpdatingXero,
    addOrderProducts,
    deleteOrderProduct,
    receiveOrder,
    sendOrder,
    setStatus,
    setOrder,
    initialDateRange,
    updateOrderProduct,
    updateOrderProducts,
    updateOrder,
    createExpense,
    deleteExpense,
    updateExpense,
    isFetchingPosition,
    activeProducts,
    refreshOrder,
    currentInvoice,
    loadInvoice,
    createInvoice,
    searchInvoice,
    search,
    orderInvoices,
    searchTerm,
    setSearchTerm,
    addExistingInvoice,
    deleteOrderInvoice,
    isDeletingInvoice,
    upsertInvoiceProducts,
    orderRefresh,
    setOrderRefresh,
    isUpdatingInvoice,
    setCurrentInvoice,
    updateInvoice,
    deleteOrderProducts,
    refreshProductStats,
    productStats,
    statsQuery,
    setStatsQuery,
    updateBinLocationOrderProduct,
  } = useOrderTs(orderId);

  const isDraft = order?.status === "draft";
  const isSent = order?.status === "sent";
  const isReceived = order?.status === "received";

  useEffect(() => {
    if (order && !isOrderModalLoading && !isDeletingSingleProduct && !isDeletingInvoice) {
      setIsEditing(false);
      setOverlay(undefined);
    }
  }, [isOrderModalLoading, isDeletingSingleProduct, isDeletingInvoice]);

  useEffect(() => {
    if (order) {
      const total = order.order_products.reduce((acc, cur) => acc + (cur.qty_received ?? 0), 0);
      setQtyReceivedTotal(total);
    }
  }, [order]);

  function getSyncModalWarnings() {
    const warnings = order?.order_products.reduce(
      (acc, product) =>
        (product.paid_per_unit ?? 0) <= 0 && (product.qty_received ?? 0) > 0
          ? [...acc, `${product.product_variant.title} has a price paid of <strong>$0</strong>`]
          : acc,
      [] as string[]
    );
    if (!warnings) return [];
    else return warnings;
  }

  function handleDeleteProduct(productId: number) {
    setCurrentProductId(productId);
    setOverlay("deleteProduct");
  }

  const handleDateArrayChange = (newDate: Date | Date[] | null): moment.Moment | undefined | null => {
    let result: Date;
    if (!newDate) return newDate;
    if (Array.isArray(newDate)) {
      if (newDate[0]) {
        result = newDate[0];
      } else return undefined;
    } else {
      result = newDate;
    }

    return moment(result);
  };

  function orderProductsFillQuantities(): OrderProductUpdate[] {
    if (!order) return [];

    return order.order_products.reduce((acc: OrderProductUpdate[], { id, product_variant }) => {
      const stats = productStats[product_variant.id];
      if (stats === undefined) return acc;

      const stockRequired = stats.sold_qty > 0 && stats.in_stock + stats.orders_in_transfer < stats.sold_qty;

      const update: OrderProductUpdate = {
        id,
        qty_ordered: Math.max(stats.sold_qty - stats.in_stock - stats.orders_in_transfer, 0),
      };
      return stockRequired ? [...acc, update] : acc;
    }, []);
  }

  if (notFound) return <NotFound />;
  if (!order || !locations || isFetchingOrder || isFetchingLocations) return <Loading />;

  return (
    <div className="max-w-site mb-12">
      <OrderHeader
        isEditing={isEditing}
        parent={parent}
        order={order}
        setIsEditing={setIsEditing}
        setOverlay={setOverlay}
        isUpdatingXero={isUpdatingXero}
        currentInvoice={currentInvoice}
        loadInvoice={loadInvoice}
        createInvoice={createInvoice}
        searchInvoice={searchInvoice}
        orderInvoices={orderInvoices}
        search={search}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        addExistingInvoice={addExistingInvoice}
        deleteOrderInvoice={deleteOrderInvoice}
      />

      <div className="flex justify-between items-start">
        <div className="capitalize mr-2">
          <h2>
            <strong>Supplier</strong>: {order.supplier.name}
          </h2>
          <h2 className="mt-2">
            <strong>Location</strong>: {order.location.name}
          </h2>
        </div>

        {currentInvoice && (
          <div>
            <div className="flex mt-4">
              <div className="flex">
                <DebouncedInput
                  Component={Input}
                  cacheDeps={[currentInvoice.id]}
                  props={{
                    id: "invoice-number",
                    className: "w-48 mr-2",
                    label: "Invoice Number",
                    value: currentInvoice?.invoice_number,
                    disabled: false,
                    readOnly: false,
                  }}
                  requestHandler={val => {
                    updateInvoice(currentInvoice.id, {
                      invoice_number: val === "" ? null : val,
                    });
                  }}
                  setValue={val => {
                    setCurrentInvoice(current => {
                      if (current) {
                        return { ...current, invoice_number: val === "" ? undefined : val };
                      } else return current;
                    });
                  }}
                />
              </div>
              <div className="flex">
                <div className="mr-2">
                  <label className="font-bold text-sm" htmlFor="invoice-date">
                    Invoice Date
                  </label>
                  <div className="mt-1">
                    <DebouncedInput
                      Component={DatePicker}
                      cacheDeps={[currentInvoice.id]}
                      props={{
                        readOnly: false,
                        id: "invoice-date",
                        value: currentInvoice?.invoice_date?.toDate(),
                      }}
                      requestHandler={value => {
                        let val = handleDateArrayChange(value);
                        updateInvoice(currentInvoice.id, {
                          invoice_date: val ? dateString(val) : null,
                        });
                      }}
                      setValue={value => {
                        let val = handleDateArrayChange(value);
                        setCurrentInvoice(current => {
                          if (current) {
                            return { ...current, invoice_date: val ? val : undefined };
                          } else return current;
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="mr-2">
                  <label className="font-bold text-sm" htmlFor="payment-date-due">
                    Payment Due
                  </label>
                  <div className="mt-1">
                    <DebouncedInput
                      Component={DatePicker}
                      cacheDeps={[currentInvoice.id]}
                      props={{
                        readOnly: false,
                        id: "payment-date-due",
                        value: currentInvoice?.payment_due?.toDate(),
                      }}
                      requestHandler={value => {
                        let val = handleDateArrayChange(value);
                        updateInvoice(currentInvoice.id, {
                          payment_due: val ? dateString(val) : null,
                        });
                      }}
                      setValue={value => {
                        let val = handleDateArrayChange(value);
                        setCurrentInvoice(current => {
                          if (current) {
                            return { ...current, payment_due: val ? val : undefined };
                          } else return current;
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex mt-4 mb-6">
              {!isReceived && (
                <Button
                  iconBefore={<FaTrash />}
                  onClick={() => setOverlay("deleteInvoice")}
                  value="Remove invoice"
                  variant="ghost"
                  size="small"
                  className="mr-2"
                />
              )}

              <Button
                className="mr-2"
                iconBefore={<FaEye />}
                onClick={() => navigate(`/invoices/${currentInvoice.id}`)}
                value={"View invoice"}
                variant="ghost"
                size="small"
              />

              {currentInvoice.xero_invoice_id && (
                <Button
                  className="mr-2"
                  loading={isUpdatingXero}
                  iconBefore={<FaEye />}
                  onClick={() => openInvoiceInXero(currentInvoice)}
                  value={"View in Xero"}
                  variant="ghost"
                  size="small"
                />
              )}

              <Button
                // className="mr-2"
                loading={isUpdatingXero}
                iconBefore={<FaFileInvoiceDollar />}
                onClick={() => sendToXero(currentInvoice.id)}
                value={getInvoicesAction([currentInvoice])}
                variant="ghost"
                size="small"
              />
            </div>
          </div>
        )}

        {isDraft && (
          <OrderStockPosition
            fetching={isFetchingOrder}
            initialDateRange={initialDateRange}
            locations={locations}
            onFill={() => {
              updateOrderProducts(orderProductsFillQuantities());
            }}
            statsQuery={statsQuery}
            setStatsQuery={setStatsQuery}
            onRefresh={(range: Range, locationId: number, supplierId: number) => {
              refreshProductStats(range, order.order_id, locationId, supplierId);
            }}
          />
        )}
      </div>

      {!isReceived && (
        <div className="mt-4">
          <Button
            iconBefore={<FaPlus />}
            onClick={(e: any) => {
              e.stopPropagation();
              setOverlay("addProducts");
            }}
            size="small"
            value="Add Products"
            variant="primary"
          />
        </div>
      )}

      <OrderTable
        deleteOrderProduct={deleteOrderProduct}
        deleteOrderProducts={deleteOrderProducts}
        handleDeleteProduct={handleDeleteProduct}
        isEditing={isEditing}
        isFetchingPosition={isFetchingPosition}
        order={order}
        currentInvoice={currentInvoice}
        updateOrderProduct={updateOrderProduct}
        upsertInvoiceProducts={upsertInvoiceProducts}
        orderRefresh={orderRefresh}
        setOrderRefresh={setOrderRefresh}
        refreshOrder={refreshOrder}
        productStats={productStats}
        statsQuery={statsQuery}
        updateBinLocation={updateBinLocationOrderProduct}
        // todo: replace with proper shop settings
        binLocationsActive={(window as any).auth?.user?.shop?.bin_locations_active ?? false}
      />

      <div className="flex justify-between mt-8">
        <div className="w-full">
          <Tabs
            className="max-w-3xl"
            content={
              currentTab === "details" ? (
                <OrderDetails
                  locations={locations}
                  loading={isUpdatingOrder}
                  order={order}
                  readOnly={isReceived}
                  updateOrder={updateOrder}
                  currentInvoice={currentInvoice}
                />
              ) : (
                <OrderNotes
                  loading={isUpdatingOrder}
                  notes={order.notes}
                  updateOrder={(notes, inline) => updateOrder({ notes }, inline)}
                />
              )
            }
            currentTab={currentTab}
            onTabClick={setCurrentTab}
            tabs={[
              {
                className: "",
                id: "details",
                label: "Details",
              },
              {
                className: "mr-2",
                id: "notes",
                label: "Notes",
              },
            ]}
          />
        </div>
        <div className="ml-4 w-full max-w-lg">
          <HorizontalTable
            head={
              <>
                {currentInvoice && (
                  <HorizontalTableCell
                    bold
                    large
                    value={`Received on invoice ${currentInvoice.invoice_number ?? currentInvoice.id}`}
                  />
                )}

                <HorizontalTableCell
                  bold
                  large
                  value={`
                 ${isDraft || isSent ? "Estimated cost" : `${currentInvoice ? "Total order cost" : "Cost"}`}`}
                />
              </>
            }
            body={
              <>
                {currentInvoice && (
                  <HorizontalTableCell large value={`$${roundToTwo(getSubtotal(currentInvoice.products))}`} />
                )}
                <HorizontalTableCell
                  large
                  value={`$${roundToTwo(
                    isDraft || isSent
                      ? order.order_products.reduce((acc, cur) => {
                          return cur.last_paid_per_unit ? cur.last_paid_per_unit * cur.qty_ordered + acc : acc;
                        }, 0)
                      : order.total
                  )}`}
                />
              </>
            }
          />

          {currentInvoice && (
            <>
              <h2 className="font-bold pt-10 pb-4">{`Invoice ${
                currentInvoice.invoice_number ?? currentInvoice.id
              } summary`}</h2>

              <OrderExpensesTable
                createExpense={createExpense}
                deleteExpense={deleteExpense}
                updateExpense={updateExpense}
                expenses={currentInvoice.expenses}
                loading={isUpdatingOrder}
                modalLoading={isOrderModalLoading}
                readOnly={isReceived}
              />
            </>
          )}

          <OrderTotals
            loading={isUpdatingOrder}
            order={order}
            updateAdjustment={adjustments => {
              if (currentInvoice) {
                updateInvoice(currentInvoice.id, { adjustments });
              }
            }}
            invoice={currentInvoice}
          />
        </div>
      </div>

      {overlay && (
        <div ref={ref} className="fixed top-0 right-0 w-full h-screen z-50">
          <AddProductModal
            isOpen={overlay === "addProducts"}
            loading={isOrderModalLoading}
            onClose={() => setOverlay(undefined)}
            onSubmit={productIds => {
              addOrderProducts(productIds);
            }}
            currentProductsVariantIds={order.order_products.map(op => op.product_variant.id)}
            allProducts={activeProducts}
          />

          {currentInvoice && (
            <DeleteInvoiceModal
              isOpen={overlay === "deleteInvoice"}
              invoice={currentInvoice}
              loading={isDeletingInvoice}
              onClose={() => setOverlay(undefined)}
              onSubmit={() => deleteOrderInvoice(currentInvoice.id)}
            />
          )}

          {currentProductId && (
            <DeleteProductModal
              isOpen={overlay === "deleteProduct"}
              loading={isDeletingSingleProduct}
              onClose={() => setOverlay(undefined)}
              onSubmit={() => deleteOrderProduct(currentProductId)}
            />
          )}

          <OrderReceiveModal
            isOpen={overlay === "receive"}
            loading={isOrderModalLoading}
            onClose={() => setOverlay(undefined)}
            onSubmit={() => {
              receiveOrder();
              setCurrentInvoice(undefined);
            }}
            warnings={getSyncModalWarnings()}
          />
          <OrderSendModal
            isOpen={overlay === "send"}
            loading={isOrderModalLoading}
            onClose={() => setOverlay(undefined)}
            onSubmit={sendOrder}
          />
          <OrderUpdateStatusModal
            currentStatus={order.status}
            isOpen={overlay === "status"}
            loading={isOrderModalLoading}
            onClose={() => setOverlay(undefined)}
            onSubmit={status => {
              setStatus(status);
              setCurrentInvoice(undefined);
            }}
            qtyReceivedTotal={qtyReceivedTotal}
          />
        </div>
      )}

      <LoadingGlobal loading={isUpdatingOrder} />
    </div>
  );
};

export { Order };
