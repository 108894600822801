import { gql } from "graphql-request";
import { client, removeEmpty, request, result } from "./helpers";
import { Result, Ok, Err } from "ts-results";
import { GetSuppliersQuery, SupplierFieldsFragment, SupplierFieldsWithVendorsFragment } from "../generated/graphql";
import { legacyRequest } from "../backend-v3/helpers";
import { transformSupplier, transformVendor } from "./transform_legacy";
import { processProductVariant, ProductVariant, ProductVariantSupplier } from "./orders";

type SupplierWithoutVendors = {
  id: number;
  name: string;
  email?: string | null;
  contact_first_name?: string | null;
  contact_last_name?: string | null;
};

type Supplier = SupplierWithoutVendors & {
  vendors: Vendor[];
};

type Vendor = {
  id: number;
  name: string;
};
type VendorWithSuppliers = Vendor & {
  suppliers: SupplierWithoutVendors[];
};

export type XeroContact = {
  id: string;
  name?: string | null;
};

const getVendor = async (id: any) => {
  const q = gql`
    query GetVendor($id: Int!) {
      vendors_by_pk(id: $id) {
        id
        name
        suppliers_vendors {
          supplier {
            id
            name
            email
            contact_first_name
            contact_last_name
          }
        }
        shop {
          shop
        }
        products {
          created_at
          handle
          id
          online_store_url
          online_store_preview_url
          shop_id
          status
          title
          updated_at
          vendor_id
          vendor {
            name
          }
          product_variants {
            created_at
            display_name
            id
            inventory_id
            price
            shop_id
            shopify_product_id
            shopify_product_variant_id
            shopify_unit_cost
            sku
            title
            updated_at
            stock_level {
              in_stock
              orders_in_transfer
              last_paid_per_unit
            }
          }
        }
      }
    }
  `;
  let res = await legacyRequest(q, { id: Number(id) });

  if (!res.success) return res;

  res.data = transformVendor(res.data.vendorsByPk);
  return res;
};

const updateSupplier = async (id: any, data: any) => {
  const q = gql`
    mutation UpdateSuppliers($id: Int!, $data: suppliers_set_input!) {
      update_suppliers_by_pk(pk_columns: { id: $id }, _set: $data) {
        id
        name
        email
        contact_first_name
        contact_last_name
        xero_contact {
          id
          name
        }
        suppliers_vendors {
          vendor {
            id
            name
          }
        }
      }
    }
  `;
  let newData = {
    contact_first_name: data.contactFirstName,
    contact_last_name: data.contactLastName,
    email: data.email,
    name: data.name,
    xero_contact_id: data.xeroContactId,
  };

  removeEmpty(newData);

  let vars = {
    id: Number(id),
    data: newData,
  };

  let res = await legacyRequest(q, vars);
  if (!res.success) return res;
  res.data = transformSupplier(res.data.updateSuppliersByPk);
  return res;
};

const removeVendor = async (supplier_id: any, vendor_id: any) => {
  const q = gql`
    mutation DeleteVendorSupplier($supplier_id: Int!, $vendor_id: Int!) {
      data: delete_suppliers_vendors(where: { supplier_id: { _eq: $supplier_id }, vendor_id: { _eq: $vendor_id } }) {
        affected_rows
      }
    }
  `;

  let res = await legacyRequest(q, { supplier_id: Number(supplier_id), vendor_id: Number(vendor_id) });
  return res;
};

const getVendors = async (): Promise<Result<VendorWithSuppliers[], Error>> => {
  let c = await result((await client()).GetVendors());
  if (c.err) return c;
  return Ok(
    c.val.vendors.map(x => {
      return { ...x, suppliers: x.suppliers_vendors.map(x => x.supplier) };
    })
  );
};

const processSupplier = (data: SupplierFieldsWithVendorsFragment): Supplier => {
  let supplier = {
    ...data,
    vendors: data.suppliers_vendors.map(x => x.vendor),
  };

  return supplier;
};

const getSuppliers = async (): Promise<Result<Supplier[], Error>> => {
  let c = await result((await client()).GetSuppliers());
  if (c.err) return c;
  return Ok(c.val.suppliers.map(processSupplier));
};

type SupplierCodeData = {
  supplier_id: number;
  product_variant_id: number;
  code?: string | null;
  expected_cost?: number | null;
};

const updateSupplierCode = async (data: SupplierCodeData[]): Promise<Result<SupplierCodeData[], Error>> => {
  const c = await result((await client()).CreateSupplierForVariant({ data }));
  if (c.err) return c;
  return Ok(c.val.insert_suppliers_product_variants?.returning ?? []);
};

const updateSupplierExpectedCost = async (data: SupplierCodeData[]): Promise<Result<SupplierCodeData[], Error>> => {
  const c = await result((await client()).CreateSupplierForVariantUpdateExpectedCostOnly({ data }));
  if (c.err) return c;
  return Ok(c.val.insert_suppliers_product_variants?.returning ?? []);
};

const insertSupplierPvBySku = async (
  supplier_id: number,
  data: { sku: string; code: string | null }[]
): Promise<Result<ProductVariantSupplier[], Error>> => {
  const c = await result((await client()).UpsertSupplierPvsWithSku({ supplier_id, data }));
  if (c.err) return c;

  const pvs: (ProductVariantSupplier | null)[] = c.val.upsert_supplier_product_variants_sku.map(x => {
    const pv = processProductVariant(x.product_variant!);
    if (pv === null) return null;
    return { ...pv, code: x.code !== null ? x.code : undefined };
  });

  return Ok(pvs.filter((x): x is ProductVariantSupplier => x !== null));
};

const deleteSupplierProduct = async (
  supplier_id: number,
  product_variant_id: number
): Promise<Result<undefined, Error>> => {
  const c = await result((await client()).DeleteSupplierProducts({ supplier_id, product_variant_id }));
  if (c.err) return c;
  return Ok(undefined);
};

const updateSupplierCodeReturningPv = async (
  data: SupplierCodeData[]
): Promise<Result<ProductVariantSupplier[], Error>> => {
  const c = await result((await client()).CreateSupplierForVariantReturningPvData({ data }));
  if (c.err) return c;

  if (!c.val.insert_suppliers_product_variants) return Ok([]);

  const pvs: (ProductVariantSupplier | null)[] = c.val.insert_suppliers_product_variants.returning.map(x => {
    const pv = processProductVariant(x.product_variant!);
    if (pv === null) return null;
    return { ...pv, code: x.code !== null ? x.code : undefined };
  });

  return Ok(pvs.filter((x): x is ProductVariantSupplier => x !== null));
};

const createSupplier = async (data: any) => {
  const q = gql`
    mutation CreateSupplier($data: suppliers_insert_input!) {
      insert_suppliers_one(object: $data) {
        id
      }
    }
  `;
  let newData = {
    contact_first_name: data.contactFirstName,
    contact_last_name: data.contactLastName,
    email: data.email,
    name: data.name,
    xero_contact_id: data.xeroContactId === "" ? undefined : data.xeroContactId,
    shop_id: (window as any).auth.user.shop.id,
  };

  removeEmpty(newData);

  let vars = {
    data: newData,
  };

  let res = await legacyRequest(q, vars);
  return res;
};

const deleteSupplier = async (supplier_id: any) => {
  const q = gql`
    mutation DeleteSupplier($supplier_id: Int!) {
      delete_suppliers_vendors(where: { supplier_id: { _eq: $supplier_id } }) {
        affected_rows
      }

      delete_suppliers_by_pk(id: $supplier_id) {
        id
      }
    }
  `;

  let res = await legacyRequest(q, { supplier_id: Number(supplier_id) });
  return res;
};

const getSupplier = async (id: any) => {
  const q = gql`
    query GetSupplier($id: Int!) {
      suppliers_by_pk(id: $id) {
        id
        name
        email
        contact_first_name
        contact_last_name
        xero_contact {
          id
          name
        }
        suppliers_vendors {
          vendor {
            id
            name
          }
        }
      }
    }
  `;
  let res = await legacyRequest(q, { id: Number(id) });
  if (!res.success) return res;

  res.data = transformSupplier(res.data.suppliersByPk);
  return res;
};

const addVendor = async (supplier_id: any, vendor_id: any) => {
  const q = gql`
    mutation InsertNewVendorForSupplier($supplier_id: Int!, $vendor_id: Int!) {
      data: insert_suppliers_vendors_one(object: { supplier_id: $supplier_id, vendor_id: $vendor_id }) {
        id
      }
    }
  `;

  let res = await legacyRequest(q, { supplier_id: Number(supplier_id), vendor_id: Number(vendor_id) });
  return res;
};

const getXeroContacts = async () => {
  const q = gql`
    query XeroContacts {
      xero_contacts {
        name
        id
      }
    }
  `;

  let res = await legacyRequest(q);
  if (!res.success) return res;
  res.data = res.data.xeroContacts;
  return res;
};

const getSupplierAssignedProducts = async (supplier_id: number): Promise<Result<ProductVariantSupplier[], Error>> => {
  const data = await result((await client()).GetSupplierAssignedProducts({ supplier_id }));
  if (data.err) return data;

  const pvs: (ProductVariantSupplier | null)[] = data.val.suppliers_product_variants.map(x => {
    const pv = processProductVariant(x.product_variant);
    if (pv === null) return null;
    return { ...pv, code: x.code !== null ? x.code : undefined };
  });

  return Ok(pvs.filter((x): x is ProductVariantSupplier => x !== null));
};

const getSupplierVendorProducts = async (
  supplier: number,
  vendor: number
): Promise<Result<ProductVariantSupplier[], Error>> => {
  const data = await result((await client()).GetSupplierVendorProducts({ supplier, vendor }));
  if (data.err) return data;

  const pvs: (ProductVariantSupplier | null)[] = data.val.suppliers_vendors_products.map(x => {
    const pv = processProductVariant(x.product_variant!);
    if (pv === null) return null;
    return { ...pv, code: x.supplier_code !== null ? x.supplier_code : undefined };
  });

  return Ok(pvs.filter((x): x is ProductVariantSupplier => x !== null));
};

const findXeroContact = async (query: string): Promise<Result<XeroContact[], Error>> => {
  const data = await result(
    (await client()).FindXeroContact({ query: `%${query}%`, shopId: (window as any).auth.user.shop.id })
  );
  if (data.err) return data;
  return Ok(data.val.xero_contacts);
};

export {
  SupplierCodeData,
  Supplier,
  SupplierWithoutVendors,
  createSupplier,
  deleteSupplier,
  addVendor,
  getSupplier,
  getXeroContacts,
  getSupplierAssignedProducts,
  getSupplierVendorProducts,
  Vendor,
  getVendor,
  getVendors,
  removeVendor,
  getSuppliers,
  updateSupplierCode,
  processSupplier,
  updateSupplier,
  VendorWithSuppliers,
  updateSupplierCodeReturningPv,
  deleteSupplierProduct,
  insertSupplierPvBySku,
  findXeroContact,
  updateSupplierExpectedCost,
};
