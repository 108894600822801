import { useState } from "react";
import * as types from "../common/types";
import { Dropdown } from "../components/DropdownTs";
import { TableCell } from "../components/TableCellTs";
import { TableHeadCell } from "../components/TableHeadCell";
import { TableHeadRow } from "../components/TableHeadRow";
import { TableRow } from "../components/TableRowTs";
import { Table } from "../components/TableTs";
import { dateString, formatDate } from "../helpersTs";
import { useLocations } from "../hooks/useLocationsTs";
import { useProductsHistory } from "../hooks/useProducts";

const ProductHistoryEach = ({ pvid, parent }: { pvid: number; parent: types.Parent }) => {
  const { isFetchingHistory, productsHistory } = useProductsHistory(pvid);
  const { locations } = useLocations();
  let locationOptions = locations.map(({ id, name }) => ({ name, value: id.toString() }));
  locationOptions.unshift({ name: "All", value: "all" });

  // filter by location state
  const [location, setLocation] = useState<string>("all");
  return (
    <div>
      <Dropdown
        className="mb-8 w-48"
        id="filter-key"
        label="Filter By Location"
        onChange={(x: string) => {
          setLocation(x);
        }}
        options={locationOptions}
        value={location}
      />

      <Table
        className=""
        loading={isFetchingHistory}
        emptyState={!productsHistory?.product_changes_view.length && !isFetchingHistory && "Nothing to display"}
        renderHead={() => (
          <TableHeadRow>
            <TableHeadCell value="Date" width="32" />
            <TableHeadCell value="Order Number" width="32" />
            <TableHeadCell value="Order Status" width="32" />
            <TableHeadCell value="Order Location" width="32" />
            <TableHeadCell value="Order Qty Received" width="32" />
            <TableHeadCell value="Order Paid Per Unit" width="32" />
            <TableHeadCell value="Stocktake Number" width="32" />
            <TableHeadCell value="Stocktake Status" width="32" />
            <TableHeadCell value="Stocktake Location" width="32" />

            <TableHeadCell value="Stocktake Change" width="32" />
            <TableHeadCell value="Transfer Number" width="32" />
            <TableHeadCell value="Transfer Status" width="32" />

            <TableHeadCell value="Transfer From Location" width="32" />
            <TableHeadCell value="Transfer To Location" width="32" />
            <TableHeadCell value="Transfer Qty Requested" width="32" />
            <TableHeadCell value="Transfer Qty Received" width="32" />
            <TableHeadCell value="Transfer Qty Fulfilled" width="32" />
          </TableHeadRow>
        )}
        renderBody={() => (
          <>
            {productsHistory?.product_changes_view
              .filter(p => {
                if (location === "all") return true;
                const locId = Number(location);
                const order_location_id = p.order_location_id;
                const stocktake_location_id = p.stocktake_location_id;
                const transfer_from_location_id = p.transfer_from_location_id;
                const transfer_to_location_id = p.transfer_to_location_id;
                if (locId === order_location_id) return true;
                if (locId === stocktake_location_id) return true;
                if (locId === transfer_from_location_id) return true;
                if (locId === transfer_to_location_id) return true;
                return false;
              })
              .map(
                ({
                  ts,
                  order_number,
                  order_status,
                  order_qty_received,
                  order_paid_per_unit,
                  stocktake_id,
                  stocktake_change,
                  stocktake_status,
                  transfer_id,
                  transfer_from_location_id,
                  transfer_to_location_id,
                  transfer_status,
                  transfer_qty_requested,
                  transfer_qty_received,
                  transfer_qty_fulfilled,
                  stocktake_location_id,
                  order_location_id,
                }) => (
                  <TableRow key={ts}>
                    <TableCell value={{ type: "string", initial: dateString(ts) }} />
                    <TableCell url={`/orders/${order_number}`} value={{ type: "number", initial: order_number }} />
                    <TableCell value={{ type: "string", initial: order_status }} />
                    <TableCell
                      value={{ type: "string", initial: locations.find(x => x.id === order_location_id)?.name }}
                    />
                    <TableCell value={{ type: "number", initial: order_qty_received }} />
                    <TableCell value={{ type: "number", initial: order_paid_per_unit }} />
                    <TableCell url={`/stocktakes/${stocktake_id}`} value={{ type: "number", initial: stocktake_id }} />
                    <TableCell value={{ type: "string", initial: stocktake_status }} />
                    <TableCell
                      value={{ type: "string", initial: locations.find(x => x.id === stocktake_location_id)?.name }}
                    />
                    <TableCell value={{ type: "number", initial: stocktake_change }} />
                    <TableCell url={`/transfers/${transfer_id}`} value={{ type: "number", initial: transfer_id }} />
                    <TableCell value={{ type: "string", initial: transfer_status }} />

                    <TableCell
                      value={{ type: "string", initial: locations.find(x => x.id === transfer_from_location_id)?.name }}
                    />
                    <TableCell
                      value={{ type: "string", initial: locations.find(x => x.id === transfer_to_location_id)?.name }}
                    />
                    <TableCell value={{ type: "number", initial: transfer_qty_requested }} />
                    <TableCell value={{ type: "number", initial: transfer_qty_received }} />
                    <TableCell value={{ type: "number", initial: transfer_qty_fulfilled }} />
                  </TableRow>
                )
              )}
          </>
        )}
      />
    </div>
  );
};

export { ProductHistoryEach };
