import React from 'react'
import PropTypes from 'prop-types'
import { FaPlus } from 'react-icons/fa'

import { Modal } from '../../Modal'

import { PROP_TYPES } from '../../../common/propTypes'

const AddIntegrationModal = ({ integration, isOpen, loading, onClose, onSubmit }) =>
  integration ? (
    <Modal
      closeModal={onClose}
      disabled={loading}
      heading="Add Integration"
      isOpen={isOpen}
      loading={loading}
      onSubmit={() => onSubmit(integration.name)}
      submitIcon={<FaPlus />}
      submitValue="Add"
    >
      {!loading ? (
        <p className="mt-6">
          You are about to integrate with <code className="bg-orange-100 p-1">{integration.name}</code>. Your web page
          will be redirected. All gds?
        </p>
      ) : (
        <p className="mt-6">Redirecting...</p>
      )}
    </Modal>
  ) : null

AddIntegrationModal.propTypes = {
  integration: PROP_TYPES.INTEGRATION,
  isOpen: PropTypes.bool,
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

AddIntegrationModal.defaultProps = {
  integration: null,
  isOpen: false,
  loading: false,
}

export { AddIntegrationModal }
