import React, { useState, useEffect, useRef } from "react";

import { Loading } from "../components/Loading";

import { location } from "./common";
import { isActive, useProducts } from "../hooks/useProducts";
import { getAllProducts, ProductVariant } from "../backend-v3/orders";
import { Bundle, deleteBundle, getBundles, upsertBundle } from "../backend-v3/product_bundles";
import { Header } from "../components/Header";
import { Table } from "../components/TableTs";
import { TableHeadRow } from "../components/TableHeadRow";
import { TableHeadCell } from "../components/TableHeadCell";
import { TableCell } from "../components/TableCellTs";
import { sortArrayByObjectKey } from "../helpersTs";
import { useFeedback } from "../hooks/useFeedbackTs";
import { Button } from "../components/ButtonTs";
import { FaArrowRight, FaPlus, FaSave, FaTrash } from "react-icons/fa";
import { Modal } from "../components/Modal";
import { AddProductModal } from "../components/AddProductModal";
import { ModalState, useOverlay } from "../hooks/useOverlayTs";
import { TableRowActions } from "../components/TableRowActions";

type BundleDraft = {
  product_variant_id_parent: number;
  product_variant_id_child: number | undefined;
  ratio: number | undefined;
};
const ProductBundles = (location: location) => {
  const isMounted = useRef(true);

  const { setAllProducts, activeProducts, allProducts, setUpProducts } = useProducts();
  const [loaded, setLoaded] = useState(false);
  const [bundles, setBundles] = useState<Bundle[]>([]);
  const [productsObj, setProductsObj] = useState<Record<number, ProductVariant>>({});

  const [bundlesGrouped, setBundlesGrouped] = useState<Record<string, Bundle[]>>({});

  const { setError, setSaved, setToast, setLoading } = useFeedback();

  async function handleUpdateRatioForDraft(parent: number, child: number, ratio: number) {
    setNewBundles(bundles => {
      return bundles.map(b => {
        return b.product_variant_id_child === child && b.product_variant_id_parent === parent
          ? {
              ...b,
              ratio,
            }
          : b;
      });
    });
  }

  async function handleChangeRatioDb(newBundle: Bundle) {
    setSaved(false);
    const res = await upsertBundle([newBundle]);
    if (res.err) {
      setError(res.val.message);
    } else {
      setBundles(bundles => {
        return bundles.map(b => {
          if (
            b.product_variant_id_child === newBundle.product_variant_id_child &&
            b.product_variant_id_parent === newBundle.product_variant_id_parent
          )
            return newBundle;
          return b;
        });
      });
    }
    setSaved(true);
  }

  async function handleUpsertBundle(newBundles: Bundle[]) {
    setSaved(false);
    const res = await upsertBundle(newBundles);
    if (res.err) {
      setError(res.val.message);
    } else {
      getData();
    }
    setSaved(true);
  }

  useEffect(() => {
    getData();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const obj = bundles.reduce<Record<string, Bundle[]>>(
      (acc, val) => ({
        ...acc,
        [val.product_variant_id_parent]: [...(acc[val.product_variant_id_parent] ?? []), val],
      }),
      {}
    );

    setBundlesGrouped(obj);
  }, [bundles]);

  // useEffect(() => {
  //   console.log("active prods");

  //   const obj = allProducts.reduce(
  //     (acc, val) => ({
  //       ...acc,
  //       [val.id]: val,
  //     }),
  //     {}
  //   );

  //   setProductsObj(obj);
  // }, [allProducts]);

  async function getData() {
    const res = await getAllProducts();
    const bundles = await getBundles();
    if (!isMounted.current) return;
    if (res.ok && bundles.ok) {
      setNewBundles([]);
      setUpProducts(res.val);

      let obj: Record<number, ProductVariant> = {};

      res.val.forEach(p => {
        obj[p.id] = p;
      });

      setProductsObj(obj);
      setBundles(bundles.val);
      setLoaded(true);
    }
  }

  const [newBundle, setNewBundle] = useState(false);
  const [newSingleProduct, setNewSingleProduct] = useState<number | undefined>(undefined);
  const [newBundles, setNewBundles] = useState<BundleDraft[]>([]);
  const [draftBundlesGrouped, setDraftBundlesGrouped] = useState<Record<string, BundleDraft[]>>({});

  const deleteBundleHandle = async (child: number, parent: number) => {
    setSaved(false);

    const res = await deleteBundle({ product_variant_id_child: child });

    if (res.err) {
      setError(res.val.message);
    } else {
      console.log("removed");
      setBundles(bundles => {
        return bundles.filter(b => !(b.product_variant_id_child === child && b.product_variant_id_parent === parent));
      });
    }

    setSaved(true);
  };

  const saveBundles = () => {
    let bundlesToSave: Bundle[] = [];

    newBundles.forEach(x => {
      if (x.product_variant_id_child !== undefined && x.ratio !== undefined && x.ratio > 0) {
        bundlesToSave.push({
          ratio: x.ratio,
          product_variant_id_parent: x.product_variant_id_parent,
          product_variant_id_child: x.product_variant_id_child,
        });
      }
    });

    handleUpsertBundle(bundlesToSave);
  };

  useEffect(() => {
    console.log("new bundles delete");
    const obj = newBundles.reduce<Record<string, BundleDraft[]>>(
      (acc, val) => ({
        ...acc,
        [val.product_variant_id_parent]: [...(acc[val.product_variant_id_parent] ?? []), val],
      }),
      {}
    );

    setDraftBundlesGrouped(obj);
  }, [newBundles]);

  const Rows = ({ parentDraft }: { parentDraft: boolean }) => {
    const filtered: Record<string, BundleDraft[] | Bundle[]> = parentDraft
      ? Object.keys(draftBundlesGrouped)
          .filter(key => bundlesGrouped[key] === undefined)
          .reduce<Record<string, BundleDraft[]>>((cur, key) => {
            return Object.assign(cur, { [key]: draftBundlesGrouped[key] });
          }, {})
      : bundlesGrouped;

    const AddNew = ({ parent }: { parent: number }) => {
      return (
        <span
          onClick={() => setNewSingleProduct(Number(parent))}
          className="mt-2 mb-2 text-sm py-2 px-3 rounded-md inline-block hover:bg-gray-200 cursor-pointer  bg-gray-100"
        >
          Add product single
        </span>
      );
    };

    return (
      <>
        {Object.keys(filtered).map(key => {
          const bundles = filtered[key];
          const createNew = (
            <>
              <tr>
                <td></td>
                <td className="text-left">
                  <AddNew parent={Number(key)} />
                </td>
                <td></td>
              </tr>
            </>
          );

          const newBundles = draftBundlesGrouped[key]?.map((v, index) => {
            const productChild = v.product_variant_id_child ? productsObj[v.product_variant_id_child] : undefined;
            const productParent = productsObj[v.product_variant_id_parent]!;

            return (
              <tr className="align-top" key={`${v.product_variant_id_child}`}>
                <TableCell
                  value={{
                    initial:
                      parentDraft && index === 0 ? (
                        <span className="bg-orange-100">{productParent?.full_title}</span>
                      ) : undefined,
                    type: "element",
                  }}
                />
                {productChild === undefined ? (
                  <div className="mb-4">
                    <AddNew parent={productParent.id} />
                  </div>
                ) : (
                  <TableCell
                    value={{
                      initial: <span className="mb-10 bg-orange-100">{productChild?.full_title}</span>,
                      type: "element",
                    }}
                  />
                )}
                {productChild ? (
                  <TableCell
                    value={{
                      initial: v.ratio,
                      type: "number",
                    }}
                    onChange={ratio => handleUpdateRatioForDraft(productParent.id, productChild.id, Number(ratio))}
                  />
                ) : (
                  <td></td>
                )}

                {productChild && (
                  <TableRowActions
                    actions={[
                      {
                        id: "delete",
                        iconBefore: <FaTrash />,
                        onClick: e => {
                          e.stopPropagation();

                          setNewBundles(bundles => {
                            return bundles.filter(
                              b =>
                                !(
                                  b.product_variant_id_child === productChild.id &&
                                  b.product_variant_id_parent === productParent.id
                                )
                            );
                          });
                        },
                      },
                    ]}
                  />
                )}
              </tr>
            );
          });

          const bundlesNodes = bundles?.map((v, index) => {
            const productParent = productsObj[v.product_variant_id_parent];
            const productChild = v.product_variant_id_child ? productsObj[v.product_variant_id_child] : undefined;

            const parentActive = productParent ? isActive(productParent) : false;
            const childActive = productChild ? isActive(productChild) : false;

            const discontinuedNode = <span className="ml-2 text-xs bg-orange-300 px-2 py-1 rounded">DISCONTINUED</span>;

            const parentNode = () => {
              if (index !== 0) {
                return <TableCell value={{ initial: undefined, type: "string" }} />;
              }

              if (parentActive) {
                return <TableCell value={{ initial: `${productParent?.full_title}`, type: "string" }} />;
              }
              return (
                <TableCell
                  value={{
                    initial: (
                      <div>
                        {productParent?.full_title}
                        {discontinuedNode}
                      </div>
                    ),
                    type: "element",
                  }}
                />
              );
            };

            const childNode = () => {
              if (childActive) {
                return <TableCell value={{ initial: productChild?.full_title, type: "string" }} />;
              }
              return (
                <TableCell
                  value={{
                    initial: (
                      <div>
                        {productChild?.full_title}
                        {discontinuedNode}
                      </div>
                    ),
                    type: "element",
                  }}
                />
              );
            };

            return (
              <tr className="align-top" key={`${v.product_variant_id_child}`}>
                {parentNode()}
                {childNode()}
                <TableCell
                  value={{
                    initial: v.ratio,
                    type: "number",
                  }}
                  onChange={ratio => {
                    if (v.product_variant_id_child && ratio) {
                      handleChangeRatioDb({
                        product_variant_id_parent: v.product_variant_id_parent,
                        product_variant_id_child: v.product_variant_id_child,
                        ratio: Number(ratio),
                      });
                    }
                  }}
                />

                <TableRowActions
                  actions={[
                    {
                      id: "delete",
                      iconBefore: <FaTrash />,
                      onClick: e => {
                        e.stopPropagation();
                        if (productParent && productChild) {
                          deleteBundleHandle(productChild.id, productParent.id);
                        }
                      },
                    },
                  ]}
                />
              </tr>
            );
          });

          return (
            <>
              {!parentDraft && bundlesNodes}
              {newBundles}

              {!parentDraft && createNew}
            </>
          );
        })}
      </>
    );
  };

  if (!loaded) return <Loading />;
  return (
    <>
      {newSingleProduct !== undefined && (
        <AddProductModal
          isOpen={newSingleProduct !== undefined}
          loading={false}
          onClose={() => setNewSingleProduct(undefined)}
          onSubmit={products => {
            setNewBundles(bundles => {
              return [
                ...products.map(x => {
                  return { product_variant_id_parent: newSingleProduct, product_variant_id_child: x, ratio: undefined };
                }),

                ...bundles,
              ];
            });
            setNewSingleProduct(undefined);
          }}
          currentProductsVariantIds={[
            ...[...newBundles, ...bundles].map(x => x.product_variant_id_parent),
            ...[...newBundles, ...bundles].map(x => x.product_variant_id_child),
          ].filter((x): x is number => x !== undefined)}
          allProducts={activeProducts}
        />
      )}

      {newBundle && (
        <AddProductModal
          isOpen={newBundle}
          loading={false}
          onClose={() => setNewBundle(false)}
          onSubmit={products => {
            setNewBundles(bundles => {
              return [
                ...products.map(x => {
                  return { product_variant_id_parent: x, product_variant_id_child: undefined, ratio: undefined };
                }),

                ...bundles,
              ];
            });
            setNewBundle(false);
          }}
          currentProductsVariantIds={[
            ...[...newBundles, ...bundles].map(x => x.product_variant_id_parent),
            ...[...newBundles, ...bundles].map(x => x.product_variant_id_child),
          ].filter((x): x is number => x !== undefined)}
          allProducts={activeProducts}
        />
      )}

      <Header
        actions={[
          <Button
            className={"mr-4"}
            disabled={
              newBundles.filter(
                val => val.product_variant_id_child !== undefined && val.ratio !== undefined && val.ratio > 0
              ).length === 0
            }
            iconBefore={<FaSave />}
            onClick={() => saveBundles()}
            value="Save"
            variant="ghost"
          />,

          <Button iconBefore={<FaPlus />} onClick={() => setNewBundle(true)} value="New" variant="primary" />,
        ]}
        crumbs={[{ to: "/product_bundles", value: "Bundle Pricing" }]}
      />

      <Table
        renderHead={() => (
          <TableHeadRow>
            <TableHeadCell value="Product bundle" width="5/12" />
            <TableHeadCell value="Product single" width="5/12" />
            <TableHeadCell value="Units" width="2/12" />
          </TableHeadRow>
        )}
        renderBody={() => (
          <>
            <Rows parentDraft={true} />

            <Rows parentDraft={false} />
          </>
        )}
      />
    </>
  );
};

export { ProductBundles };
