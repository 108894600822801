import { gql } from 'graphql-request'
import { request, client, result } from './helpers'
import { Result, Ok, Err } from 'ts-results'
import { FaWindowMaximize } from 'react-icons/fa'

type Location = {
  id: number
  name: string
  address1?: string | null
  address2?: string | null
  city?: string | null
  country?: string | null
  phone?: string | null
  zip?: string | null
}

const getLocations = async (): Promise<Result<Location[], Error>> => {
  const c = await result((await client()).GetLocations())

  if (c.err) return c

  return Ok(c.val.locations)
}

export { getLocations, Location }
