import { useEffect, useRef, useState } from "react";
import { ProductVariant, getAllProducts } from "../backend-v3/orders";
import { useProducts } from "../hooks/useProducts";
import { sortArrayByObjectKey } from "../helpersTs";

const ProductHistory = () => {
  const { activeProducts, setAllProducts, setUpProducts } = useProducts();
  const isMounted = useRef(true);
  const [loading, setLoading] = useState(false);

  async function getData() {
    setLoading(true);
    const res = await getAllProducts();
    if (!isMounted.current) return;
    if (res.ok) {
      setUpProducts(res.val);

      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <div className="flex flex-col">
      {sortArrayByObjectKey(activeProducts, "full_title").map(product => {
        return (
          <a
            className="underline"
            href={`/product_history/${product.id}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => e.stopPropagation()}
          >
            {product.full_title}
          </a>
        );
      })}
    </div>
  );
};

export { ProductHistory };
