import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Loading } from "../components/Loading";
import { SupplierVendorHeader } from "../components/Layouts/SupplierVendor/SupplierVendorHeader";
import { SupplierProductsTable } from "../components/Layouts/Supplier/SupplierProductsTable";

import { getSupplierVendorProducts } from "../backend-v3/suppliers";
import { getVendor } from "../backend-v3/suppliers";
import { useFeedback } from "../hooks/useFeedbackTs";
import { ProductVariantSupplier } from "../backend-v3/orders";

const SupplierVendor = ({ location, supplierId, vendorId }: any) => {
  const [supplierVariants, setSupplierVariants] = useState<ProductVariantSupplier[]>();
  const [vendor, setVendor] = useState();
  const { setError } = useFeedback();
  useEffect(() => {
    async function getData() {
      const res = await getVendor(Number(vendorId));

      if (!res.success) setError(res as any);
      if (res.success) {
        setVendor(res.data);
      }
    }

    getData();
  }, [vendorId]);

  useEffect(() => {
    async function getData() {
      const res = await getSupplierVendorProducts(Number(supplierId), Number(vendorId));
      if (res.err) setError(res.val.message);
      if (res.ok) {
        setSupplierVariants(res.val);
      }
    }

    getData();
  }, [supplierId]);

  return !vendorId || !vendor || !supplierId || !supplierVariants ? (
    <Loading />
  ) : (
    <div>
      <SupplierVendorHeader
        parent={{
          to: `/suppliers/${supplierId}`,
          value: location.state?.supplierName ? location.state.supplierName : "Supplier",
        }}
        id={vendorId}
        name={vendor && (vendor as any).name}
      />
      <SupplierProductsTable loading={false} products={supplierVariants} supplierId={supplierId} />
    </div>
  );
};

SupplierVendor.propTypes = {
  location: PropTypes.shape({ state: PropTypes.shape({ supplierName: PropTypes.string }) }),
  supplierId: PropTypes.string,
  vendorId: PropTypes.string,
};

SupplierVendor.defaultProps = {
  location: { state: { supplierName: null } },
  supplierId: null,
  vendorId: null,
};

export { SupplierVendor };
