import React from "react";

import { HorizontalTable } from "../../HorizontalTable";
import { HorizontalTableCell } from "../../HorizontalTableCell";
import { TableCellInput } from "../../TableCellInputTs";
import { roundToTwo } from "../../../helpersTs";
import { OrderExtended, OrderProduct, OrderStatus } from "../../../backend-v3/orders";
import { OrderUpdate } from "../../../backend-v3/orders";
import { CONSTANTS } from "../../../common/constants";
import { InvoiceExpense, InvoiceWithProductsExpanded } from "../../../backend-v3/invoices";
import { OrderProductsInvoiceWithVariantsFragment } from "../../../generated/graphql";

type Props = {
  loading?: boolean;
  order: OrderExtended;
  invoice?: InvoiceWithProductsExpanded;
  updateAdjustment: (adjustment: number) => void;
};

export const getSubtotal = (products: OrderProductsInvoiceWithVariantsFragment[]) =>
  products.reduce((acc, cur) => {
    return cur.paid_per_unit * cur.qty_received + acc;
  }, 0);

const OrderTotals = ({ loading = false, order, updateAdjustment, invoice }: Props) => {
  const subtotal = getSubtotal(invoice?.products ?? []);

  const isDraft = order.status === CONSTANTS.STATUS.DRAFT;
  const isSent = order.status === CONSTANTS.STATUS.SENT;
  const isReceived = order.status === CONSTANTS.STATUS.RECEIVED;

  function handleUpdateAdjustments(value: string) {
    return updateAdjustment(value ? Number(roundToTwo(parseFloat(value))) : 0);
  }

  return (
    <div className="mt-8">
      {!invoice ? (
        <></>
      ) : (
        <>
          <HorizontalTable
            head={
              <>
                <HorizontalTableCell value={`Sub Total for Invoice #${invoice.invoice_number ?? invoice.id} `} />
                <HorizontalTableCell value="Adjustments" />
                <HorizontalTableCell value="Fixed Expenses" />
                <HorizontalTableCell value="GST" />
                <HorizontalTableCell bold large value={`Total Cost`} />
              </>
            }
            body={
              <>
                <HorizontalTableCell value={roundToTwo(invoice.total_received)} />
                <HorizontalTableCell
                  value={
                    !isReceived ? (
                      <div className="flex items-center -my-1">
                        <TableCellInput
                          className="text-right"
                          disabled={loading}
                          onChange={handleUpdateAdjustments}
                          type={{
                            type: "number",
                            initial: Number(roundToTwo(invoice.adjustments)),
                          }}
                        />
                      </div>
                    ) : (
                      roundToTwo(invoice.adjustments)
                    )
                  }
                />
                <HorizontalTableCell value={roundToTwo(invoice.total_expenses)} />
                <HorizontalTableCell value={roundToTwo(invoice.total_with_gst - invoice.total)} />
                <HorizontalTableCell large value={`$${roundToTwo(invoice.total_with_gst)}`} />
              </>
            }
          />
        </>
      )}
    </div>
  );
};

export { OrderTotals };
