import React from 'react'
import PropTypes from 'prop-types'
import { FaSort } from 'react-icons/fa'

const TableSortableColumnHeading = ({ text, onClick }) => (
  <span className="flex items-center">
    {text}
    <span className="p-2 cursor-pointer" onClick={onClick}>
      <FaSort />
    </span>
  </span>
)

TableSortableColumnHeading.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}

export { TableSortableColumnHeading }
