import { useEffect, useRef } from "react";

import { SupplierCodeData, updateSupplierCode as update, updateSupplierExpectedCost } from "../backend-v3/suppliers";
import { useFeedback } from "./useFeedbackTs";

const useSupplierCode = () => {
  const isMounted = useRef(true);
  const { setError, setSaved } = useFeedback();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  async function updateSupplierCode(data: SupplierCodeData, typ: "cost" | "code") {
    setSaved(false);
    let res;
    if (typ === "code") {
      res = await update([data]);
    } else {
      res = await updateSupplierExpectedCost([data]);
    }
    if (!isMounted.current) return;
    if (res.err) {
      setSaved("error");
      setError(res.val.message);
    }
    if (res.ok) setSaved(true);
  }

  return { updateSupplierCode };
};

export { useSupplierCode };
