import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import equal from "fast-deep-equal";
import { FaSave, FaUndo } from "react-icons/fa";
import { Link } from "@reach/router";

import { Button } from "../../ButtonTs";
import { Dropdown } from "../../DropdownTs";
import { Input } from "../../InputTs";
import { Loading } from "../../Loading";

import { PROP_TYPES } from "../../../common/propTypes";
import { useSupplier } from "../../../hooks/useSupplier";
import { FindXeroContact } from "./SupplierFindXeroContact";
import { debounce } from "throttle-debounce";

const SupplierDetails = ({ loading, supplier, updateSupplier }) => {
  const [updatedSupplier, setUpdatedSupplier] = useState(supplier);
  const [xeroContact, setXeroContact] = useState(supplier.xeroContact);
  const { foundXeroContacts, findXeroContact, isSearchingXero } = useSupplier(supplier.id);
  const [searchContactQuery, setSearchContactQuery] = useState("");

  const [startedSearch, setStartedSearch] = useState(false);

  function handleSave() {
    updateSupplier({
      ...updatedSupplier,
      xeroContactId: xeroContact ? xeroContact.id : null,
    });
  }

  function handleSetSupplier(updatedFields) {
    setUpdatedSupplier({
      ...updatedSupplier,
      ...updatedFields,
    });
  }

  return (
    <div className="mt-2">
      <div className="flex">
        <Input
          className="w-64 mr-2"
          disabled={loading}
          id="supplier-edit-name"
          label="Name"
          onChange={name => handleSetSupplier({ name })}
          value={updatedSupplier.name}
        />
        <Input
          className="w-64"
          disabled={loading}
          id="supplier-edit-email"
          label="Email"
          onChange={email => handleSetSupplier({ email })}
          type="email"
          value={updatedSupplier.email}
        />
      </div>

      <div className="mt-6">
        <FindXeroContact
          startedSearch={startedSearch}
          setStartedSearch={setStartedSearch}
          setSearchQuery={setSearchContactQuery}
          query={searchContactQuery}
          contact={xeroContact}
          isSearching={isSearchingXero}
          setContact={contact => {
            setStartedSearch(false);
            setXeroContact(contact);
            setSearchContactQuery("");
            findXeroContact("");
          }}
          findContact={search => {
            findXeroContact(search);
            if (search === "") {
              setXeroContact(null);
            }
          }}
          foundContacts={foundXeroContacts}
        />
      </div>

      <p className="font-bold mt-6">Contact person</p>
      <div className="flex mt-2">
        <Input
          className="w-64 mr-2"
          disabled={loading}
          id="supplier-edit-firstName"
          label="First Name"
          onChange={contactFirstName => handleSetSupplier({ contactFirstName })}
          type="firstName"
          value={updatedSupplier.contactFirstName}
        />
        <Input
          className="w-64"
          disabled={loading}
          id="supplier-edit-lastName"
          label="Last Name"
          onChange={contactLastName => handleSetSupplier({ contactLastName })}
          type="lastName"
          value={updatedSupplier.contactLastName}
        />
      </div>

      <div className="flex items-center mt-6">
        <Button
          disabled={loading || equal(supplier, updatedSupplier)}
          iconBefore={<FaUndo />}
          onClick={() => setUpdatedSupplier(supplier)}
          value="Undo"
          variant="ghost"
        />
        <Button
          className="ml-2"
          disabled={loading}
          iconBefore={<FaSave />}
          loading={loading}
          onClick={handleSave}
          value="Save"
        />
      </div>
    </div>
  );
};

SupplierDetails.propTypes = {
  loading: PropTypes.bool,
  supplier: PROP_TYPES.SUPPLIER.isRequired,
  updateSupplier: PropTypes.func.isRequired,
};

SupplierDetails.defaultProps = {
  loading: false,
};

export { SupplierDetails };
