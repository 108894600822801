import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'

const Breadcrumb = ({ className, current, ...props }) => {
  const classes = `
    ${!current ? 'font-bold' : ''} text-orange px-3 py-2 capitalize inline-block ${className}
  `

  return !current ? (
    <Link className={`${classes} hover:underline`} {...props} />
  ) : (
    <span className={classes} {...props} />
  )
}

Breadcrumb.propTypes = {
  className: PropTypes.string,
  current: PropTypes.bool,
}

Breadcrumb.defaultProps = {
  className: '',
  current: false,
}

export { Breadcrumb }
