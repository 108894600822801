import React, { useEffect, useContext, useState, useRef } from "react";
import { FaExclamationTriangle } from "react-icons/fa";

import { Authenticate } from "./components/Authenticate";
import { FeedbackProvider } from "./providers/FeedbackProviderTs";
import { LoadingGlobal } from "./components/LoadingGlobal";
import { PageLayout } from "./components/PageLayout";

import { refreshUser } from "./backend-v3/auth";

const App = () => {
  const isMounted = useRef(true);
  const [loading, setLoading] = useState(true);
  const [shopUrl, setShopUrl] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    getUser();

    return () => {
      isMounted.current = false;
    };
  }, []);

  async function getUser() {
    if (!isMounted.current) return;
    let res;
    try {
      res = (await refreshUser()).unwrap();
    } catch (e) {}

    setUser(res);
    setLoading();
  }

  function getShopUrl(user) {
    if (user && user.user && user.user.shop) {
      return user.user.shop.name;
    }
  }

  return !loading ? (
    <FeedbackProvider>
      {user && user.user.shop && <PageLayout shopUrl={getShopUrl(user)} onLogout={setUser} />}
      {!user && <Authenticate setUser={setUser} />}
      {user && !user.user.shop && <p>User is not activated</p>}
    </FeedbackProvider>
  ) : (
    <LoadingGlobal loading={loading} />
  );
};

export default App;
