import { navigate } from "@reach/router";
import { Invoice } from "../../../backend-v3/invoices";

export const getInvoicesAction = (invoices: Invoice[]): string | undefined => {
  let sendInvoicesStatus: string;
  const single = invoices.length === 1;
  if (invoices.length === 0) {
    return undefined;
  } else {
    let countSent = invoices.filter(x => x.xero_invoice_id !== undefined).length;
    if (countSent === invoices.length) {
      sendInvoicesStatus = single ? "Resend to Xero" : "Resend all to Xero";
    } else if (countSent === 0) {
      sendInvoicesStatus = single ? "Send to Xero" : "Send all to Xero";
    } else {
      sendInvoicesStatus = single ? "Send to Xero" : "Send all to Xero";
    }
  }
  return sendInvoicesStatus;
};

// if (order.xero_invoice_link) {
//   window.open(order.xero_invoice_link(), '_blank', 'noopener')
// }

export const invoicesQuery = (invoices: Invoice[]): string => {
  const query = invoices.map(i => i.id).join(" OR ");
  return `/invoices?query=${query}`;
};
