import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FaPlus } from "react-icons/fa";
import { Link } from "@reach/router";

import { Dropdown } from "../../DropdownTs";
import { Input } from "../../InputTs";
import { Loading } from "../../Loading";
import { Modal } from "../../Modal";

import { validateField } from "../../../forms";
import { useSupplier } from "../../../hooks/useSupplier";

const initialErrorState = {
  email: false,
  name: false,
  firstName: false,
};

const CreateSupplierModal = ({ handleCancel, handleCreate, isOpen, loading }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [xeroContactId, setXeroContactId] = useState("");
  const { getXeroContacts, xeroContacts } = useSupplier();
  const [errors, setErrors] = useState(initialErrorState);

  useEffect(() => {
    getXeroContacts();
  }, []);

  function handleSubmit() {
    setErrors(initialErrorState);

    const checkEmail = validateField("Email", email, { isRequired: true, isEmail: true });
    const checkName = validateField("Supplier name", name, { isRequired: true, minLength: 2, maxLength: 50 });
    const checkFirstName = validateField("First name", firstName, { isRequired: true, minLength: 2, maxLength: 50 });

    if (!checkEmail.isValid || !checkName.isValid || !checkFirstName.isValid) {
      return setErrors({
        email: !checkEmail.isValid && checkEmail.error,
        name: !checkName.isValid && checkName.error,
        firstName: !checkFirstName.isValid && checkFirstName.error,
      });
    }

    return handleCreate({ email, name, contactFirstName: firstName, contactLastName: lastName, xeroContactId });
  }

  return (
    <Modal
      closeModal={handleCancel}
      disabled={!name || !email || !firstName || loading}
      heading="New Supplier"
      isOpen={isOpen}
      loading={loading}
      onSubmit={handleSubmit}
      submitIcon={<FaPlus />}
      submitValue="Create"
    >
      <div className="flex mt-6">
        <Input
          className="w-1/2 mr-2"
          error={errors.name}
          id="supplier-name"
          label="Company Name*"
          onChange={value => setName(value)}
          value={name}
        />
        <Input
          className="w-1/2"
          error={errors.email}
          id="supplier-email"
          label="Email*"
          onChange={value => setEmail(value)}
          type="email"
          value={email}
        />
      </div>
      <div className="mt-6">
        {xeroContacts && xeroContacts.length > 0 && (
          <Dropdown
            className="w-64"
            id="xero-contacts"
            label="Xero Contact"
            onChange={setXeroContactId}
            options={xeroContacts.map(x => ({ name: x.name, value: x.id }))}
            value={xeroContactId}
          />
        )}
        {xeroContacts && xeroContacts.length < 1 && (
          <div>
            <span className="font-bold text-sm">Xero Contact</span>
            <p className="mt-2">
              You need to{" "}
              <Link className="text-orange" to="/integrations">
                create a Xero integration
              </Link>
            </p>
          </div>
        )}
        {!xeroContacts && (
          <div className="w-64">
            <Loading loading />
          </div>
        )}
      </div>
      <p className="mt-6">Contact person</p>
      <div className="mt-2 flex">
        <Input
          className="w-1/2 mr-2"
          error={errors.firstName}
          id="supplier-fname"
          label="First Name*"
          onChange={value => setFirstName(value)}
          value={firstName || ""}
        />
        <Input
          className="w-1/2"
          id="supplier-lname"
          label="Last Name"
          onChange={value => setLastName(value)}
          value={lastName || ""}
        />
      </div>
    </Modal>
  );
};

CreateSupplierModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

CreateSupplierModal.defaultProps = {
  isOpen: false,
  loading: false,
};

export { CreateSupplierModal };
