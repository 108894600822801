import React, { useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { Dropdown } from '../../DropdownTs'
import { Modal } from '../../Modal'
import { Location } from '../../../backend-v3/locations'
import { TransferCreate } from '../../../backend-v3/transfers'

type Props = {
  isOpen?: boolean,
  loading?: boolean,
  locations: Location[],
  onClose: () => void,
  onSubmit: (newTransfer: TransferCreate) => void
}

const CreateTransferModal = ({ isOpen = false, loading = false, locations, onClose, onSubmit }: Props) => {
  const [locationFrom, setLocationFrom] = useState(locations[0]?.id)
  const [locationTo, setLocationTo] = useState(locations[1]?.id)

  function handleSetLocationFrom(stringValue: string) {
    const value = Number(stringValue)
    setLocationFrom(value)

    if (value === locationTo) {
      setLocationTo(locations.filter(({ id }) => id !== value)[0]?.id)
    }
  }

  return (
    <Modal
      closeModal={onClose}
      disabled={!locationTo && !locationFrom}
      loading={loading}
      heading="New Transfer"
      isOpen={isOpen}
      onSubmit={() => {
        if (locationFrom && locationTo) {
          onSubmit({
            to_location_id: locationTo,
            from_location_id: locationFrom
          })
        }
      }
      }
      submitIcon={<FaPlus />}
      submitValue="Create"
    >
      <Dropdown
        className="w-64 max-w-full mt-6"
        id="new-order-supplier"
        label="Origin Location"
        onChange={handleSetLocationFrom}
        options={locations.map(({ id: value, name }) => ({ value : value.toString(), name }))}
        value={locationFrom?.toString()}
      />
      <Dropdown
        className="w-64 max-w-full mt-4"
        disabled={!locationTo}
        id="new-order-location"
        label="Destination Location"
        onChange={(x: string) => setLocationTo(Number(x))}
        options={locations
          .filter(({ id }) => id !== locationFrom)
          .map(({ id: value, name }) => ({ value: value.toString(), name }))}
        value={locationTo?.toString()}
      />
    </Modal>
  )
}

export { CreateTransferModal }
