import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'

const SidebarLink = ({ className, ...props }) => {
  const classes = `p-3 w-full inline-block ${className}`

  return (
    <Link
      getProps={({ isCurrent, isPartiallyCurrent }) => ({
        className: isPartiallyCurrent
          ? `bg-orange text-white ${classes} ${
              isCurrent ? 'cursor-default' : 'hover:bg-orange-500 active:bg-orange-600 focus:bg-orange-600'
            }`
          : `${classes} hover:bg-gray-200 active:bg-gray-300 focus:bg-gray-300`,
      })}
      {...props}
    />
  )
}

SidebarLink.propTypes = {
  className: PropTypes.string,
}

SidebarLink.defaultProps = {
  className: '',
}

export { SidebarLink }
