import React from 'react'
import PropTypes from 'prop-types'

const Tab = ({ className, fullWidth, isCurrent, label, onClick }) => (
  <li
    className={`rounded-t ${fullWidth ? 'flex-1' : ''} ${
      isCurrent ? 'bg-black text-white' : 'bg-white hover:bg-gray-100 z-0'
    } ${className}`}
  >
    <button className="block w-full px-6 py-3 font-bold capitalize" onClick={onClick}>
      {label}
    </button>
  </li>
)

Tab.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  isCurrent: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

Tab.defaultProps = {
  className: '',
  fullWidth: false,
  isCurrent: false,
}

export { Tab }
