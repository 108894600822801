import React from 'react'
import PropTypes from 'prop-types'

import { Loading } from './Loading'

const LoadingLocal = ({ loading }) =>
  loading ? (
    <div className="absolute top-0 left-0 w-full h-full bg-lighten-800 flex items-center justify-center z-50">
      <Loading />
    </div>
  ) : null

LoadingLocal.propTypes = {
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

LoadingLocal.defaultProps = {
  loading: false,
}

export { LoadingLocal }
