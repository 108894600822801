import React from 'react'
import PropTypes from 'prop-types'
import Picker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle'
import { FaCalendarAlt } from 'react-icons/fa'
import { Range } from '../hooks/useOrderTs'

import * as moment from 'moment'

type Props = {
  className?: string,
  onChange: (x: Range) => void,
  value: Range,
  maxDate: Date,
  id: string
}

const DateRangePicker = ({ className = '', onChange, value, ...props } : Props ) => 
{
  return (
    <Picker
      calendarIcon={<FaCalendarAlt />}
      className={className}
      clearIcon={null}
      format="dd/MM/y"
      onChange={(x) => {
        if (x) {
          const range = {
            from: moment(x[0]),
            to: moment(x[1])
          }
          onChange(range)
        }
        else onChange(value)
      }}
      // onChange={(x) => onChange({from: moment(x[0])})}
      value={[value.from.toDate(), value.to.toDate()]}
      {...props}
    />
  )

}

export { DateRangePicker }
