import React from "react";
import PropTypes from "prop-types";
import { FaPlus } from "react-icons/fa";

import { Button } from "../../ButtonTs";
import { Header } from "../../Header";

const TransfersHeader = ({ onCreateClick }) => (
  <Header
    actions={
      onCreateClick ? <Button iconBefore={<FaPlus />} onClick={onCreateClick} value="New" variant="primary" /> : null
    }
    crumbs={[{ to: "/transfers", value: "Transfers" }]}
  >
    {!onCreateClick && <p>To create a transfer you first need to have more than one location set up in Shopify.</p>}
  </Header>
);

TransfersHeader.propTypes = {
  onCreateClick: PropTypes.func,
};

TransfersHeader.defaultProps = {
  onCreateClick: null,
};

export { TransfersHeader };
