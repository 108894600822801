import React from 'react'
import PropTypes from 'prop-types'
import { FaTimes } from 'react-icons/fa'

const Toast = ({ message, onClose }) => (
  <div className="max-w-full bg-orange text-white rounded relative py-4 px-10 shadow-lg">
    <div className="absolute top-0 right-0 p-2 cursor-pointer" onClick={() => onClose()}>
      <FaTimes />
    </div>
    <span className="text-2xl">{message}</span>
  </div>
)

Toast.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.node,
}

Toast.defaultProps = {
  message: '',
}

export { Toast }
