import React from "react";
import PropTypes from "prop-types";

type Props = {
  alignText?: string;
  className?: string;
  error?: string;
  label?: string;
  onChange: (x: string) => void;
  value?: string;
  type?: string;
  readOnly?: boolean;
  id: string;
  disabled?: boolean;
  placeholder?: string;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  autoComplete?: string;
};

const Input = ({
  alignText = "left",
  className = "",
  error,
  id,
  label,
  onChange,
  readOnly = false,
  type = "string",
  value = "",
  ...props
}: Props) => {
  function getBorderStyle() {
    return error ? "border-red focus:border-red" : "focus:border-black";
  }

  return (
    <div className={className}>
      {label && (
        <label className="font-bold text-sm" htmlFor={id}>
          {label}
        </label>
      )}
      <div className="relative mt-1">
        {!readOnly ? (
          <input
            id={id}
            value={value}
            onChange={e => onChange(e.target.value)}
            className={`block rounded appearance-none bg-white w-full py-2 px-3 focus:outline-none border disabled:border-gray-300 disabled:text-gray-300 text-${alignText}
          ${getBorderStyle()}
        `}
            type={type}
            {...props}
          ></input>
        ) : (
          <span className={`block rounded bg-white w-full py-2 px-3 text-${alignText}`}>{value}</span>
        )}
        {error && <p className="text-xs font-bold text-red mt-1">{error}</p>}
      </div>
    </div>
  );
};

export { Input };
