import React from "react";
import PropTypes from "prop-types";
import { navigate, Link } from "@reach/router";
import { FaTrash } from "react-icons/fa";

import { Table } from "../../TableTs";
import { TableCell } from "../../TableCellTs";
import { TableHeadCell } from "../../TableHeadCell";
import { TableHeadRow } from "../../TableHeadRow";
import { TableRow } from "../../TableRowTs";
import { TableRowActions } from "../../TableRowActions";

import { PROP_TYPES } from "../../../common/propTypes";
import { sortArrayByObjectKey } from "../../../helpers";
import { Supplier, SupplierWithoutVendors } from "../../../backend-v3/suppliers";

type Props = {
  onDeleteClick: any;
  suppliers: Supplier[];
};
const SuppliersTable = ({ onDeleteClick, suppliers }: Props) => (
  <Table
    childIds={suppliers.map(({ id }) => id)}
    className="mt-6"
    renderHead={() => (
      <TableHeadRow>
        <TableHeadCell value="Supplier Name" width="3/12" />
        <TableHeadCell value="Vendors" width="4/12" />
        <TableHeadCell value="Email" width="64" />
        <TableHeadCell value="Contact Name" width="64" />
        <TableHeadCell alignment="right" width="32" value="Actions" />
      </TableHeadRow>
    )}
    renderBody={() =>
      sortArrayByObjectKey(suppliers, "name").map(
        ({ contact_first_name, contact_last_name, email, id, name, vendors }: Supplier) => (
          <TableRow key={id} onClick={() => navigate(`/suppliers/${id}`)}>
            <TableCell
              value={{
                type: "element",
                initial: <Link to={`/suppliers/${id}`}>{name}</Link>,
              }}
            />

            <TableCell
              value={{
                type: "element",
                initial: (
                  <>
                    {vendors.reduce(
                      (acc, cur, i) => [
                        ...acc,
                        <Link className="hover:underline" key={cur.id} to={`/vendors/${cur.id}`}>
                          {cur.name}
                        </Link>,
                        ...[i < vendors.length - 1 ? [<span key={`_${cur.id}`}>, </span>] : []],
                      ],
                      [] as any
                    )}
                  </>
                ),
              }}
            />
            <TableCell capitalize={false} value={{ type: "string", initial: email ?? "" }} />
            <TableCell
              value={{
                type: "string",
                initial: `${contact_first_name || ""}${contact_first_name ? " " : ""}${contact_last_name || ""}`,
              }}
            />
            <TableRowActions
              actions={[
                {
                  id: "delete",
                  iconBefore: <FaTrash />,
                  onClick: e => {
                    e.stopPropagation();
                    onDeleteClick(id);
                  },
                },
              ]}
            />
          </TableRow>
        )
      )
    }
  />
);

SuppliersTable.propTypes = {
  onDeleteClick: PropTypes.func.isRequired,
  suppliers: PropTypes.arrayOf(PROP_TYPES.SUPPLIER),
};

SuppliersTable.defaultProps = {
  suppliers: [],
};

SuppliersTable.displayName = "SuppliersTable";

export { SuppliersTable };
