import PropTypes, { bool } from 'prop-types'

const EXPENSE = PropTypes.shape({
  amount: PropTypes.number,
  createdAt: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string,
  orderId: PropTypes.string,
  updatedAt: PropTypes.string,
})

const LOCATION = PropTypes.shape({
  companyName: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  line3: PropTypes.string,
  city: PropTypes.string,
  postcode: PropTypes.string,
  phoneNumber: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
})

const PRODUCT = PropTypes.exact({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lastPricePaid: PropTypes.number,
  sku: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  vendorId: PropTypes.number,
  vendorName: PropTypes.string,
  quantityInStock: PropTypes.number,
  quantityOnOrder: PropTypes.number,
  quantitySold: PropTypes.number,
  supplierCode: PropTypes.string,
})

const ORDER_PRODUCT = PropTypes.exact({
  draftLine: bool,
  costPaid: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  quantityOrdered: PropTypes.number,
  quantityReceived: PropTypes.number,
  product: PRODUCT,
})

const STOCKTAKE_PRODUCT = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  costOfGoods: PropTypes.number,
  product: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    sku: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    vendorId: PropTypes.number,
  }),
  quantityCounted: PropTypes.number,
  quantityExpected: PropTypes.number,
})

const SUPPLIER = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  contactFirstName: PropTypes.string,
  contactLastName: PropTypes.string,
  email: PropTypes.string,
  name: PropTypes.string,
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
})

const VENDOR = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  suppliers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      contactFirstName: PropTypes.string,
      contactLastName: PropTypes.string,
      email: PropTypes.string,
      name: PropTypes.string,
    }),
  )
})

const PROP_TYPES = {
  ERROR: PropTypes.shape({
    status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    messages: PropTypes.arrayOf(PropTypes.string),
  }),
  EXPENSE,
  EXPENSES: PropTypes.arrayOf(EXPENSE),
  INTEGRATION: PropTypes.shape({
    authUrl: PropTypes.string,
    name: PropTypes.string,
  }),
  LOCATION,
  ORDER: PropTypes.shape({
    adjustments: PropTypes.number,
    createdAt: PropTypes.string,
    fixedExpenses: PropTypes.arrayOf(EXPENSE),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    invoiceDate: PropTypes.string,
    invoiceNumber: PropTypes.string,
    location: LOCATION,
    locationId: PropTypes.number,
    notes: PropTypes.string,
    orderDate: PropTypes.string,
    orderNumber: PropTypes.number,
    paymentDue: PropTypes.string,
    products: PropTypes.arrayOf(ORDER_PRODUCT),
    status: PropTypes.string,
    supplier: SUPPLIER,
    supplierId: PropTypes.number,
    supplierInvoiceNumber: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
  PRODUCT,
  ORDER_PRODUCT,
  STOCKTAKE: PropTypes.shape({
    createdAt: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    location: LOCATION,
    locationId: PropTypes.string,
    products: PropTypes.arrayOf(STOCKTAKE_PRODUCT),
    status: PropTypes.string,
    stocktakeNumber: PropTypes.number,
    updatedAt: PropTypes.string,
    vendor: VENDOR,
    vendorId: PropTypes.string,
  }),
  STOCK_POSITION: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      quantityInStock: PropTypes.number,
      quantitySold: PropTypes.number,
      quantityOnOrder: PropTypes.number
    })
  ),
  SUPPLIER,
  TABLE_ROW: {
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        tabIndex: PropTypes.string,
        id: PropTypes.string,
        iconAfter: PropTypes.node,
        iconBefore: PropTypes.node,
        onClick: PropTypes.func,
        size: PropTypes.string,
        value: PropTypes.string,
        variant: PropTypes.string,
      }),
    ),
    bulkAction: PropTypes.shape({
      bulkChecked: PropTypes.arrayOf(PropTypes.string),
      onBulkChange: PropTypes.func,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        editable: PropTypes.bool,
        id: PropTypes.string.isRequired,
        type: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node]),
        url: PropTypes.string,
      }),
    ).isRequired,
    loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  },
  VENDOR
}

export { PROP_TYPES }
