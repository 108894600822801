import { useEffect, useState, useRef } from "react";

import { useProducts } from "./useProducts";
import { useFeedback } from "./useFeedbackTs";

import * as api from "../backend-v3/transfers";
import * as ordersApi from "../backend-v3/orders";

const useTransfer = (transferId: number) => {
  const isMounted = useRef(true);

  const [isFetchingTransfer, setIsFetchingTransfer] = useState(false);
  const [isTransferModalLoading, setIsTransferModalLoading] = useState(false);
  const [isUpdatingTransfer, setIsUpdatingTransfer] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [transfer, setTransfer] = useState(undefined as undefined | api.TransferExtended);
  const { setError, setSaved, setToast } = useFeedback();

  const { setAllProducts, allProducts, setUpProducts } = useProducts();

  useEffect(() => {
    if (transferId) getData();
    return () => {
      isMounted.current = false;
    };
  }, []);

  async function getData() {
    setIsFetchingTransfer(true);

    const res = await api.getTransfer(transferId);

    if (!isMounted.current) return;
    if (res.err) {
      // setNotFound(true);
      setError(res.val.message);
    } else {
      const allProdsData = await ordersApi.getAllProducts();
      if (allProdsData.err) {
        // setNotFound(true);
        setError(allProdsData.val.message);
      } else {
        setTransfer(res.val);
        setUpProducts(allProdsData.val);
      }
    }
    setIsFetchingTransfer(false);
  }

  async function addTransferProducts(pvIds: number[]) {
    setIsTransferModalLoading(true);
    const res = await api.addTransferProducts(transferId, pvIds);
    if (res.err) {
      setError(res.val.message);
    } else {
      setTransfer(res.val);
      setToast("Products added!");
    }
    setIsTransferModalLoading(false);
  }

  async function deleteTransferProducts(tpIds: number[]) {
    setIsUpdatingTransfer(true);

    const res = await api.deleteTransferProducts(tpIds);
    if (res.err) {
      setError(res.val.message);
    } else {
      setTransfer(transfer => {
        if (transfer) {
          const products = transfer.products.filter(tp => !tpIds.includes(tp.id));
          return { ...transfer, products };
        }
        return transfer;
      });
      setToast("Products removed!");
      setIsUpdatingTransfer(false);
    }
  }

  async function changeTransferStatus(status: api.TransferStatus) {
    setIsTransferModalLoading(true);

    const res = await api.updateTransfer(transferId, {
      status,
    });

    if (res.err) {
      setError(res.val.message);
    } else {
      setTransfer(x => {
        if (x) return { ...x, transfer: res.val };
        return x;
      });
      setToast("Updated!");
      setIsTransferModalLoading(false);
    }
  }

  async function updateTransferProduct(id: number, data: api.TransferProductUpdate) {
    setSaved(false);
    const res = await api.updateTransferProduct(id, data);
    if (res.err) {
      setSaved("error");
      setError(res.val.message);
    } else {
      setTransfer(t => {
        if (t) return { ...t, products: t.products.map(product => (product.id === id ? res.val : product)) };
        return t;
      });
      setSaved(true);
    }
  }

  return {
    addTransferProducts,
    deleteTransferProducts,
    changeTransferStatus,
    updateTransferProduct,
    transfer,
    isFetchingTransfer,
    isTransferModalLoading,
    isUpdatingTransfer,
    notFound,
    allProducts,
  };
};

export { useTransfer };
