import React from 'react'
import PropTypes from 'prop-types'
import Picker from 'react-date-picker/dist/entry.nostyle'
import { FaCalendarAlt, FaTimes } from 'react-icons/fa'



type Props = {
  className?: string,
  readOnly: boolean,
  value?: Date,
  onChange: (x: Date | Date[] | null) => void
}

const DatePicker = ({ className = '', onChange, readOnly, value, ...props }: Props) => (
  <Picker
    calendarIcon={<FaCalendarAlt />}
    className={className}
    clearIcon={<FaTimes />}
    disabled={readOnly}
    format="dd/MM/y"
    onChange={onChange}
    value={value}
    {...props}
  />
)


export { DatePicker }
