import React from "react";
import PropTypes from "prop-types";

import { theme } from "../../../tailwind.config";

const Logo = ({ fill }) => (
  // <svg fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124.2 46.2" {...props}>
  //   <path d="M0 40.5v-6.8c2.8 2.6 6.4 3.9 10.8 3.9 1.5 0 2.7-.2 3.6-.8.9-.5 1.3-1.2 1.3-2 0-.6-.3-1.1-.8-1.7-.6-.5-1.9-1.2-4-2.1-2.1-.8-3.4-1.3-3.8-1.5C3 27.7.9 25 .9 21.3c0-2.6 1-4.6 3-6.2 2-1.6 4.5-2.3 7.5-2.3 3.2 0 5.8.7 7.9 2v6.3c-1-.6-2.1-1.1-3.4-1.6-1.3-.4-2.7-.6-4.1-.6-1.3 0-2.3.2-3.1.6s-1.2 1-1.2 1.8c0 .2.1.4.2.8s.5.7 1 1.1c.6.3 1.8.8 3.7 1.5 2.5 1 4.4 1.8 5.7 2.6 1.3.8 2.4 1.7 3.2 2.9a7.5 7.5 0 011.2 4.3c0 1.1-.2 2.2-.7 3.3-.4 1.1-1.1 2-2.1 2.9-.9.9-2.2 1.5-3.7 2s-3.3.8-5.3.8c-4.1.1-7.7-.9-10.7-3z" />
  //   <path d="M26.6 42.9V2.6h6.9v23.6l9.8-12.4h7.9l-11 13.9 12 15.2H44L33.5 29.4v13.5h-6.9z" />
  //   <path d="M54.3 31.3V13.7h7v17c0 2.2.6 3.8 1.8 4.9 1.2 1.1 2.7 1.6 4.6 1.6s3.4-.5 4.6-1.6 1.8-2.7 1.8-4.9v-17h7v17.4c0 1.5-.2 3-.7 4.5s-1.3 2.8-2.4 4-2.5 2.1-4.3 2.9c-1.7.7-3.8 1.1-6.1 1.1-2.8 0-5.2-.5-7.1-1.6-2-1.1-3.5-2.5-4.6-4.3-1-1.7-1.6-3.9-1.6-6.4z" />
  //   <g>
  //     <path
  //       d="M115.7 44.7l-10.6-16.1-2-3.1c-.5.9-1.2 2.1-1.9 3.2l-10.4 16h-5.6L100.1 22 90.3 7.1c-.6-1-1.4-1.1-2.6-1.1h-2.1V1.6h2.1c3.4 0 5 .6 7.2 3.9l6.3 9.7c.7 1.1 1.5 2.3 2.1 3.2.5-.9 1.2-2.2 2-3.3l6.3-9.7c2.1-3.3 3.7-3.9 7.2-3.9h2.1v4.3h-2.1c-1.2 0-1.9.2-2.6 1.1l-9.8 14.8 15 23h-5.7 0z"
  //       fill="none"
  //       stroke={accent}
  //       strokeWidth="3"
  //     />
  //   </g>
  // </svg>
  <svg
    // fill={fill}
    // className="w-full"
    fill={fill}
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 1000.49 536.22"
  >
    <defs>
      <style></style>
      <linearGradient
        id="linear-gradient"
        x1="631.72"
        y1="267.84"
        x2="845.56"
        y2="267.84"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.29" stop-color="#f37021" />
        <stop offset="0.9" stop-color="#ef4136" />
      </linearGradient>
    </defs>
    <path
      class="cls-1"
      d="M242.44,284.75l-15.37-5.84c-8.54-3.1-9.21-5.46-9.21-6.11,0-2.44,3-3.67,8.87-3.67,8.12,0,22.2,7.29,29,11.08a5.31,5.31,0,0,0,6.71-1.29l20.42-25.15a5.42,5.42,0,0,0-1.44-7.94C267,236.09,245.22,226,226.43,226c-18.55,0-33.55,4.25-44.58,12.64-11.19,8.5-16.86,20.28-16.86,35,0,11.74,3.7,21.41,11,28.74,7.12,7.16,18.81,13.88,34.73,20l17.19,6.71c7.16,2.82,7.71,5,7.71,5.55,0,3.75-6.32,4.54-11.63,4.54-11.11,0-30.43-11.27-38.66-16.41a5.32,5.32,0,0,0-6.91,1.13l-20.59,24.95a5.3,5.3,0,0,0,.5,7.3c5.89,5.36,17.6,15.44,26.1,19a99.07,99.07,0,0,0,38.64,7.44c18.73,0,34.48-4.44,46.86-13.2,12.32-9.09,18.57-21.57,18.57-37.1,0-11.55-3.65-21-10.85-28C270.61,297.41,258.77,290.84,242.44,284.75Z"
    />
    <path
      class="cls-1"
      d="M459,229.5H406.77a5.31,5.31,0,0,0-4.12,2L359.11,285V161.9a5.31,5.31,0,0,0-5.31-5.31H307.87a5.31,5.31,0,0,0-5.31,5.31V373.77a5.32,5.32,0,0,0,5.31,5.32H353.8a5.32,5.32,0,0,0,5.31-5.32V306.09l50.22,70.76a5.33,5.33,0,0,0,4.33,2.24h49.65a5.32,5.32,0,0,0,4.36-8.36l-49.6-70.94a5.31,5.31,0,0,1,.23-6.4l44.83-55.22A5.32,5.32,0,0,0,459,229.5Z"
    />
    <path
      class="cls-1"
      d="M579.15,229.5a5.31,5.31,0,0,0-5.31,5.32v78.52a18.23,18.23,0,0,1-5.71,14c-3.88,3.68-8.63,5.46-14.53,5.46-6.11,0-11-1.78-14.82-5.46a18.23,18.23,0,0,1-5.71-14V234.82a5.32,5.32,0,0,0-5.32-5.32H481.83a5.32,5.32,0,0,0-5.32,5.32v74.43c0,21.64,7.33,39.47,21.78,53s33,20.34,55.31,20.34,40.86-6.85,55.17-20.35,21.62-31.35,21.62-53V234.82a5.32,5.32,0,0,0-5.31-5.32Z"
    />
    <path
      class="cls-2"
      d="M776.34,240.29,757.6,216a4.62,4.62,0,0,1,0-5.64l40.57-52.55a4.6,4.6,0,0,1,3.65-1.79h37.46a4.6,4.6,0,0,1,3.65,7.42l-59.3,76.83A4.6,4.6,0,0,1,776.34,240.29Zm-142-76.83L711.91,264a4.61,4.61,0,0,1,0,5.63L632.69,372.21a4.61,4.61,0,0,0,3.65,7.43H673.8a4.61,4.61,0,0,0,3.65-1.8L735,303.3a4.61,4.61,0,0,1,7.3,0l57.54,74.54a4.61,4.61,0,0,0,3.65,1.8h37.46a4.61,4.61,0,0,0,3.65-7.43L679.11,157.83a4.6,4.6,0,0,0-3.65-1.79H638A4.6,4.6,0,0,0,634.35,163.46Z"
    />
  </svg>
);

export { Logo };
