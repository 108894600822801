import { useEffect, useState, useRef } from "react";
import * as api from "../backend-v3/invoices";
import { useFeedback } from "./useFeedbackTs";

const useInvoice = (invoiceId: number) => {
  const isMounted = useRef(true);
  const [status, setStatus] = useState<"fetching">();
  const [invoice, setInvoice] = useState<api.InvoiceWithProductsExpanded>();
  const [notFound, setNotFound] = useState(false);
  const [sendingToXero, setSendingToXero] = useState(false);
  const { setError, setToast } = useFeedback();

  useEffect(() => {
    if (invoiceId) getData();
    return () => {
      isMounted.current = false;
    };
  }, []);

  async function getData() {
    const res = await api.getInvoice(invoiceId);
    if (!isMounted.current) return;
    if (res.err) {
      setNotFound(true);
    } else {
      setInvoice(res.val);
      setStatus(undefined);
    }
  }

  async function sendToXero() {
    setSendingToXero(true);
    const res = await api.sendToXero(Number(invoiceId));
    if (res.err) {
      setError(res.val.message);
      return;
    }
    setInvoice(prev => {
      if (prev) return { xero_invoice_id: res.val, ...prev };
    });
    setToast("Sent to Xero!");
    setSendingToXero(false);
  }

  return {
    invoice,
    notFound,
    status,
    sendingToXero,
    sendToXero,
  };
};

export { useInvoice };
