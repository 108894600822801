// todo: get rid of error unwraps

import { navigate } from "@reach/router";
import { useEffect, useState, useRef } from "react";
import * as api from "../backend-v3/transfers";
import { useFeedback } from "./useFeedbackTs";

const useTransfers = () => {
  const isMounted = useRef(true);
  const [isFetchingTransfers, setIsFetchingTransfers] = useState(false);
  const [isUpdatingTransfers, setIsUpdatingTransfers] = useState(false);
  const [transfers, setTransfers] = useState([] as api.Transfer[]);
  const { setError, setToast } = useFeedback();

  useEffect(() => {
    getData();
    return () => {
      isMounted.current = false;
    };
  }, []);

  async function getData() {
    setIsFetchingTransfers(true);
    const data = await api.getTransfers();

    if (data.err) {
      setError(data.val.message);
      return;
    }
    setTransfers(data.val);
    setIsFetchingTransfers(false);
  }

  async function createTransfer(newTransfer: api.TransferCreate) {
    setIsUpdatingTransfers(true);
    const data = await api.createTransfer(newTransfer);
    if (!isMounted.current) return;
    if (data.err) {
      setError(data.val.message);
      return;
    }
    setToast(`Transfer created!`);
    navigate(`/transfers/${data.val}`);
    setIsUpdatingTransfers(false);
  }

  async function deleteTransfer(transferId: number) {
    setIsUpdatingTransfers(true);
    const data = await api.deleteTransfer(transferId);
    if (!isMounted.current) return;
    if (data.err) {
      setError(data.val.message);
      return;
    }
    setTransfers(prevTransfers => prevTransfers.filter(t => t.id !== transferId));
    setToast(`Deleted!`);
    setIsUpdatingTransfers(false);
  }

  return {
    createTransfer,
    deleteTransfer,
    isFetchingTransfers,
    isUpdatingTransfers,
    transfers,
  };
};

export { useTransfers };
