import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { Button } from "./ButtonTs";

const Modal = ({
  cancelValue,
  children,
  className,
  closeModal,
  disabled,
  heading,
  isOpen,
  loading,
  maxWidth,
  onSubmit,
  submitIcon,
  submitValue,
}) => {
  useEffect(() => {
    if (typeof document !== "undefined") {
      document.body.style.overflowY = isOpen ? "hidden" : "";
    }

    return () => {
      if (typeof document !== "undefined") {
        document.body.style.overflowY = "";
      }
    };
  }, [isOpen]);

  return isOpen ? (
    <div
      className={`fixed top-0 left-0 z-50 bg-darken-800 w-full h-screen p-4 flex items-center justify-center${className}`}
      onClick={closeModal}
    >
      <div className={`w-full max-w-${maxWidth}`} onClick={e => e.stopPropagation()}>
        <div className="bg-white p-8 w-full rounded">
          {heading && <h2 className="font-bold border-b border-gray-100 -mx-8 px-8 pb-2">{heading}</h2>}
          {children}
          <div className="flex mt-8">
            <Button className="mr-2" disabled={loading} onClick={closeModal} value={cancelValue} variant="ghost" />
            {(submitIcon || submitValue) && (
              <Button
                disabled={disabled}
                loading={loading}
                onClick={onSubmit}
                iconBefore={submitIcon}
                value={submitValue}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

Modal.propTypes = {
  cancelValue: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  closeModal: PropTypes.func.isRequired,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  maxWidth: PropTypes.string,
  onSubmit: PropTypes.func,
  submitIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  submitValue: PropTypes.string,
};

Modal.defaultProps = {
  cancelValue: "Cancel",
  className: "",
  disabled: false,
  heading: null,
  isOpen: false,
  loading: false,
  maxWidth: "xl",
  onSubmit: null,
  submitIcon: null,
  submitValue: null,
};

export { Modal };
