import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { animated, useTransition, useSpring } from "react-spring";
import { FaCheck, FaSyncAlt, FaExclamationTriangle } from "react-icons/fa";

import { Breadcrumb } from "./Breadcrumb";
import { Save } from "./Save";

import { useFeedback } from "../hooks/useFeedbackTs";

const Header = ({ actions, className, children, crumbs }) => {
  const { saved, loading } = useFeedback();

  const { transform } = useSpring({
    loop: true,
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg)" },
    config: {
      duration: 100000,
    },
  });

  const Saving = props => {
    const { transform } = useSpring({
      loop: true,
      from: { transform: "rotate(0deg)" },
      to: { transform: "rotate(360deg)" },
      config: {
        duration: 1000,
      },
    });

    return (
      <div className="flex items-center justify-center text-sm" {...props}>
        <span className="mr-2">Loading...</span>
        <animated.span
          style={{
            transform,
          }}
        >
          <FaSyncAlt />
        </animated.span>
      </div>
    );
  };

  const AnimatedSaving = animated(Saving);

  return (
    <div
      className={`sticky py-4 -mt-4 mb-6 bg-white z-10 flex items-center justify-between border-b border-gray-100 ${className}`}
      style={{ top: "2.8rem" }}
    >
      <div className="-ml-3">
        {crumbs.map((crumb, i) => (
          <Fragment key={crumb.value}>
            <Breadcrumb to={crumb.to} current={i >= crumbs.length - 1}>
              {crumb.value}
            </Breadcrumb>
            {i < crumbs.length - 1 && <span>/</span>}
          </Fragment>
        ))}
      </div>
      {children && <div>{children}</div>}
      <div className="flex items-center">
        <div className="flex items-center justify-center text-sm pr-4">{loading && <AnimatedSaving />}</div>

        <Save saved={saved} />
        {actions && <div className="flex align-center">{actions}</div>}
      </div>
    </div>
  );
};

Header.propTypes = {
  actions: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    })
  ).isRequired,
};

Header.defaultProps = {
  actions: [],
  className: "",
  children: null,
};

export { Header };
