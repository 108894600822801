import { BACKEND_CONSTANTS } from "./constants";
import { query } from "./helpers";
import { gql } from "graphql-request";
import { legacyRequest } from "../backend-v3/helpers";

function transformIntegrations(i) {
  return [
    {
      name: "xero",
      active: i.xeroEnabled,
    },
  ];
}

const addIntegration = async integration => {
  let q;
  switch (integration) {
    case "xero":
      q = gql`
        mutation XeroAuthorize {
          xero {
            url: authorize
          }
        }
      `;
      break;
  }

  let res = await legacyRequest(q);
  return res;
};

const removeIntegration = async integration => {
  let q;
  switch (integration) {
    case "xero":
      q = gql`
        mutation XeroRemove {
          xero {
            remove
          }
        }
      `;
      break;
  }

  let res = await legacyRequest(q);
  return res;
};

const getIntegrations = async () => {
  const q = gql`
    query Integrations($id: Int!) {
      integrations_status(where: { id: { _eq: $id } }) {
        xero_enabled
      }
    }
  `;
  let res = await legacyRequest(q, { id: window.auth.user.shop.id });
  if (!res.success) return res;
  res.data = transformIntegrations(res.data.integrationsStatus[0]);
  return res;
};

export { getIntegrations, removeIntegration, addIntegration };
