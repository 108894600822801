function hasLength(value) {
  return value.toString().trim().length > 0
}

function isEmail(value) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(value).toLowerCase())
}

function isMinLength(value, minLength) {
  return value.toString().trim().length >= minLength
}

function isMaxLength(value, maxLength) {
  return value.toString().trim().length <= maxLength
}

const errors = (field, x) => ({
  hasLength: `${field} is required!`,
  isEmail: `${field} must be valid!`,
  isMinLength: `${field} must be a min. of ${x} characters!`,
  isMaxLength: `${field} must be a min. of ${x} characters!`,
})

const validateField = (field, value, config) => {
  if (config.isRequired && !hasLength(value)) return { isValid: false, error: errors(field).hasLength }
  if (config.isEmail && !isEmail(value)) return { isValid: false, error: errors(field).isEmail }
  if (config.minLength && !isMinLength(value, config.minLength))
    return { isValid: false, error: errors(field, config.minLength).isMinLength }
  if (config.maxLength && !isMaxLength(value, config.maxLength))
    return { isValid: false, error: errors(field, config.maxLength).isMaxLength }

  return { isValid: true }
}

export { validateField }
