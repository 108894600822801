module.exports = {
  theme: {
    fontFamily: {
      sans: ['IBM Plex Sans', 'Helvetica Neue', 'Helvetica', 'sans-serif'],
    },
    colors: {
      black: '#000',
      'darken-100': 'rgba(0,0,0,0.1)',
      'darken-200': 'rgba(0,0,0,0.2)',
      'darken-800': 'rgba(0,0,0,0.8)',
      'lighten-50': 'rgba(255,255,255,0.05)',
      'lighten-800': 'rgba(255,255,255,0.8)',
      gray: '#787878',
      'gray-100': '#f2f2f2',
      'gray-200': '#d9d9d9',
      'gray-300': '#bfbfbf',
      'gray-400': '#a6a6a6',
      'gray-500': '#8c8c8c',
      'gray-600': '#737373',
      'gray-700': '#595959',
      'gray-800': '#404040',
      'gray-900': '#262626',
      'gray-1000': '#0d0d0d',
      orange: '#ff6602',
      'orange-100': '#fff0e5',
      'orange-200': '#ffd1b3',
      'orange-300': '#ffb280',
      'orange-400': '#ff934d',
      'orange-500': '#ff741a',
      'orange-600': '#e65b00',
      'orange-700': '#b34700',
      'orange-800': '#803200',
      'orange-900': '#4d1e00',
      'orange-1000': '#1a0a00',
      'tint-100': 'rgb(255, 116, 26, 0.1)',
      'tint-200': 'rgb(255, 116, 26, 0.2)',
      red: '#ff4258',
      white: '#fff',
    },
    inset: {
      0: 0,
      auto: 'auto',
      full: '100%',
      '-full': '-100%',
      '1/2': '50%',
    },
    extend: {
      height: {
        64: '16rem',
      },
      maxHeight: {
        '1/2': '50vh',
      },
      maxWidth: {
        site: '120rem',
      },
      minWidth: {
        72: '18rem',
      },
      width: {
        72: '18rem',
      },
      zIndex: {
        60: 60,
      },
    },
  },
  variants: {
    backgroundColor: ['active', 'disabled', 'responsive', 'hover', 'focus'],
    borderWidth: ['disabled', 'responsive', 'hover'],
    borderColor: ['disabled', 'responsive', 'hover'],
    fontSize: ['responsive', 'hover'],
    textColor: ['disabled', 'responsive', 'hover'],
  },
  plugins: [],
}
