import React from "react";
import PropTypes from "prop-types";

import { LoadingLocal } from "./LoadingLocal";

const HorizontalTable = ({ className, body, head, loading }) => (
  <div className="relative">
    <table className={`flex mt-2 w-full ${className}`}>
      <thead className="flex-1">
        <tr className="block">{head}</tr>
      </thead>
      <tbody>
        <tr className="text-right block w-32">{body}</tr>
      </tbody>
    </table>
    <LoadingLocal loading={loading} />
  </div>
);

HorizontalTable.propTypes = {
  className: PropTypes.string,
  body: PropTypes.node.isRequired,
  head: PropTypes.node.isRequired,
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

HorizontalTable.defaultProps = {
  className: "",
  loading: false,
};

export { HorizontalTable };
