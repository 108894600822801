import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import { navigate } from '@reach/router'

import { CreateOrderModal } from '../components/Layouts/Orders/CreateOrderModal'
import { DeleteOrderModal } from '../components/Layouts/Orders/DeleteOrderModal'
import { Loading } from '../components/Loading'
import { OrdersHeader } from '../components/Layouts/Orders/OrdersHeader'
import { OrdersTable } from '../components/Layouts/Orders/OrdersTableTs'
import { Tabs } from '../components/Tabs'

import { CONSTANTS } from '../common/constants'
import { useOrders } from '../hooks/useOrdersTs'
import { useLocations } from '../hooks/useLocationsTs'
import { useSuppliers } from '../hooks/useSuppliersTs'

import { getOrders, Order } from '../backend-v3/orders'
import { Supplier } from '../backend-v3/suppliers'

import { location } from './common'


type modalState = 'receive' | 'delete' | 'create' | null
type currentOrderId = number | null

const Orders = (location: location) => {

  const [currentTab, setCurrentTab] = useState('draft')
  const [currentOrderId, setCurrentOrderId] = useState(null as number | null)
  const [modal, setModal] = useState('receive' as modalState)
  const ordersInterface = useOrders()
  const { locations, isFetchingLocations } = useLocations()
  const { suppliers, isFetchingSuppliers } = useSuppliers()

  useEffect(() => {
    if (ordersInterface.orders && !ordersInterface.isUpdatingOrders) setModal(null)
  }, [ordersInterface.isUpdatingOrders])

  useEffect(() => {
    const parsed: {
      tab?: string
    } = queryString.parse(location.search)

    if (parsed.tab) setCurrentTab(parsed.tab.toUpperCase())
  }, [])

  function handleSetTab(tab: string) {
    navigate(`/orders?tab=${tab.toLowerCase()}`)
    setCurrentTab(tab)
  }

  function getFilteredOrders() {
    return currentTab === 'ALL' ? ordersInterface.orders : ordersInterface.orders.filter(({ status }) => status === currentTab)
  }

  function handleDeleteOrder(orderId: number) {
    setCurrentOrderId(orderId)
    setModal('delete')
  }


  if (!ordersInterface.orders || !locations || !suppliers || ordersInterface.isFetchingOrders || isFetchingLocations || isFetchingSuppliers)
    return <Loading />

  return (
    <>
      <OrdersHeader onCreateClick={suppliers.length ? () => setModal('create') : null} />
      <Tabs
        className="w-full"
        content={
          <OrdersTable
            currentTab={currentTab}
            deleteOrder={handleDeleteOrder}
            locations={locations}
            orders={ordersInterface.orders ? getFilteredOrders() : []}
            sendToXero={ordersInterface.sendToXero}
            processingXero={ordersInterface.processingXero}
            suppliers={suppliers}
          />
        }
        currentTab={currentTab}
        maxWidth="none"
        onTabClick={handleSetTab}
        tabs={[
          {
            id: CONSTANTS.STATUS.DRAFT,
            label: 'Draft',
          },
          {
            id: CONSTANTS.STATUS.SENT,
            label: 'Sent',
          },
          {
            id: CONSTANTS.STATUS.PARTIALLY_RECEIVED,
            label: 'Partially Received',
          },
          {
            id: CONSTANTS.STATUS.RECEIVED,
            label: 'Received',
          },
          {
            id: 'ALL',
            label: 'All',
          },
        ]}
      />
      {suppliers.length > 0 && locations.length > 0 && (
        <CreateOrderModal
          isOpen={modal === 'create'}
          loading={ordersInterface.isUpdatingOrders}
          locations={locations}
          onClose={setModal}
          onSubmit={ordersInterface.createOrder}
          suppliers={suppliers}
        />
      )}

      {currentOrderId ? (
        <DeleteOrderModal
          isOpen={modal === 'delete'}
          loading={ordersInterface.isUpdatingOrders}
          onClose={() => setModal(null)}
          onSubmit={() => ordersInterface.deleteOrder(currentOrderId)}
        />
      ) : null}

    </>
  )
}

export { Orders, modalState }
