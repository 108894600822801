import React from 'react'
import PropTypes from 'prop-types'

const MenuItem = ({ className, icon, onClick, value, ...props }) => (
  <li
    className={`py-4 px-5 text-lg flex align-center justify-end hover:bg-lighten-50 ${
      onClick ? 'cursor-pointer' : ''
    } ${className}`}
    onClick={onClick}
    {...props}
  >
    <span>{value}</span>
    {icon && <span className="pl-2 flex items-center">{icon}</span>}
  </li>
)

MenuItem.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

MenuItem.defaultProps = {
  className: '',
  icon: null,
  onClick: null,
}

export { MenuItem }
