import { useEffect, useState, useRef } from 'react'
import { Vendor } from '../backend-v3/suppliers'
import { useFeedback } from './useFeedbackTs'
import { getVendors } from '../backend-v3/suppliers'

const useVendors = () => {
  const isMounted = useRef(true)
  const [isFetchingVendors, setIsFetchingVendors] = useState(false)
  const [vendors, setVendors] = useState([] as Vendor[])
  const { setError } = useFeedback()

  useEffect(() => {
    getData()

    return () => {
      isMounted.current = false
    }
  }, [])

  async function getData() {
    setIsFetchingVendors(true)

    const res = await getVendors()

    if (!isMounted.current) return
    if (res.err) setError(res.val.message)
    if (res.ok) setVendors(res.val)

    setIsFetchingVendors(false)
  }

  return { isFetchingVendors, vendors }
}

export { useVendors }
