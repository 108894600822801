import React from 'react'
import PropTypes from 'prop-types'

import { Header } from '../../Header'

const SupplierVendorHeader = ({ id, name, parent }) => {
  return (
    <Header
      crumbs={[
        { to: parent.to, value: parent.value },
        {
          to: `${parent.to}/${id}`,
          value: name,
        },
      ]}
    />
  )
}

SupplierVendorHeader.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  parent: PropTypes.shape({
    to: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
}

SupplierVendorHeader.defaultProps = {
  id: null,
  name: null,
}

export { SupplierVendorHeader }
