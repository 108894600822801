import React from 'react'
import PropTypes from 'prop-types'
import { FaExclamationTriangle } from 'react-icons/fa'

import { Modal } from '../../Modal'

import { PROP_TYPES } from '../../../common/propTypes'

const DeleteSupplierModal = ({ handleCancel, handleDelete, isOpen, loading, supplier }) =>
  supplier ? (
    <Modal
      closeModal={handleCancel}
      disabled={loading}
      heading="Delete Supplier"
      isOpen={isOpen}
      loading={loading}
      onSubmit={() => handleDelete(supplier.id)}
      submitIcon={<FaExclamationTriangle />}
      submitValue="Delete"
    >
      <p className="mt-6">
        Are you sure you want to delete supplier <code className="text-orange">{supplier.name}</code>?
      </p>
    </Modal>
  ) : null

DeleteSupplierModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  supplier: PROP_TYPES.SUPPLIER,
}

DeleteSupplierModal.defaultProps = {
  isOpen: false,
  loading: false,
  supplier: null,
}

export { DeleteSupplierModal }
