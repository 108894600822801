import { FaPlus, FaTrash } from "react-icons/fa";

import { Modal } from "../../Modal";

import { InvoiceFastSearch, InvoiceWithProductsExpanded } from "../../../backend-v3/invoices";

type Props = {
  invoice: InvoiceWithProductsExpanded;
  isOpen?: boolean;
  loading?: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

const DeleteInvoiceModal = ({ invoice, isOpen = false, loading = false, onClose, onSubmit }: Props) => (
  <Modal
    closeModal={onClose}
    heading="Delete Invoice"
    loading={loading}
    isOpen={isOpen}
    onSubmit={onSubmit}
    submitIcon={<FaTrash />}
    submitValue="Delete"
  >
    <p className="mt-4">
      This will remove invoice{" "}
      <code className="text-orange">#{invoice.invoice_number ? invoice.invoice_number : invoice.id}</code> from the
      order.
    </p>
  </Modal>
);

export { DeleteInvoiceModal };
