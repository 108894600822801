import roundTo from "round-to";
import moment from "moment";

const dateString = date => moment(date).format("YYYY-MM-DD");
const dateTimeString = date => moment(date).format("ISO");
const formatDate = date => moment(date).format("L");
const roundToTwo = val => roundTo(val, 2).toFixed(2);
const sortArrayByObjectKey = (array, key, reverse = false) =>
  array.sort((prev, next) => {
    if (prev[key] < next[key]) return reverse ? 1 : -1;
    if (prev[key] > next[key]) return reverse ? -1 : 1;
    return 0;
  });
const sortArrayByObjectSubKey = (array, key, subkey, reverse = false) =>
  array.sort((prev, next) => {
    if (prev[key][subkey] < next[key][subkey]) return reverse ? 1 : -1;
    if (prev[key][subkey] > next[key][subkey]) return reverse ? -1 : 1;
    return 0;
  });

export { dateString, dateTimeString, formatDate, roundToTwo, sortArrayByObjectKey, sortArrayByObjectSubKey };
