import { useEffect, useState, useRef } from "react";
import queryString from "query-string";
import { navigate } from "@reach/router";

import { getIntegrations, removeIntegration as remove, addIntegration as add } from "../backend-v3/integrations";
import { useFeedback } from "./useFeedbackTs";

const useIntegrations = (location: any) => {
  const isMounted = useRef(true);
  const [isUpdatingIntegrations, setIsUpdatingIntegrations] = useState<boolean>();
  const [integrations, setIntegrations] = useState();
  const { setError, setToast } = useFeedback();

  useEffect(() => {
    const parsed = queryString.parse(location.search);

    if (parsed.success) handleSuccessCallback(parsed.success);
  }, []);

  useEffect(() => {
    getData();

    return () => {
      isMounted.current = false;
    };
  }, []);

  function handleSuccessCallback(success: any) {
    if (success === "true") setToast("Integration added!");
    if (success === "false") setToast("Integration failed...");

    navigate("/integrations");
  }

  async function getData() {
    setIsUpdatingIntegrations(true);
    const res = await getIntegrations();
    if (!isMounted.current) return;
    if (!res.success) setError(res as any);
    if (res.success) setIntegrations(res.data);
    setIsUpdatingIntegrations(undefined);
  }

  async function addIntegration(name: any) {
    setIsUpdatingIntegrations(true);

    const res = await add(name);

    if (!isMounted.current) return;
    if (!res.success) {
      setIsUpdatingIntegrations(undefined);
      setError(res as any);
    }

    if (res.success) {
      navigate(res.data.xero.url);
    }
  }

  async function removeIntegration(integrationId: any) {
    setIsUpdatingIntegrations(true);

    const res = await remove(integrationId);

    if (!isMounted.current) return;
    if (!res.success) setError(res as any);
    if (res.success) {
      getData();
      setToast("Integration removed!");
    }

    setIsUpdatingIntegrations(undefined);
  }

  return { addIntegration, removeIntegration, integrations, isUpdatingIntegrations };
};

export { useIntegrations };
