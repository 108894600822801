import { CONSTANTS } from "../common/constants";

function transformExpense(exp) {
  return {
    ...exp,
    id: exp.id.toString(),
    orderId: exp.orderId.toString(),
  };
}

function getUrl(pId, pvId, shop) {
  const productId = pId.substring(22);
  const productVariantId = pvId.substring(29);
  const url = `https://${shop}/admin/products/${productId}?variant=${productVariantId}`;
  return url;
}

function transformOrderProduct(op) {
  let supplierCode;
  if (op.supplierProductVariant && op.supplierProductVariant.code) {
    supplierCode = op.supplierProductVariant.code;
  } else supplierCode = "";
  const product = {
    id: op.productVariant.id,
    lastPricePaid: op.stockLevelPerLocation.lastPaidPerUnit || 0,
    sku: op.productVariant.sku,
    title: `${op.productVariant.product.title} - ${op.productVariant.title}`,
    url: getUrl(op.productVariant.shopifyProductId, op.productVariant.shopifyProductVariantId, op.shop.shop),
    vendorId: op.productVariant.product.vendorId,
    vendorName:
      op.productVariant.product.vendor && op.productVariant.product.vendor.name
        ? op.productVariant.product.vendor.name
        : "",
    quantityInStock: op.stockLevelPerLocation.inStock,
    quantityOnOrder: op.stockLevelPerLocation.ordersInTransfer,
    quantitySold: op.stockLevelPerLocation.soldQty,
    supplierCode,
  };

  return {
    draftLine: false,
    product,
    costPaid: op.paidPerUnit || 0,
    id: op.id,
    quantityOrdered: op.qtyOrdered || 0,
    quantityReceived: op.qtyReceived || 0,
  };
}

function transformProduct(product, variant, stock_level, shop) {
  let supplierCode = stock_level.supplierCode ? stock_level.supplierCode : "";
  return {
    id: variant.id,
    lastPricePaid: stock_level.lastPaidPerUnit || 0,
    sku: variant.sku,
    title: `${product.title} - ${variant.title}`,
    url: getUrl(variant.shopifyProductId, variant.shopifyProductVariantId, shop.shop),
    vendorId: product.vendorId,
    vendorName: product.vendor.name,
    quantityInStock: stock_level.inStock,
    quantityOnOrder: stock_level.ordersInTransfer,
    quantitySold: stock_level.soldQty,
    supplierCode,
  };
}

function transformDraftProduct(op) {
  const product = transformProduct(op.productVariant.product, op.productVariant, op, op.shop);

  return {
    draftLine: true,
    // yuck
    id: op.productVariant.id * 10,
    product,
    costPaid: 0,
    quantityOrdered: 0,
    quantityReceived: 0,
  };
}

function transformVendor(vendor) {
  let products = [];

  if (vendor.products) {
    products = vendor.products.map(product => {
      const variants = product.productVariants.map(variant => {
        return transformProduct(product, variant, product, vendor.shop);
      });
      return variants;
    });
  }

  const suppliers = vendor.suppliersVendors.map(x => ({
    id: x.supplier.id,
    contactFirstName: x.supplier.contactFirstName,
    contactLastName: x.supplier.contactLastName,
    email: x.supplier.email,
    name: x.supplier.name,
  }));
  return {
    id: vendor.id,
    name: vendor.name,
    suppliers,
    products: products.flat(),
  };
}

function transformOrder(order) {
  return {
    adjustments: order.adjustments,
    createdAt: order.orderCreatedAt ? order.orderCreatedAt : order.createdAt,
    id: order.orderId ? order.orderId.toString() : order.id.toString(),
    invoiceDate: order.invoiceDate,
    invoiceNumber: order.invoiceNumber,
    locationId: order.locationId,
    kind: CONSTANTS.KIND.PURCHASE,
    notes: order.notes,
    orderDate: order.orderDate,
    orderNumber: order.orderNumber,
    orderTotal: order.total || 0,
    paymentDue: order.paymentDue,
    status: order.status,
    supplierName: order.supplier.name,
    xeroInvoiceLink: order.xeroInvoiceId
      ? `https://go.xero.com/AccountsPayable/Edit.aspx?InvoiceID=${order.xeroInvoiceId}`
      : null,
    supplierId: order.supplierId,
    supplierInvoiceNumber: order.supplierInvoiceNumber ? order.supplierInvoiceNumber : undefined,
    updatedAt: order.versionCreatedAt,
    sentToXero: order.xeroInvoiceId ? true : false,
  };
}

function transformSupplier(supplier) {
  const vendors = supplier.suppliersVendors.map(x => ({
    id: x.vendor.id,
    name: x.vendor.name,
  }));
  return {
    id: supplier.id,
    contactFirstName: supplier.contactFirstName,
    contactLastName: supplier.contactLastName,
    email: supplier.email,
    name: supplier.name,
    xeroContactId: supplier.xeroContactId,
    xeroContact: supplier.xeroContact,
    vendors,
  };
}

function transformLocation(loc) {
  return {
    companyName: loc.name,
    id: loc.id,
    line1: loc.address1,
    line2: loc.address2,
    line3: "",
    city: loc.city,
    postcode: loc.zip,
    phoneNumber: loc.phone,
    createdAt: "",
    updatedAt: "",
  };
}

export {
  transformOrder,
  transformSupplier,
  transformLocation,
  transformExpense,
  transformOrderProduct,
  transformDraftProduct,
  transformVendor,
  transformProduct,
};
