import React from 'react'
import PropTypes from 'prop-types'
import { FaExclamationTriangle } from 'react-icons/fa'

import { Modal } from '../../Modal'

import { PROP_TYPES } from '../../../common/propTypes'

const SupplierRemoveVendorModal = ({ onSubmit, onClose, isOpen, loading, vendor }) =>
  vendor ? (
    <Modal
      closeModal={onClose}
      disabled={!vendor}
      heading="Add Vendor"
      isOpen={isOpen}
      loading={loading}
      onSubmit={() => onSubmit(vendor.id)}
      submitIcon={<FaExclamationTriangle />}
      submitValue="Remove"
    >
      <p className="mt-6">
        You are about to remove vendor <code className="text-orange">{vendor.name}</code> from this supplier. You sure?
      </p>
    </Modal>
  ) : null

SupplierRemoveVendorModal.propTypes = {
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  vendor: PROP_TYPES.VENDOR,
}

SupplierRemoveVendorModal.defaultProps = {
  isOpen: false,
  loading: false,
  vendor: null,
}

SupplierRemoveVendorModal.displayName = 'SupplierRemoveVendorModal'

export { SupplierRemoveVendorModal }
