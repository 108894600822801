import { Invoice } from "../../../backend-v3/invoices";

export const openInvoiceInXero = (invoice: Invoice) => {
  const url = invoice.xero_invoice_id
    ? `https://go.xero.com/AccountsPayable/Edit.aspx?InvoiceID=${invoice.xero_invoice_id}`
    : undefined;

  if (url) {
    window.open(url, "_blank", "noopener");
  }
};
