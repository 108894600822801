import React, { useCallback, FunctionComponent } from "react";
import PropTypes from "prop-types";
import { debounce } from "throttle-debounce";
import { CANCELLED } from "dns";

type OnChange<T, K> = T & {
  onChange: (x: K) => void;
};

type Props<T, K> = {
  Component: FunctionComponent<OnChange<T, K>>;
  delay?: number;
  requestHandler: (x: K) => void;
  setValue: (x: K) => void;
  props: T;
  cacheDeps?: [any];
};

const DebouncedInput = <T extends object, K extends unknown>({
  Component,
  delay = 850,
  requestHandler,
  setValue,
  cacheDeps,
  props,
}: Props<T, K>) => {
  const fn = debounce(delay, (x: K) => {
    requestHandler(x);
  });
  const debounced = useCallback(fn, cacheDeps ?? []);

  function handleChange(value: K) {
    setValue(value);
    debounced(value);
  }

  return <Component onChange={handleChange} {...props} />;
};

// onChange={handleChange} {...props} />

// const debounce = <P extends object>(
//   Component: React.ReactElement<P>
// ) : React.FC<P & Extra> => {
//   const ComponentWrapped = (props: P) => {
//     return <Component {...props} />
//   };
//   return ComponentWrapped;
// }

// DebouncedInput.propTypes = {
//   Component: PropTypes.elementType.isRequired,
//   delay: PropTypes.number,
//   requestHandler: PropTypes.func.isRequired,
//   setValue: PropTypes.func.isRequired,
// }

// DebouncedInput.defaultProps = {
//   delay: 850,
// }

export { DebouncedInput, debounce };
