import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FaEye, FaFileInvoiceDollar, FaPen, FaPlus, FaTrash } from "react-icons/fa";

import { AddProductModal } from "../components/AddProductModal";
import { Button } from "../components/ButtonTs";
import { DeleteProductModal } from "../components/Layouts/OrderTs/DeleteProductModal";
import { Loading } from "../components/Loading";
import { LoadingGlobal } from "../components/LoadingGlobal";
import { NotFound } from "./NotFound";
import { OrderDetails } from "../components/Layouts/OrderTs/OrderDetails";
import { OrderExpensesTable } from "../components/Layouts/OrderTs/OrderExpensesTable";
import { OrderHeader } from "../components/Layouts/OrderTs/OrderHeader";
import { OrderNotes } from "../components/Layouts/OrderTs/OrderNotes";
import { OrderReceiveModal } from "../components/Layouts/OrderTs/OrderReceiveModal";
import { OrderSendModal } from "../components/Layouts/OrderTs/OrderSendModal";
import { OrderStockPosition } from "../components/Layouts/OrderTs/OrderStockPosition";
import { OrderSyncModal } from "../components/Layouts/OrderTs/OrderSyncModal";
import { OrderTable } from "../components/Layouts/OrderTs/OrderTable";
import { OrderTotals } from "../components/Layouts/OrderTs/OrderTotals";
import { OrderUpdateStatusModal } from "../components/Layouts/OrderTs/OrderUpdateStatusModal";
import { Tabs } from "../components/Tabs";
import { DatePicker } from "../components/DatePicker";

import { CONSTANTS } from "../common/constants";
import { useLocations } from "../hooks/useLocationsTs";
import { useOrder as useOrderTs, OrderProductUpdate } from "../hooks/useOrderTs";
import { useOverlay, ModalState } from "../hooks/useOverlayTs";
import * as types from "../common/types";
import { OverlayState } from "../components/Layouts/OrderTs/types";
import { DebouncedInput } from "../components/DebouncedComponent";
import { Input } from "../components/InputTs";
import { DeleteInvoiceModal } from "../components/Layouts/OrderTs/DeleteInvoiceModal";
import { UpdateOrderProductInvoice } from "../backend-v3/invoices";
import moment from "moment";
import { dateString } from "../helpersTs";

import Picker from "react-date-picker/dist/entry.nostyle";
import { useInvoice } from "../hooks/useInvoice";
import { Header } from "../components/Header";
import { InvoiceTable } from "../components/Layouts/Invoice/InvoiceTable";
import { InvoiceTotals } from "../components/Layouts/Invoice/InvoiceTotals";
import { openInvoiceInXero } from "../components/Layouts/Invoice/common";
import { getInvoicesAction } from "../components/Layouts/OrderTs/common";

const Invoice = ({ invoiceId, parent }: { invoiceId: number; parent: types.Parent }) => {
  //   const [currentProductId, setCurrentProductId] = useState<number>();
  //   const [qtyReceivedTotal, setQtyReceivedTotal] = useState(0);
  //   const [currentTab, setCurrentTab] = useState("details");
  //   const [isEditing, setIsEditing] = useState(false);

  //   useEffect(() => {
  //     if (order && !isOrderModalLoading && !isDeletingSingleProduct && !isDeletingInvoice) {
  //       setIsEditing(false);
  //       setOverlay(undefined);
  //     }
  //   }, [isOrderModalLoading, isDeletingSingleProduct, isDeletingInvoice]);

  const { invoice, notFound, status, sendToXero, sendingToXero } = useInvoice(invoiceId);

  if (notFound) return <NotFound />;

  if (!invoice) return <Loading />;

  return (
    <div className="max-w-site mb-12">
      <Header
        actions={
          <>
            {
              <>
                {
                  <Button
                    className="ml-2"
                    iconBefore={<FaFileInvoiceDollar />}
                    loading={sendingToXero}
                    onClick={() => sendToXero()}
                    value={getInvoicesAction([invoice])}
                    variant="secondary"
                  />
                }

                {invoice.xero_invoice_id && (
                  <Button
                    className="ml-2"
                    iconBefore={<FaEye />}
                    onClick={() => {
                      if (invoice) {
                        openInvoiceInXero(invoice);
                      }
                    }}
                    value="View in Xero"
                    variant="secondary"
                  />
                )}
              </>
            }
          </>
        }
        crumbs={[
          { to: parent?.to ?? "", value: parent?.value ?? "" },
          {
            to: `${parent?.to ?? ""}/${invoice.id}`,
            value: (
              <div>
                {invoice.id} <span className="ml-4 text-black inline-block text-sm font-bold">Created at:</span>{" "}
                <span className="text-black inline-block text-sm">{invoice.created_at.format("L")}</span>
              </div>
            ),
          },
        ]}
      />

      <div className="flex justify-between items-start">
        {
          <div className="flex mt-4">
            <div className="flex">
              <div className="mr-2">
                <label className="font-bold text-sm" htmlFor="invoice-number">
                  Invoice No.
                </label>
                <div className="mt-1">{invoice.invoice_number}</div>
              </div>
            </div>

            <div className="flex">
              <div className="mr-2">
                <label className="font-bold text-sm" htmlFor="supplier">
                  Supplier
                </label>
                <div className="mt-1">{invoice.supplier.name}</div>
              </div>
            </div>

            <div className="flex">
              <div className="mr-2">
                <label className="font-bold text-sm" htmlFor="invoice-date">
                  Invoice Date
                </label>
                <div className="mt-1">{invoice.invoice_date ? dateString(invoice.invoice_date) : undefined}</div>
              </div>

              <div className="mr-2">
                <label className="font-bold text-sm" htmlFor="payment-date-due">
                  Payment Due
                </label>
                <div className="mt-1">{invoice.payment_due ? dateString(invoice.payment_due) : undefined}</div>
              </div>
            </div>
          </div>
        }
      </div>

      <InvoiceTable loading={status === "fetching"} invoice={invoice} />

      <div className="mt-10 flex justify-end ">
        <div className="ml-4 bg-blue w-full max-w-lg ">
          {/* todo: add mutations */}
          <OrderExpensesTable
            createExpense={alert}
            deleteExpense={alert}
            updateExpense={alert}
            expenses={invoice.expenses}
            loading={false}
            modalLoading={false}
            readOnly={true}
          />
          <InvoiceTotals loading={false} invoice={invoice} />
        </div>
      </div>
    </div>
  );
};

export { Invoice };
