import React from 'react'
import PropTypes from 'prop-types'
import { FaSyncAlt } from 'react-icons/fa'
// import { FaEnvelope, FaEye } from 'react-icons/fa'

// import { Input } from '../../Input'
import { Modal } from '../../Modal'
// import { Textarea } from '../../Textarea'
// import { PROP_TYPES } from '../../../common/propTypes'
// import { Button } from '../../Button'

type Props = {
  isOpen: boolean,
  loading: boolean,
  onClose: () => void,
  onSubmit: () => void,
}

const OrderSendModal = ({ isOpen, loading, onClose, onSubmit } : Props ) => {
  // const [cc, setCC] = useState('')
  // const [email, setEmail] = useState(supplier.email)
  // const [message, setMessage] = useState(`Hi ${supplier.contactFirstName}`)
  // const [subject, setSubject] = useState(`Purchase Order ${orderId}`)

  return (
    <Modal
      closeModal={onClose}
      heading="Change Purchase Order Status"
      loading={loading}
      isOpen={isOpen}
      onSubmit={() => onSubmit()}
      submitIcon={<FaSyncAlt />}
      submitValue="Confirm"
    >
      <div className="mt-4">
        <p>
          This will change your purchase order status to <code className="text-orange">Sent</code>. Make sure you
          download the CSV and send it to your supplier.
        </p>
      </div>
    </Modal>
    // <Modal
    //   closeModal={onClose}
    //   heading="Send Purchase Order"
    //   loading={loading}
    //   isOpen={isOpen}
    //   onSubmit={() =>
    //     onSubmit({
    //       cc,
    //       email,
    //       message,
    //       subject,
    //     })
    //   }
    //   submitIcon={<FaEnvelope />}
    //   submitValue="Send"
    // >
    //   <Input
    //     className="w-full mt-4"
    //     disabled={loading}
    //     id="send-modal-email"
    //     label="Email"
    //     onChange={setEmail}
    //     value={email}
    //   />
    //   <Input className="w-full mt-2" disabled={loading} id="send-modal-cc" label="CC" onChange={setCC} value={cc} />
    //   <Input
    //     className="w-full mt-2"
    //     disabled={loading}
    //     id="send-modal-subject"
    //     label="Subject"
    //     onChange={setSubject}
    //     value={subject}
    //   />
    //   <Textarea
    //     className="mt-2"
    //     disabled={loading}
    //     label="Message"
    //     onChange={e => setMessage(e.target.value)}
    //     value={message}
    //   />
    //   <div className="mt-4">
    //     <Button
    //       disabled={loading}
    //       iconBefore={<FaEye />}
    //       onClick={() => alert('TODO: Show preview')}
    //       size="small"
    //       value="Preview Purchase Order"
    //       variant="secondary"
    //     />
    //   </div>
    // </Modal>
  )
}
export { OrderSendModal }
