import React, { useState, useEffect } from "react";
import { Dropdown } from "../../DropdownTs";
import { Input } from "../../InputTs";
import { Button } from "../../ButtonTs";
import { Order } from "../../../backend-v3/orders";
import { Supplier, SupplierWithoutVendors } from "../../../backend-v3/suppliers";
import { boolean } from "yargs";
import { Location } from "../../../backend-v3/locations";

type Props = {
  orders: Order[];
  setFilteredOrders: React.Dispatch<React.SetStateAction<Order[]>>;
  locations: Location[];
  suppliers: Supplier[];
};
// { label: 'Purchase Order No.', value: 'orderNumber' },
type FilterKey = "location" | "supplier" | "orderNumber" | "none";

const OrdersFilter = ({ locations, suppliers, orders, setFilteredOrders }: Props) => {
  const [filterKey, setFilterKey] = useState("none" as FilterKey);
  const [query, setQuery] = useState("");

  useEffect(() => {
    setFilteredOrders(getFilteredOrders(query, orders));
  }, [orders]);

  function getOptions(filterKey: "location" | "supplier"): { name: string; value: string }[] {
    if (filterKey === "location") return locations.map(l => ({ name: l.name, value: l.id.toString() }));
    else return suppliers.map(s => ({ name: s.name, value: s.id.toString() }));
  }

  function getFilteredOrders(newQueryId: string | undefined, ordersToFilter: Order[]) {
    // Clear dropdown filter with 'undefined' value
    if (!newQueryId) return orders;
    // Clear input filter with 'undefined' filterKey
    if (!filterKey) return orders;
    // const key = filterKeys.find(({ value }) => value === filterKey).value

    return ordersToFilter.filter(order => {
      switch (filterKey) {
        case "location":
          const locsFiltered = locations.filter(l => l.id.toString() === newQueryId).map(l => l.id);
          return locsFiltered.includes(order.location_id);
        case "supplier":
          const suppliersFiltered = suppliers.filter(s => s.id.toString() === newQueryId).map(s => s.id);
          return suppliersFiltered.includes(order.supplier_short.id);
        case "orderNumber":
          return order.order_number.toString().includes(newQueryId);
      }
    });
  }

  function getPlaceholderText() {
    return filterKey !== "none" ? `Enter a ${filterKey.toLowerCase()}` : "First select a filter";
  }

  function reset() {
    setFilterKey("none");
    setQuery("");
    setFilteredOrders(orders);
  }

  function onFilterKeyChange(newKey: FilterKey) {
    if (!newKey) reset();

    setFilterKey(newKey);
  }

  function onQueryChange(newQuery: string) {
    setFilteredOrders(getFilteredOrders(newQuery, orders));
    setQuery(newQuery);
  }

  return (
    <div className="flex items-end">
      <Dropdown
        className="mr-2 w-48"
        id="filter-key"
        label="Filter By"
        onChange={(x: string) => {
          switch (x) {
            case "location":
              return onFilterKeyChange("location");
            case "supplier":
              return onFilterKeyChange("supplier");
            case "none":
              return onFilterKeyChange("none");
            case "orderNumber":
              return onFilterKeyChange("orderNumber");
          }
        }}
        options={[
          { name: "None", value: "none" },
          { name: "Location", value: "location" },
          { name: "Purchase Order No.", value: "orderNumber" },
          { name: "Supplier", value: "supplier" },
        ]}
        value={filterKey}
      />
      {filterKey === "location" || filterKey === "supplier" ? (
        <Dropdown
          className="w-64 mr-2"
          id="filter-query"
          label="Query"
          onChange={onQueryChange}
          options={[{ name: "", value: "" }, ...getOptions(filterKey)]}
          value={query}
        />
      ) : (
        <Input
          className="w-64 mr-2"
          disabled={filterKey === "none"}
          id="filter-query"
          label="Query"
          onChange={onQueryChange}
          placeholder={getPlaceholderText()}
          value={query}
        />
      )}
      <div>
        <Button onClick={reset} value="Reset Filter" variant="secondary" />
      </div>
    </div>
  );
};

export { OrdersFilter };
