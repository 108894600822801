import { useEffect, useState, useRef } from "react";
import {
  createSupplier as create,
  deleteSupplier as destroy,
  getSuppliers,
  Supplier,
  SupplierWithoutVendors,
} from "../backend-v3/suppliers";
import { sortArrayByObjectKey } from "../helpersTs";
import { useFeedback } from "./useFeedbackTs";

const useSuppliers = () => {
  const isMounted = useRef(true);
  const [isFetchingSuppliers, setIsFetchingSuppliers] = useState(false);
  const [isUpdatingSuppliers, setIsUpdatingSuppliers] = useState(false);
  const [suppliers, setSuppliers] = useState([] as Supplier[]);
  const { setError, setToast } = useFeedback();

  useEffect(() => {
    getData();

    return () => {
      isMounted.current = false;
    };
  }, []);

  async function getData() {
    setIsFetchingSuppliers(true);

    const res = await getSuppliers();

    if (!isMounted.current) return;
    if (res.err) setError(res.val.message);
    else {
      setSuppliers(sortArrayByObjectKey(res.val, "name"));
    }
    setIsFetchingSuppliers(false);
  }

  async function createSupplier(data: any) {
    setIsUpdatingSuppliers(true);

    const res = await create(data);

    if (!isMounted.current) return;
    if (!res.success) setError(res as any);
    if (res.success) {
      setIsUpdatingSuppliers(false);
      return await getData();
    }
  }

  async function deleteSupplier(supplierId: any) {
    setIsUpdatingSuppliers(true);

    const res = await destroy(supplierId);

    if (!isMounted.current) return;
    if (!res.success) setError(res as any);
    if (res.success) {
      setIsUpdatingSuppliers(false);
      setToast("Supplier deleted!");
      return await getData();
    }

    setIsUpdatingSuppliers(false);
  }

  return { createSupplier, deleteSupplier, isFetchingSuppliers, isUpdatingSuppliers, suppliers };
};

export { useSuppliers };
