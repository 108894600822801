import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import { navigate } from '@reach/router'

import { CreateStocktakeModal } from '../components/Layouts/Stocktakes/CreateStocktakeModal'
import { DeleteStocktakeModal } from '../components/Layouts/Stocktakes/DeleteStocktakeModal'
import { Loading } from '../components/Loading'
import { StocktakesHeader } from '../components/Layouts/Stocktakes/StocktakesHeader'
import { StocktakesTable } from '../components/Layouts/Stocktakes/StocktakesTable'
import { Tabs } from '../components/Tabs'

import { useStocktakes } from '../hooks/useStocktakesTs'
import { useLocations } from '../hooks/useLocationsTs'
import { useVendors } from '../hooks/useVendorsTs'
import { StocktakeStatus } from '../backend-v3/stocktakes'
import { location } from './common'

type modalState = 'delete' | 'create' | null

const Stocktakes = (location: location) => {
  const [currentTab, setCurrentTab] = useState<StocktakeStatus | 'all'>('draft')
  const [currentStocktakeId, setCurrentStocktakeId] = useState(null as number | null)
  const [modal, setModal] = useState(null as modalState)

  const { createStocktake, deleteStocktake, isFetchingStocktakes, isUpdatingStocktakes, stocktakes } = useStocktakes()

  const { locations, isFetchingLocations } = useLocations()
  const { vendors, isFetchingVendors } = useVendors()

  useEffect(() => {
    if (stocktakes && !isUpdatingStocktakes) setModal(null)
  }, [isUpdatingStocktakes])


  useEffect(() => {
    const parsed: {
      tab?: string
    } = queryString.parse(location.search)

    if (parsed.tab) setCurrentTab(parsed.tab as StocktakeStatus)
  }, [])

  function handleSetTab(tab: StocktakeStatus) {
    navigate(`/stocktakes?tab=${tab.toLowerCase()}`)
    setCurrentTab(tab)
  }

  function handleDeleteStocktake(stocktakeId: number) {
    setCurrentStocktakeId(stocktakeId)
    setModal('delete')
  }

  if (!stocktakes || !locations || !vendors || isFetchingStocktakes || isFetchingLocations || isFetchingVendors)
    return <Loading />

  return (
    <>
      <StocktakesHeader onCreateClick={locations.length > 1 ? () => setModal('create') : null} />
      <Tabs
        className="w-full"
        content={
          <StocktakesTable
            currentTab={currentTab}
            deleteStocktake={handleDeleteStocktake}
            locations={locations}
            stocktakes={stocktakes ? stocktakes.filter(({ status }) => status === currentTab) : []}
            vendors={vendors}
          />
        }
        currentTab={currentTab}
        maxWidth="none"
        onTabClick={handleSetTab}
        tabs={[
          {
            id: 'draft',
            label: 'Draft',
          },
          {
            id: 'in_progress',
            label: 'In Progress',
          },
          {
            id: 'counted',
            label: 'Counted',
          },
        ]}
      />
      {locations.length > 1 && (
        <CreateStocktakeModal
          locations={locations}
          isOpen={modal === 'create'}
          loading={isUpdatingStocktakes}
          onClose={() => setModal(null)}
          onSubmit={createStocktake}
          vendors={vendors}
        />
      )}
      <DeleteStocktakeModal
        isOpen={modal === 'delete'}
        loading={isUpdatingStocktakes}
        onClose={() => setModal(null)}
        onSubmit={() => { if (currentStocktakeId) deleteStocktake(currentStocktakeId) }
        }
      />
    </>
  )
}

export { Stocktakes }
