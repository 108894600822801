import React from "react";
import { Button } from "./ButtonTs";

import { PROP_TYPES } from "../common/propTypes";

const TableRowActions = ({ actions }) => (
  <td className="p-2">
    <div className="flex w-full items-center justify-end">
      {actions.map(({ id, variant = null, ...action }) => (
        <Button key={id} className="ml-2" size="small" variant={variant || "ghost"} {...action} />
      ))}
    </div>
  </td>
);

TableRowActions.propTypes = {
  actions: PROP_TYPES.TABLE_ROW.actions.isRequired,
};

export { TableRowActions };
