// todo: get rid of error unwraps

import { navigate } from '@reach/router'
import { useEffect, useState, useRef } from 'react'
import * as api from '../backend-v3/stocktakes'
import { useFeedback } from './useFeedbackTs'

const useStocktakes = () => {
  const isMounted = useRef(true)
  const [isFetchingStocktakes, setIsFetchingStocktakes] = useState(false)
  const [isUpdatingStocktakes, setIsUpdatingStocktakes] = useState(false)

  const [stocktakes, setStocktakes] = useState([] as api.Stocktake[])
  const { setError, setToast } = useFeedback()

  useEffect(() => {
    getData()
    return () => {
      isMounted.current = false
    }
  }, [])

  async function getData() {
    setIsFetchingStocktakes(true)
    const data = (await api.getStocktakes())
    if (data.err) {
      setError(data.val.message)
      return
    }
    setStocktakes(data.val)
    setIsFetchingStocktakes(false)
  }

  async function createStocktake(newStocktake: api.StocktakeCreate) {
    setIsUpdatingStocktakes(true)
    const data = await api.createStocktake(newStocktake);
    if (!isMounted.current) return
    if (data.err) {
      setError(data.val.message)
    } else {
      setToast('Stocktake created!')
      navigate(`stocktakes/${data.val}`)
    }
    setIsUpdatingStocktakes(false)
  }

  async function deleteStocktake(id: number) {
    setIsUpdatingStocktakes(true)
    const data = await api.deleteStocktake(id);
    if (!isMounted.current) return
    if (data.err) {
      setError(data.val.message)
    } else {
      setStocktakes(prev => prev.filter(stocktake => stocktake.id !== id))
      setToast('Deleted!')
    }
    setIsUpdatingStocktakes(false)
  }


  return {
    deleteStocktake,
    createStocktake,
    isFetchingStocktakes,
    isUpdatingStocktakes,
    stocktakes
  }
}

export { useStocktakes }