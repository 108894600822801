import React from "react";

import { HorizontalTable } from "../../HorizontalTable";
import { HorizontalTableCell } from "../../HorizontalTableCell";
import { TableCellInput } from "../../TableCellInputTs";
import { roundToTwo } from "../../../helpersTs";
import { OrderExtended, OrderProduct, OrderStatus } from "../../../backend-v3/orders";
import { OrderUpdate } from "../../../backend-v3/orders";
import { CONSTANTS } from "../../../common/constants";
import { InvoiceExpense, InvoiceWithProductsExpanded } from "../../../backend-v3/invoices";
import { OrderProductsInvoiceWithVariantsFragment } from "../../../generated/graphql";

type Props = {
  loading?: boolean;
  invoice: InvoiceWithProductsExpanded;
};

const InvoiceTotals = ({ loading = false, invoice }: Props) => {
  return (
    <div className="mt-8">
      <HorizontalTable
        head={
          <>
            <HorizontalTableCell value={`Sub Total`} />
            <HorizontalTableCell value="Adjustments" />
            <HorizontalTableCell value="Fixed Expenses" />
            <HorizontalTableCell value="GST" />
            <HorizontalTableCell bold large value={`Total Cost`} />
          </>
        }
        body={
          <>
            <HorizontalTableCell value={roundToTwo(invoice.total_received)} />
            <HorizontalTableCell value={roundToTwo(invoice.adjustments)} />
            <HorizontalTableCell value={roundToTwo(invoice.total_expenses)} />
            <HorizontalTableCell value={roundToTwo(invoice.total_with_gst - invoice.total)} />
            <HorizontalTableCell large value={`$${roundToTwo(invoice.total_with_gst)}`} />
          </>
        }
      />
    </div>
  );
};

export { InvoiceTotals };
