import React from 'react'

import { HorizontalTable } from '../../HorizontalTable'
import { HorizontalTableCell } from '../../HorizontalTableCell'

import { roundToTwo } from '../../../helpersTs'
import { StocktakeWithProducts } from '../../../backend-v3/stocktakes'

const StocktakeTotals = ({ stocktake }: {
  stocktake: StocktakeWithProducts
}) => {
  const value = stocktake.products.reduce((acc, { cost, qty_counted, qty_expected }) => {
    const hasQuantityCounted = qty_counted === 0 || qty_counted

    return hasQuantityCounted && cost ? ((qty_counted ? qty_counted : 0) - qty_expected) * cost + acc : acc
  }, 0)


  const isDraft = stocktake.stocktake.status === 'draft'

  return (
    <div className="mt-8">
      <HorizontalTable
        head={
          <HorizontalTableCell
            bold
            large
            value={`Total
            ${isDraft ? 'Estimated' : ''} Value`}
          />
        }
        body={<HorizontalTableCell large value={`$${roundToTwo(value)}`} />}
      />
    </div>
  )
}

export { StocktakeTotals }
