import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { AddIntegrationModal } from "../components/Layouts/Integrations/AddIntegrationModal";
import { IntegrationsTable } from "../components/Layouts/Integrations/IntegrationsTable";
import { Loading } from "../components/Loading";
import { RemoveIntegrationModal } from "../components/Layouts/Integrations/RemoveIntegrationModal";
import { useIntegrations } from "../hooks/useIntegrations";

const Integrations = ({ location }: any) => {
  const [modal, setModal] = useState<"add" | "remove">();
  const [currentIntegration, setCurrentIntegration] = useState();
  const { addIntegration, integrations, isUpdatingIntegrations, removeIntegration } = useIntegrations(location);

  useEffect(() => {
    if (integrations && !isUpdatingIntegrations) setModal(undefined);
  }, [isUpdatingIntegrations]);

  function onRemoveCancel() {
    setCurrentIntegration(undefined);
    setModal(undefined);
  }

  function onAddClick(integrationId: any) {
    setCurrentIntegration((integrations as any).find(({ name }: any) => name === integrationId));
    setModal("add");
  }

  function onRemoveClick(integrationId: any) {
    setCurrentIntegration((integrations as any).find(({ name }: any) => name === integrationId));
    setModal("remove");
  }

  if (!integrations) return <Loading />;

  return (
    <div className="max-w-site mt-2 mb-6">
      {(integrations as any).some(({ active }: any) => active) && (
        <>
          <p className="text-orange mb-4">Existing Integrations</p>
          <IntegrationsTable
            onRemoveClick={onRemoveClick}
            integrations={(integrations as any).filter(({ active }: any) => active)}
          />
        </>
      )}
      {(integrations as any).some(({ active }: any) => !active) && (
        <>
          <p className="text-orange mt-8 mb-4">Available Integrations</p>
          <IntegrationsTable
            onAddClick={onAddClick}
            integrations={(integrations as any).filter(({ active }: any) => !active)}
          />
        </>
      )}
      <AddIntegrationModal
        integration={currentIntegration}
        isOpen={modal === "add"}
        loading={isUpdatingIntegrations}
        onClose={setModal}
        onSubmit={addIntegration}
      />
      {(integrations as any).length > 0 && (
        <RemoveIntegrationModal
          integration={currentIntegration}
          isOpen={modal === "remove"}
          loading={isUpdatingIntegrations}
          onClose={onRemoveCancel}
          onSubmit={removeIntegration}
        />
      )}
    </div>
  );
};

Integrations.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

Integrations.defaultProps = {
  location: { search: "" },
};

export { Integrations };
