import React from "react";
import PropTypes from "prop-types";
import { FaTrash, FaPlus } from "react-icons/fa";

import { Table } from "../../TableTs";
import { TableCell } from "../../TableCellTs";
import { TableHeadCell } from "../../TableHeadCell";
import { TableHeadRow } from "../../TableHeadRow";
import { TableRow } from "../../TableRowTs";
import { TableRowActions } from "../../TableRowActions";

import { PROP_TYPES } from "../../../common/propTypes";

const IntegrationsTable = ({ onAddClick, onRemoveClick, integrations }: any) => {
  return (
    <Table
      className="mt-4 max-w-3xl"
      renderHead={() => (
        <TableHeadRow>
          <TableHeadCell value="Name" width="3/12" />
          <TableHeadCell alignment="right" width="32" value="Actions" />
        </TableHeadRow>
      )}
      renderBody={() => (
        <>
          {integrations.map(({ name, active }: any) => {
            const actions = {
              add: {
                id: "add",
                iconBefore: <FaPlus />,
                onClick: () => onAddClick(name),
                variant: "outline",
              },
              remove: {
                id: "remove",
                iconBefore: <FaTrash />,
                onClick: () => onRemoveClick(name),
              },
            };
            return (
              <TableRow key={name}>
                <TableCell value={{ type: "string", initial: name }} />
                <TableRowActions actions={!active ? [actions.add] : [actions.remove]} />
              </TableRow>
            );
          })}
        </>
      )}
    />
  );
};

IntegrationsTable.propTypes = {
  onAddClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  integrations: PropTypes.arrayOf(PROP_TYPES.INTEGRATION),
};

IntegrationsTable.defaultProps = {
  integrations: [],
  onAddClick: null,
  onRemoveClick: null,
};

export { IntegrationsTable };
