import React from "react";
import PropTypes from "prop-types";
import { FaPrint } from "react-icons/fa";

import { Button } from "../../ButtonTs";
import { Header } from "../../Header";
import { Status } from "../../Status";

import { formatDate } from "../../../helpersTs";
import { StocktakeWithProducts } from "../../../backend-v3/stocktakes";
import { Parent } from "../../../common/types";
import { OverlayState } from "../../../pages/Stocktake";

type Props = {
  stocktake: StocktakeWithProducts;
  parent: Parent;
  onPrintClick: () => void;
  setModal: (m: OverlayState) => void;
};

const StocktakeHeader = ({ onPrintClick, parent, stocktake, setModal }: Props) => {
  const isDraft = stocktake.stocktake.status === "draft";
  const isInProgress = stocktake.stocktake.status === "in_progress";

  return (
    <Header
      actions={
        <>
          <Button className="ml-2" iconBefore={<FaPrint />} onClick={onPrintClick} variant="secondary" value="Print" />
          {isDraft && (
            <Button
              className="ml-2"
              onClick={(e: any) => {
                e.stopPropagation();
                setModal("begin");
              }}
              value="Begin counting"
            />
          )}
          {isInProgress && (
            <Button
              className="ml-2"
              onClick={(e: any) => {
                e.stopPropagation();
                setModal("complete");
              }}
              value="Mark as counted"
            />
          )}
        </>
      }
      crumbs={[
        parent && { to: parent.to, value: parent.value },
        {
          to: `${parent?.to}/${stocktake.stocktake.id}`,
          value: (
            <div>
              {stocktake.stocktake.stocktake_number}{" "}
              <Status className="mx-2 text-sm" status={stocktake.stocktake.status} />{" "}
              <span className="text-black inline-block text-sm font-bold">Created at:</span>{" "}
              <span className="text-black inline-block text-sm">{formatDate(stocktake.stocktake.created_at)}</span>
            </div>
          ),
        },
      ]}
    />
  );
};

export { StocktakeHeader };
