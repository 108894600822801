import React from "react";
import PropTypes from "prop-types";
import { FaSyncAlt } from "react-icons/fa";

import { Modal } from "../../Modal";

import { CONSTANTS } from "../../../common/constants";
import { OrderStatus, OrderStatusUpdate } from "../../../backend-v3/orders";

type Props = {
  currentStatus: OrderStatus;
  isOpen: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: (x: OrderStatusUpdate) => void;
  qtyReceivedTotal: number;
};

const OrderUpdateStatusModal = ({ currentStatus, isOpen, loading, onClose, onSubmit, qtyReceivedTotal }: Props) => {
  function getNewStatus(): OrderStatusUpdate {
    switch (currentStatus) {
      case OrderStatus.Draft:
        return OrderStatusUpdate.Sent;
      case OrderStatus.Sent:
        return OrderStatusUpdate.Draft;
      case OrderStatus.Received:
        return OrderStatusUpdate.Sent;
      case OrderStatus.PartiallyReceived:
        return OrderStatusUpdate.Draft;
      default:
        return currentStatus;
    }
  }

  return (
    <Modal
      closeModal={onClose}
      heading="Change Purchase Order Status"
      loading={loading}
      isOpen={isOpen}
      onSubmit={() => onSubmit(getNewStatus())}
      submitIcon={<FaSyncAlt />}
      submitValue="Confirm"
    >
      <div className="mt-4">
        {currentStatus === "draft" && (
          <p>
            This will change your purchase order status to <code className="text-orange">Sent</code>. Make sure you
            download the CSV and send it to your supplier.
          </p>
        )}
        {currentStatus === "sent" && (
          <p>
            This will revert your purchase order status to <code className="text-orange">Draft</code>.
          </p>
        )}
        {currentStatus === "received" && qtyReceivedTotal > 0 && (
          <p>
            This will revert your purchase order status to <code className="text-orange">Partially Received</code>. Are
            you sure?
          </p>
        )}

        {currentStatus === "received" && qtyReceivedTotal === 0 && (
          <p>
            This will revert your purchase order status to <code className="text-orange">Sent</code>. Are you sure?
          </p>
        )}

        {currentStatus === "partially_received" && (
          <p>
            This will revert your purchase order status to <code className="text-orange">Draft</code>. Are you sure?
          </p>
        )}
      </div>
    </Modal>
  );
};

export { OrderUpdateStatusModal };
