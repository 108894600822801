import React, { useState, useEffect } from "react";
import { Dropdown } from "../../DropdownTs";
import { Input } from "../../InputTs";
import { Button } from "../../ButtonTs";
import { Order } from "../../../backend-v3/orders";
import { Supplier } from "../../../backend-v3/suppliers";
import { boolean } from "yargs";
import { Location } from "../../../backend-v3/locations";
import { Invoice } from "../../../backend-v3/invoices";

type Props = {
  invoices: Invoice[];
  setFilteredInvoices: React.Dispatch<React.SetStateAction<Invoice[]>>;
  locationQuery?: string;
  locationFilterKey?: FilterKey;
};
export type FilterKey = "invoice number" | "invoice id" | "none";

const InvoicesFilter = ({ invoices, setFilteredInvoices, locationFilterKey, locationQuery }: Props) => {
  const [filterKey, setFilterKey] = useState<FilterKey>(locationFilterKey ?? "invoice number");
  const [query, setQuery] = useState(locationQuery ?? "");

  useEffect(() => {
    setFilteredInvoices(getFilteredInvoices(query, invoices));
  }, [invoices]);

  function getFilteredInvoices(newQueryId: string | undefined, invoicestoFilter: Invoice[]) {
    // Clear dropdown filter with 'undefined' value
    if (!newQueryId) return invoices;
    // Clear input filter with 'undefined' filterKey
    if (!filterKey) return invoices;
    // const key = filterKeys.find(({ value }) => value === filterKey).value
    const queries = newQueryId.split(" OR ");

    return invoicestoFilter.filter(invoice => {
      switch (filterKey) {
        case "invoice id": {
          return (
            queries
              .map(x => {
                return invoice.id === Number(x);
              })
              .find(x => x === true) !== undefined
          );
        }
        case "invoice number":
          return (
            queries
              .map(x => {
                return invoice.invoice_number?.includes(x);
              })
              .find(x => x === true) !== undefined
          );
      }
    });
  }

  function getPlaceholderText() {
    return filterKey !== "none" ? `Enter ${filterKey.toLowerCase()}` : "First select a filter";
  }

  function reset() {
    setFilterKey("invoice number");
    setQuery("");
    setFilteredInvoices(invoices);
  }

  function onFilterKeyChange(newKey: FilterKey) {
    if (!newKey) reset();

    setFilterKey(newKey);
  }

  function onQueryChange(newQuery: string) {
    setFilteredInvoices(getFilteredInvoices(newQuery, invoices));
    setQuery(newQuery);
  }

  return (
    <div className="flex items-end">
      <Dropdown
        className="mr-2 w-48"
        id="filter-key"
        label="Filter By"
        onChange={(x: string) => {
          switch (x) {
            case "invoice id":
              return onFilterKeyChange("invoice id");
            case "invoice number":
              return onFilterKeyChange("invoice number");
          }
        }}
        options={[
          { name: "Invoice No.", value: "invoice number" },
          { name: "Invoice Id", value: "invoice id" },
        ]}
        value={filterKey}
      />
      <Input
        className="w-64 mr-2"
        disabled={filterKey === "none"}
        id="filter-query"
        label="Query"
        onChange={onQueryChange}
        placeholder={getPlaceholderText()}
        value={query}
      />
      <div>
        <Button onClick={reset} value="Reset Filter" variant="secondary" />
      </div>
    </div>
  );
};

export { InvoicesFilter };
