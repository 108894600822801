import React from 'react'
import PropTypes from 'prop-types'
import { FaTrash } from 'react-icons/fa'

import { Modal } from '../../Modal'


type Props = {
  isOpen?: boolean,
  loading?: boolean,
  onClose: () => void,
  onSubmit: () => void
}

const DeleteTransferModal = ({ loading = false, isOpen = false, onClose, onSubmit }: Props) => (
  <Modal
    closeModal={onClose}
    loading={loading}
    heading="Delete Transfer"
    isOpen={isOpen}
    onSubmit={onSubmit}
    submitIcon={<FaTrash />}
    submitValue="Delete"
  >
    <div className="mt-4">
      <p>This will delete the transfer. Are you sure you want to do this?</p>
    </div>
  </Modal>
)


export { DeleteTransferModal }
