import { styled } from "@stitches/react";
import { Input } from "../../InputTs";
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";
import { XeroContact } from "../../../backend-v3/suppliers";
import { useCallback } from "react";
import { debounce } from "throttle-debounce";

const SCROLLBAR_SIZE = 10;

const StyledScrollArea = styled(ScrollAreaPrimitive.Root, {
  height: 250,
  overflow: "hidden",
  length: 0,
});

const StyledViewport = styled(ScrollAreaPrimitive.Viewport, {
  width: "100%",
  height: "100%",
  borderRadius: "inherit",
  length: 0,
});

const StyledScrollbar = styled(ScrollAreaPrimitive.Scrollbar, {
  display: "flex",
  // ensures no selection
  userSelect: "none",
  // disable browser handling of all panning and zooming gestures on touch devices
  touchAction: "none",
  padding: 2,
  // background: blackA.blackA6,
  transition: "background 160ms ease-out",
  // "&:hover": { background: blackA.blackA8 },
  '&[data-orientation="vertical"]': { width: SCROLLBAR_SIZE },
  '&[data-orientation="horizontal"]': {
    flexDirection: "column",
    height: SCROLLBAR_SIZE,
  },
  length: 0,
});

const StyledThumb = styled(ScrollAreaPrimitive.Thumb, {
  flex: 1,
  background: "#000",
  borderRadius: SCROLLBAR_SIZE,
  // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    minWidth: 44,
    minHeight: 44,
  },
  length: 0,
});

const StyledCorner = styled(ScrollAreaPrimitive.Corner, {
  length: 0,
});

// Exports
export const ScrollArea = StyledScrollArea;
export const ScrollAreaViewport = StyledViewport;
export const ScrollAreaScrollbar = StyledScrollbar;
export const ScrollAreaThumb = StyledThumb;
export const ScrollAreaCorner = StyledCorner;

const Box = styled("div", {
  length: 0,
});

type Props = {
  foundContacts: XeroContact[];
  findContact: (q: string) => void;
  query: string;
  isSearching: boolean;
  setContact: (contact: XeroContact) => void;
  contact: XeroContact | undefined;
  startedSearch: boolean;
  setSearchQuery: (q: string) => void;
  setStartedSearch: (s: boolean) => void;
};

export const FindXeroContact = ({
  setSearchQuery,
  foundContacts,
  findContact,
  query,
  setContact,
  contact,
  startedSearch,
  setStartedSearch,
}: Props) => {
  const handleGetResults = useCallback(
    debounce(850, (q: string) => {
      const results = findContact(q);
    }),
    []
  );

  return (
    <>
      <Input
        className="w-64"
        disabled={false}
        id="supplier-edit-xeroContactSearch"
        label="Xero Contact"
        onChange={q => {
          setSearchQuery(q);
          setStartedSearch(true);
          handleGetResults(q);
        }}
        value={startedSearch ? query : contact?.name ?? contact?.id}
        placeholder={"Search by contact name"}
        autoComplete={"off"}
        type="text"
      />
      {foundContacts.length > 0 && (
        <div className="-mt-1 border-black border absolute bg-white w-64 z-10">
          <ul>
            <ScrollArea className="">
              <ScrollAreaViewport css={{ backgroundColor: "" }}>
                <Box style={{ padding: "" }}>
                  {foundContacts.map(contact => {
                    return (
                      <li onClick={x => setContact(contact)} className="cursor-pointer py-1 px-3 hover:bg-gray-100">
                        {contact.name ? contact.name : contact.id}
                      </li>
                    );
                  })}
                </Box>
              </ScrollAreaViewport>
              <ScrollAreaScrollbar orientation="vertical">
                <ScrollAreaThumb />
              </ScrollAreaScrollbar>
              <ScrollAreaScrollbar orientation="horizontal">
                <ScrollAreaThumb />
              </ScrollAreaScrollbar>
              <ScrollAreaCorner />
            </ScrollArea>
          </ul>
        </div>
      )}
    </>
  );
};
