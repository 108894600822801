import React from 'react'
import PropTypes from 'prop-types'

type Props = {
  className?: string,
  [x: string]: any
}

const TableHeadRow = ({ className, ...props }: Props) => (
  <tr className={`text-sm border-b sticky top-0 ${className}`} {...props} />
)
export { TableHeadRow }
