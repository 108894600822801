import React from 'react'
import PropTypes from 'prop-types'
import { Tab } from './Tab'

const Tabs = ({ className, content, currentTab, onTabClick, tabs, tabAlignment, tabsFillSpace, maxWidth }) => (
  <div className={className}>
    <ul className={`flex justify-${tabAlignment} text-center text-sm max-w-${maxWidth}`}>
      {tabs.map(({ className: classes, id, ...props }) => (
        <Tab
          key={id}
          className={typeof classes !== 'undefined' ? classes : ''}
          fullWidth={tabsFillSpace}
          isCurrent={currentTab === id}
          onClick={() => onTabClick(id)}
          {...props}
        />
      ))}
    </ul>
    <div className="border-t border-gray-100 rounded-b px-1 py-4">
      <div className={`max-w-${maxWidth}`}>{content}</div>
    </div>
  </div>
)

Tabs.propTypes = {
  className: PropTypes.string,
  content: PropTypes.PropTypes.node.isRequired,
  currentTab: PropTypes.string.isRequired,
  onTabClick: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      id: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ).isRequired,
  maxWidth: PropTypes.string,
  tabAlignment: PropTypes.oneOf(['start', 'end']),
  tabsFillSpace: PropTypes.bool,
}

Tabs.defaultProps = {
  className: '',
  maxWidth: '6xl',
  tabAlignment: 'start',
  tabsFillSpace: false,
}

export { Tabs }
