import React, { useState } from "react";
import PropTypes from "prop-types";
import { navigate } from "@reach/router";

import { Button } from "./ButtonTs";
import { Input } from "./InputTs";
import { Logo } from "./SVG/Logo";

// import { loginUser, createUser } from '../backend-v2/authenticate'
import { loginUser, createUser, resetPwd, resetPwdRequest } from "../backend-v3/auth";
// import { createUser } from '../backend/users'
import { errorMessageFromStatus } from "../common/errors";

const Authenticate = ({ setUser }) => {
  const [loading, setLoading] = useState();
  const [email, setEmail] = useState("");
  const [shop, setShop] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState();
  const [confirmPasswordError, setConfirmPasswordError] = useState();
  const [hasAccount, setHasAccount] = useState(true);
  const [recoverPwdRequest, setRecoverPwdRequest] = useState(false);
  const [submittedPwdRequest, setSubmittedPwdRequest] = useState(false);

  const token = new URLSearchParams(window.location.search).get("token");

  async function onSubmit(e) {
    e.preventDefault();
    setError();
    setLoading(true);

    const credentials = { email: email.trim().toLowerCase(), pwd: password.trim(), shop: shop.trim() };
    // todo: create if no account
    const res = hasAccount ? await loginUser(credentials) : await createUser(credentials);

    if (res.err) setError(res.val.message);
    if (res.ok) {
      setUser(res.val);
      if (!hasAccount) navigate("/", { replace: true });
    }

    setLoading();
  }

  async function onSubmitNewPwd(e) {
    if (token) {
      e.preventDefault();
      setError();

      if (confirmPassword !== password) {
        setConfirmPasswordError("The password confirmation does not match");
      } else {
        setConfirmPasswordError();
        setLoading(true);
      }

      const credentials = { pwd: password.trim(), token };
      const res = await resetPwd(credentials);
      if (res.err) setError(res.val.message);
      if (res.ok) {
        setUser(res.val);
        navigate("/", { replace: true });
      }
      setLoading();
    }
  }

  async function onSubmitRecovery(e) {
    e.preventDefault();
    setError();

    setLoading(true);

    const credentials = { shop: shop.trim(), email: email.trim() };
    const res = await resetPwdRequest(credentials);
    if (res.err) setError(res.val.message);
    if (res.ok) {
      setSubmittedPwdRequest(true);
    }
    setLoading();
  }

  return (
    <div className="h-screen w-full flex items-center justify-center">
      <p className="inline-block text absolute top-0 left-0 px-4 py-2">
        <Logo />
      </p>
      <div className="w-full max-w-md">
        {recoverPwdRequest ? (
          <form className="w-full max-w-md border rounded py-16 px-8" onSubmit={onSubmitRecovery}>
            <div className="mb-6 text-center">
              <p className="text-xl">Reset your password</p>
            </div>
            {error && <p className="text-orange my-4">{error}</p>}

            {!submittedPwdRequest ? (
              <>
                <Input
                  disabled={loading}
                  id="shop"
                  label="Shop domain"
                  placeholder="Enter shop domain"
                  onChange={setShop}
                  type="shop"
                  value={shop}
                  required={true}
                />

                <Input
                  className="mt-2"
                  disabled={loading}
                  id="email"
                  label="Email"
                  placeholder="Enter your email"
                  onChange={setEmail}
                  type="email"
                  value={email}
                  required={true}
                />

                <Button
                  className="mt-6 w-full"
                  disabled={loading}
                  loading={loading}
                  type="submit"
                  value="Reset"
                  variant={"primary"}
                />
              </>
            ) : (
              `A password reset link was sent to ${email}`
            )}
          </form>
        ) : !token ? (
          <form className="w-full max-w-md border rounded py-16 px-8" onSubmit={onSubmit}>
            <div className="mb-6 text-center">
              <p className="text-xl">{hasAccount ? "Sign into your account" : "Create an account"}</p>
            </div>
            {error && <p className="text-orange my-4">{error}</p>}

            <Input
              disabled={loading}
              id="shop"
              label="Shop domain"
              placeholder="Enter shop domain"
              onChange={setShop}
              type="shop"
              value={shop}
              required={true}
            />

            <Input
              className="mt-2"
              disabled={loading}
              id="email"
              label="Email"
              placeholder="Enter your email"
              onChange={setEmail}
              type="email"
              value={email}
              required={true}
            />
            <Input
              className="mt-2"
              disabled={loading}
              id="password"
              label="Password"
              placeholder="Enter your password"
              onChange={setPassword}
              type="password"
              value={password}
              required={true}
            />
            <Button
              className="mt-6 w-full"
              disabled={loading}
              loading={loading}
              type="submit"
              value={hasAccount ? "Sign In" : "Sign Up"}
              variant={hasAccount ? "secondary" : "primary"}
            />
          </form>
        ) : (
          <form className="w-full max-w-md border rounded py-16 px-8" onSubmit={onSubmitNewPwd}>
            <div className="mb-6 text-center">
              <p className="text-xl">Reset your password</p>
            </div>
            {error && <p className="text-orange my-4">{error}</p>}
            {confirmPasswordError && <p className="text-orange my-4">{confirmPasswordError}</p>}

            <Input
              className="mt-2"
              disabled={loading}
              id="password"
              label="Enter new password"
              placeholder=""
              onChange={setPassword}
              type="password"
              value={password}
              required={true}
            />

            <Input
              className="mt-2"
              disabled={loading}
              id="password"
              label="Confirm new password"
              placeholder=""
              onChange={setConfirmPassword}
              type="password"
              value={confirmPassword}
              required={true}
            />

            <Button
              className="mt-6 w-full"
              disabled={loading}
              loading={loading}
              type="submit"
              value="Reset"
              variant={"primary"}
            />
          </form>
        )}
        {!token && (
          <div className="text-right mt-4 pr-8">
            <p className="text-sm">
              {hasAccount ? "Not yet registered?" : "Already registered?"}{" "}
              <span
                className={`font-bold cursor-pointer ${hasAccount ? "text-orange" : ""}`}
                onClick={() => {
                  setRecoverPwdRequest(false);
                  setHasAccount(val => !val);
                }}
              >
                {hasAccount ? "Create Account" : "Sign In"}
              </span>
            </p>
            {hasAccount && !recoverPwdRequest && (
              <p className="text-sm">
                <span
                  className={`font-bold cursor-pointer ${hasAccount ? "text-orange" : ""}`}
                  onClick={() => {
                    setSubmittedPwdRequest(false);
                    setRecoverPwdRequest(true);
                  }}
                >
                  Click here to reset your password
                </span>
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

Authenticate.propTypes = {
  setUser: PropTypes.func.isRequired,
};

export { Authenticate };
