import React, { useState } from "react";
import PropTypes from "prop-types";
import { FaPen, FaUndo } from "react-icons/fa";

import { Address } from "../../Address";
import { Button } from "../../ButtonTs";
import { DatePicker } from "../../DatePicker";
import { DebouncedInput, debounce } from "../../DebouncedComponent";
import { Dropdown } from "../../DropdownTs";
import { Input } from "../../InputTs";

import { dateString } from "../../../helpers";
import { PROP_TYPES } from "../../../common/propTypes";
import { OrderExtended } from "../../../backend-v3/orders";
import { Location } from "../../../backend-v3/locations";
import { X } from "../../SVG/X";
import moment from "moment";
import { OrderUpdate } from "../../../backend-v3/orders";
import { InvoiceWithProductsExpanded } from "../../../backend-v3/invoices";

type Props = {
  locations: Location[];
  loading: boolean;
  readOnly: boolean;
  order: OrderExtended;
  updateOrder: (x: OrderUpdate, inline?: boolean) => void;
  currentInvoice: InvoiceWithProductsExpanded | undefined;
};

const OrderDetails = ({ currentInvoice, locations, loading, order, readOnly, updateOrder }: Props) => {
  const [editingLocation, setEditingLocation] = useState(false);

  type Fields = {
    // invoiceDate?: moment.Moment | null,
    // invoiceNumber?: string,
    locationId: number;
    // paymentDue?: moment.Moment | null
  };

  const [fields, setFields] = useState<Fields>({
    // invoiceDate: order.invoice_date,
    // invoiceNumber: order.invoice_number,
    locationId: order.location_id,
    // paymentDue: order.payment_due
  });

  return (
    <div className="inline-block relative">
      <div className="mt-4">
        <div className="flex justify-between items-end">
          <div className="flex">
            <div className="mr-2">
              {editingLocation && (
                <div className="mt-2 flex items-end">
                  <DebouncedInput
                    Component={Dropdown}
                    props={{
                      id: "shipping-address",
                      label: "Select Shipping Address",
                      value: fields.locationId.toString(),
                      options: locations.map(l => ({ name: l.name, value: l.id.toString() })),
                    }}
                    requestHandler={value => updateOrder({ locationId: Number(value) }, true)}
                    setValue={value => {
                      let locationId = Number(value);
                      setFields(x => ({ ...x, locationId }));
                      setEditingLocation(false);
                    }}
                  />
                  <div className="ml-2 pb-1">
                    <Button iconBefore={<FaUndo />} onClick={() => setEditingLocation(false)} variant="plain" />
                  </div>
                </div>
              )}
              {fields.locationId && !editingLocation && (
                <div className="mt-2 flex">
                  <Address data={locations.find(loc => loc.id === fields.locationId)} />
                  {!readOnly && (
                    <div className="ml-2 -mt-1">
                      <Button iconBefore={<FaPen />} onClick={() => setEditingLocation(true)} variant="plain" />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { OrderDetails };
