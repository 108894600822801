import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";

import { Dropdown } from "../../DropdownTs";
import { Modal } from "../../Modal";

import { Location } from "../../../backend-v3/locations";
import { Vendor } from "../../../backend-v3/suppliers";
import { StocktakeCreate } from "../../../backend-v3/stocktakes";

type Props = {
  isOpen: boolean;
  loading: boolean;

  locations: Location[];
  onClose: () => void;
  onSubmit: (newStocktake: StocktakeCreate) => void;
  vendors: Vendor[];
};

const CreateStocktakeModal = ({ isOpen, loading, locations, onClose, onSubmit, vendors }: Props) => {
  const [location, setLocation] = useState(locations[0]?.id);
  const [vendor, setVendor] = useState<number>(0);
  const vendorList = [{ name: "All vendors", value: "0" }].concat(
    vendors.map(({ id: value, name }) => ({
      name,
      value: value.toString(),
    }))
  );

  return (
    <Modal
      closeModal={onClose}
      disabled={false}
      loading={loading}
      heading="New Stocktake"
      isOpen={isOpen}
      onSubmit={() => {
        if (location) {
          onSubmit({
            location_id: location,
            vendor_id: vendor === 0 ? null : vendor,
          });
        }
      }}
      submitIcon={<FaPlus />}
      submitValue="Create"
    >
      <Dropdown
        className="mt-6"
        id="new-stocktake-vendor"
        label="Vendor"
        onChange={(x: string) => {
          setVendor(Number(x));
        }}
        options={vendorList}
        value={vendor?.toString()}
      />
      <Dropdown
        className="mt-4"
        id="new-vendor-location"
        label="Store / Warehouse Location"
        onChange={(x: string) => setLocation(Number(x))}
        options={locations.map(({ id, name }) => ({ value: id.toString(), name }))}
        value={location?.toString()}
      />
    </Modal>
  );
};

export { CreateStocktakeModal };
