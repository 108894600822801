import React, { useState } from "react";
import { FaSyncAlt } from "react-icons/fa";
import { Button } from "../../ButtonTs";
import { DateRangePicker } from "../../DateRangePickerTs";
import { Dropdown } from "../../DropdownTs";
import { Location } from "../../../backend-v3/orders";
import { Range, StatsQuery } from "../../../hooks/useOrderTs";

type Props = {
  initialDateRange: Range;
  fetching: boolean;
  locations: Location[];
  onFill: () => void;
  statsQuery: StatsQuery | undefined;
  setStatsQuery: React.Dispatch<React.SetStateAction<StatsQuery | undefined>>;
  onRefresh: (range: Range, locationId: number, supplierId: number) => void;
};

const OrderStockPosition = ({ fetching, locations, onFill, onRefresh, setStatsQuery, statsQuery }: Props) => {
  const [isEdited, setIsEdited] = useState(false);

  const allLocationsPlaceholder = { id: "all", companyName: "All locations" };

  type LocationId = {
    type: "locationId";
    val: number;
  };
  type SupplierId = {
    type: "supplierId";
    val: number;
  };

  type RangeArg = {
    type: "range";
    val: Range;
  };

  function onChange(val: LocationId | SupplierId | RangeArg) {
    setStatsQuery(prev => {
      if (prev) {
        const newObj = { ...prev };
        switch (val.type) {
          case "locationId":
            newObj.location = val.val;
            break;
          case "supplierId":
            newObj.supplier = val.val;
            break;
          case "range":
            newObj.date = val.val;
            break;
        }
        return newObj;
      }
    });

    if (!isEdited) setIsEdited(true);
  }

  return statsQuery ? (
    <div className="flex items-end">
      <div className="mr-2 w-auto">
        <label className="font-bold text-sm" htmlFor="positionDateRange">
          Sales Date Range
        </label>
        <div className="relative mt-1">
          <DateRangePicker
            id="positionDateRange"
            maxDate={new Date()}
            onChange={(val: Range) => onChange({ type: "range", val })}
            value={statsQuery.date}
          />
        </div>
      </div>
      {
        <Dropdown
          className="w-48 mr-2"
          id="positionLocation"
          label="Sales Location"
          onChange={(val: string) => onChange({ type: "locationId", val: Number(val) })}
          options={[
            { value: "", name: "All locations" },
            ...locations.map(loc => ({ value: loc.id.toString(), name: loc.name })),
          ]}
          value={statsQuery.location.toString()}
        />
      }

      <Button
        className="mr-2"
        disabled={fetching}
        iconBefore={<FaSyncAlt />}
        onClick={() => {
          onRefresh(statsQuery.date, statsQuery.location, statsQuery.supplier);
          setIsEdited(false);
        }}
        value="Refresh"
        variant="secondary"
      />
      <Button disabled={isEdited || fetching} onClick={onFill} value="Fill quantities" variant="outline" />
    </div>
  ) : null;
};

export { OrderStockPosition };
