import React, { FunctionComponent, useState } from 'react'
import PropTypes from 'prop-types'
import { FaTrash } from 'react-icons/fa'

import { Button } from './ButtonTs'
import { LoadingLocal } from './LoadingLocal'


type BulkAction<T> = {
  bulkChecked: T[],
  onBulkChange: (id: T | 'all', checked: boolean) => void
}

type Props<T> = {
  bulkAction?: (x: T[]) => void,
  childIds?: T[],
  className?: string,
  emptyState?: React.ReactNode,
  fullWidth?: boolean,
  loading?: boolean,
  readOnly?: boolean,
  renderBody: FunctionComponent<BulkAction<T>>,
  renderHead: FunctionComponent<BulkAction<T>>
}

const Table = <T extends unknown>({
  bulkAction,
  childIds = [],
  className = '',
  emptyState,
  fullWidth = true,
  loading = false,
  readOnly = false,
  renderBody,
  renderHead,
}: Props<T> ) => {
  const [bulkChecked, setBulkChecked] = useState([] as T[])
  const bulkActionDisabled = (bulkAction && bulkChecked.length > 0) || readOnly

  function handleBulkAction() {
    if (bulkAction) {
      bulkAction(bulkChecked)
    }
    setBulkChecked([])
  }

  function onBulkChange(id: T | 'all', checked: boolean) {
    switch (id) {
      case 'all':
        setBulkChecked(!checked ? childIds : [])
        break
      default:
        setBulkChecked(checked ? bulkChecked.filter(x => x !== id) : [...bulkChecked, id])
    }
  }

  return (
    <div className={`relative ${className}`}>
      {bulkAction && (
        <div className="mb-2">
          <Button
            disabled={!bulkActionDisabled || loading}
            iconBefore={<FaTrash />}
            onClick={handleBulkAction}
            size="small"
            value="Delete selected"
            variant="secondary"
          />
        </div>
      )}
      <table className={`${fullWidth ? 'w-full' : ''}`}>
        <thead>
          {renderHead({
            bulkChecked,
            onBulkChange,
          })}
        </thead>
        <tbody className="text-sm">
          {renderBody({
            bulkChecked,
            onBulkChange,
          })}
        </tbody>
      </table>
      {emptyState && <div className="p-2">{emptyState}</div>}
      <LoadingLocal loading={loading} />
    </div>
  )
}


export { Table }
