import React from 'react'
import PropTypes from 'prop-types'
import { FaTrash } from 'react-icons/fa'

import { Modal } from '../../Modal'

const DeleteStocktakeModal = ({ loading, isOpen, onClose, onSubmit, ...props }) => (
  <Modal
    closeModal={onClose}
    loading={loading}
    heading="Delete Stocktake"
    isOpen={isOpen}
    onSubmit={onSubmit}
    submitIcon={<FaTrash />}
    submitValue="Delete"
    {...props}
  >
    <div className="mt-4">
      <p>This will delete the stocktake. Are you sure you want to do this?</p>
    </div>
  </Modal>
)

DeleteStocktakeModal.propTypes = {
  isOpen: PropTypes.bool,
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

DeleteStocktakeModal.defaultProps = {
  isOpen: false,
  loading: false,
}

export { DeleteStocktakeModal }
