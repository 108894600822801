import { appendFile } from "fs/promises";
import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "throttle-debounce";
import { getLocations } from "../backend-v3/locations";
import { BinLocation, getAllProducts, Location, ProductVariant, upsertBinLocation } from "../backend-v3/orders";
import { AutoCompleteResult } from "../components/AutoCompleteResult";
import { AutoComplete } from "../components/AutoCompleteTs";
import { Dropdown } from "../components/DropdownTs";
import { EmptyTableRow } from "../components/EmptyStates/EmptyTableRow";
import { Header } from "../components/Header";
import { Input } from "../components/InputTs";
import { Loading } from "../components/Loading";
import { TableCell } from "../components/TableCellTs";
import { TableHeadCell } from "../components/TableHeadCell";
import { TableHeadRow } from "../components/TableHeadRow";
import { Table } from "../components/TableTs";
import { sortArrayByObjectKey } from "../helpersTs";
import { useFeedback } from "../hooks/useFeedbackTs";

export const Products = () => {
  const { setSaved, setError } = useFeedback();
  const [products, setProducts] = useState<ProductVariant[]>();
  const [results, setResults] = useState<ProductVariant[]>([]);
  const [locations, setLocations] = useState<Record<number, Location>>({});

  // product variant id -- location id
  const [newLocationBinForProduct, setNewLocationBinForProduct] = useState<{ pvId: number; locId: number }>();
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);
  const [query, setQuery] = useState("");
  const [queried, setQueried] = useState(false);

  useEffect(() => {
    getData();
    return () => {
      isMounted.current = false;
    };
  }, []);

  async function getData() {
    setLoading(true);
    const locations = await getLocations();
    const res = await getAllProducts();
    if (!isMounted.current) return;
    if (res.err || locations.err) {
      setError("Error while retrieving products");
    } else {
      setProducts(res.val);
      const record = locations.val.reduce<Record<number, Location>>(
        (acc, val) => ({
          ...acc,
          [val.id]: val,
        }),
        {}
      );
      setLocations(record);
      setResults(res.val);
    }
    setLoading(false);
  }

  async function updateBin(pvId: number, locationId: number, newBinName: string) {
    setSaved(false);
    const res = await upsertBinLocation({
      product_variant_id: pvId,
      location_id: locationId,
      name: newBinName,
    });
    if (!isMounted.current) return;
    if (res.err) {
      setSaved("error");
      setError(res.val.message);
    }
    if (res.ok) {
      setSaved(false);
      setResults(products => {
        // if (products) {
        return products.map(product => {
          if (product.id === pvId) {
            const binLocationUpdate =
              product.bin_locations.find(binLocation => binLocation.location_id === locationId) !== undefined;

            console.log("binLocationUpdate: ", binLocationUpdate, [
              { product_variant_id: pvId, location_id: locationId, name: newBinName },
              ...product.bin_locations,
            ]);
            return {
              ...product,

              bin_locations: binLocationUpdate
                ? product.bin_locations.map(bin => {
                    if (bin.location_id === locationId) {
                      return { ...bin, name: newBinName };
                    } else return bin;
                  })
                : [...product.bin_locations, { product_variant_id: pvId, location_id: locationId, name: newBinName }],
            };
          } else {
            return product;
          }
        });
        // }
      });
    }
    setSaved(true);
  }

  function getResults(q: string | undefined, products: ProductVariant[]): ProductVariant[] {
    if (!q || !products) return products ?? [];
    const result = products.reduce<ProductVariant[]>(
      (acc, cur) =>
        cur.full_title.toLowerCase().includes(q) ||
        (cur.sku && cur.sku.toLowerCase().includes(q)) ||
        cur.product.vendor.name.toLowerCase().includes(q)
          ? [...acc, cur]
          : acc,
      []
    );

    return result;
  }

  const handleGetResults = useCallback(
    debounce(200, (q: string, products: ProductVariant[]) => {
      const results = getResults(q, products);
      setResults(results);
      if (!queried) setQueried(true);
    }),
    []
  );

  const getRemainingBinLocations = (locations: Record<number, Location>, bins: BinLocation[]): number[] => {
    const binLocIds = bins.map(bin => bin.location_id);
    const allLocIds = Object.keys(locations).map(Number);
    return allLocIds.filter(locId => !binLocIds.includes(locId));
  };

  return loading || products === undefined ? (
    <Loading />
  ) : (
    <div className="max-w-site mb-8">
      <Header crumbs={[{ to: "/products", value: "Products" }]} />
      <Input
        id={"products"}
        className="w-full"
        onChange={q => {
          setQuery(q);
          handleGetResults(q.toString().trim().toLowerCase(), products);
        }}
        placeholder="Search by product name, sku or vendor"
        value={query}
      />
      <Table
        className="mt-8"
        renderHead={() => (
          <TableHeadRow>
            <TableHeadCell value="Product" width="6/12" />
            <TableHeadCell value="SKU" width="3/12" />
            <TableHeadCell value="Bin" width="3/12" />
          </TableHeadRow>
        )}
        renderBody={() => (
          <>
            {JSON.stringify(newLocationBinForProduct)}
            {sortArrayByObjectKey(results, "full_title").map(({ bin_locations, id, sku, full_title, url }) => (
              <tr className="align-top" key={id}>
                <TableCell value={{ initial: full_title, type: "string" }} url={url} />
                <TableCell value={{ initial: sku, type: "string" }} />
                <td className="pl-2 py-2">
                  <table>
                    {bin_locations.map(bin => (
                      <tr className="mb-2">
                        <TableCell value={{ initial: locations[bin.location_id]?.name, type: "string" }} width="32" />
                        <TableCell
                          value={{ initial: bin.name, type: "string" }}
                          onChange={value => updateBin(id, bin.location_id, value)}
                          width="32"
                        />
                      </tr>
                    ))}
                  </table>
                  {newLocationBinForProduct?.pvId === id && (
                    <tr className="mb-2">
                      <TableCell
                        value={{ initial: locations[newLocationBinForProduct.locId]?.name, type: "string" }}
                        width="32"
                      />
                      <TableCell
                        value={{ initial: "", type: "string" }}
                        onChange={async value => {
                          await updateBin(id, newLocationBinForProduct.locId, value);
                          setNewLocationBinForProduct(undefined);
                        }}
                        width="32"
                      />
                    </tr>
                  )}

                  {getRemainingBinLocations(locations, bin_locations).length > 0 && (
                    <Dropdown
                      className="w-32 text-xs"
                      id=""
                      selectClass="border-gray-100 text-gray-900"
                      placeholder={newLocationBinForProduct?.pvId === id ? "Add new bin" : "Add new bin"}
                      onChange={(x: string) => {
                        if (x !== "") {
                          setNewLocationBinForProduct(newBin => {
                            return { locId: Number(x), pvId: id };
                          });
                        }
                      }}
                      options={getRemainingBinLocations(locations, bin_locations).map(bin => {
                        const loc = locations[bin];
                        return { name: loc?.name!, value: String(bin) };
                      })}
                      value={""}
                    />
                  )}
                </td>
              </tr>
            ))}
          </>
        )}
      />
    </div>
  );
};
