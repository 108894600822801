import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = ({ checked, onChange, ...props }) => (
  <input type="checkbox" checked={checked} onChange={onChange} {...props} />
)

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export { Checkbox }
