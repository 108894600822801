import React from 'react'

import { Modal } from './Modal'

import { ErrorMessage } from './ErrorMessage'

type Props = {
  error: string,
  loading?: boolean,
  onClose: () => void
}

const ErrorModal = ({ error, loading = false, onClose }: Props) => (
  <Modal
    cancelValue="Close"
    closeModal={onClose}
    heading="Error"
    loading={loading}
    isOpen={error}
  >
    <ErrorMessage error={error} />
  </Modal>
)

export { ErrorModal }
