import React, { useState, useEffect } from "react";
import PropTypes, { string } from "prop-types";
import { FaTrash, FaPlus } from "react-icons/fa";

import { Button } from "../../ButtonTs";
import { CreateExpenseModal } from "./CreateExpenseModal";
import { DeleteExpenseModal } from "./DeleteExpenseModal";
import { EmptyTableRow } from "../../EmptyStates/EmptyTableRow";

import { Table } from "../../TableTs";
import { TableCell } from "../../TableCellTs";
import { TableCellInput } from "../../TableCellInputTs";
import { TableHeadCell } from "../../TableHeadCell";
import { TableHeadRow } from "../../TableHeadRow";

import { PROP_TYPES } from "../../../common/propTypes";
import { roundToTwo } from "../../../helpersTs";
import { InvoiceExpense } from "../../../backend-v3/invoices";
import { ExpenseUpdate } from "../../../backend-v3/invoices";
import { NewExpense } from "../../../backend-v3/invoices";

type Props = {
  readOnly: boolean;
  expenses: InvoiceExpense[];
  createExpense: (expense: NewExpense) => void;
  deleteExpense: (expenseId: number) => void;
  loading: boolean;
  modalLoading: boolean;
  updateExpense: (data: ExpenseUpdate) => void;
};

const OrderExpensesTable = ({
  createExpense,
  deleteExpense,
  expenses,
  loading,
  modalLoading,
  readOnly,
  updateExpense,
}: Props) => {
  const [currentExpense, setCurrentExpense] = useState<InvoiceExpense>();
  const [modal, setModal] = useState<"delete" | "create">();

  useEffect(() => {
    if (!modalLoading) setModal(undefined);
  }, [modalLoading]);

  function handleDeleteClick(expenseId: number) {
    setCurrentExpense(expenses.find(({ id }) => id === expenseId));
    setModal("delete");
  }

  return (
    <div className="w-full">
      <div className="flex justify-between">
        <h2 className="font-bold">Fixed Expenses</h2>
        {!readOnly && (
          <Button
            disabled={loading}
            iconBefore={<FaPlus />}
            onClick={() => setModal("create")}
            size="small"
            value="Add Expense"
            variant="secondary"
          />
        )}
      </div>
      <Table
        className="mt-2"
        readOnly={readOnly}
        fullWidth
        renderHead={() => (
          <TableHeadRow>
            <TableHeadCell value="Description" width="3/4" />
            <TableHeadCell value="Amount" width="1/4" />
            {!readOnly && <TableHeadCell alignment="right" width="32" value="Actions" />}
          </TableHeadRow>
        )}
        renderBody={() => (
          <>
            {expenses.map(({ id, amount, description }) => (
              <tr key={id}>
                <TableCell
                  slim
                  value={
                    !readOnly
                      ? {
                          type: "element",
                          initial: (
                            <TableCellInput
                              className="capitalize"
                              disabled={loading}
                              onChange={description => {
                                updateExpense({
                                  id,
                                  description,
                                });
                              }}
                              type={{
                                type: "string",
                                initial: description,
                              }}
                            />
                          ),
                        }
                      : { type: "string", initial: description }
                  }
                />
                <TableCell
                  slim
                  value={
                    !readOnly
                      ? {
                          type: "element",
                          initial: (
                            <TableCellInput
                              className="capitalize"
                              disabled={loading}
                              onChange={val => {
                                let value = Number(roundToTwo(parseFloat(val))) || 0;
                                if (value == 0 || value) {
                                  updateExpense({
                                    id,
                                    amount: value,
                                  });
                                }
                              }}
                              type={{
                                type: "number",
                                initial: Number(roundToTwo(amount)),
                                positiveOnly: true,
                              }}
                            />
                          ),
                        }
                      : {
                          type: "string",
                          initial: roundToTwo(amount),
                        }
                  }
                />
                {!readOnly && (
                  <TableCell
                    alignment="right"
                    value={{
                      type: "element",
                      initial: (
                        <span className="flex items-center justify-end inline-block">
                          <Button
                            disabled={loading}
                            size="small"
                            variant="ghost"
                            iconBefore={<FaTrash />}
                            onClick={() => handleDeleteClick(id)}
                          />
                        </span>
                      ),
                    }}
                  />
                )}
              </tr>
            ))}
            {expenses.length < 1 && <EmptyTableRow columns={["description", "amount"]} hasActions />}
          </>
        )}
      />
      {modal === "create" && (
        <CreateExpenseModal
          isOpen
          loading={modalLoading}
          onClose={() => setModal(undefined)}
          onSubmit={createExpense}
        />
      )}
      <DeleteExpenseModal
        expense={currentExpense}
        isOpen={currentExpense && modal === "delete"}
        loading={modalLoading}
        onClose={() => setModal(undefined)}
        onSubmit={deleteExpense}
      />
    </div>
  );
};

export { OrderExpensesTable };
