import React from 'react'
import PropTypes from 'prop-types'

type Props = {
  rows?: number,
  label: string,
  className?: string,
  disabled? : boolean,
  onChange? : (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
  value: string
}

const Textarea = ({ className = '', label, rows = 6, ...props } : Props) => (
  <div className={`w-full ${className}`}>
    {label && <label className="text-sm font-bold">{label}</label>}
    <textarea
      className="border rounded w-full block mt-1 p-4 text-lg disabled:border-gray-300 disabled:text-gray-300"
      rows={6}
      {...props}
    />
  </div>
)

Textarea.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  rows: PropTypes.number,
}

Textarea.defaultProps = {
  className: '',
  label: null,
}

export { Textarea }
