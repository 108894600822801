import React, { useEffect } from "react";
import { FaPlus, FaArrowRight } from "react-icons/fa";

import { AddProductModal } from "../components/AddProductModal";
import { Button } from "../components/ButtonTs";
import { Loading } from "../components/Loading";
import { LoadingGlobal } from "../components/LoadingGlobal";
import { NotFound } from "./NotFound";
import { TransferHeader } from "../components/Layouts/Transfer/TransferHeader";
import { TransferReceiveModal } from "../components/Layouts/Transfer/TransferReceiveModal";
import { TransferRequestModal } from "../components/Layouts/Transfer/TransferRequestModal";
import { TransferFulfillModal } from "../components/Layouts/Transfer/TransferFulfillModal";
import { TransferTable } from "../components/Layouts/Transfer/TransferTable";

import { useLocations } from "../hooks/useLocationsTs";
import { useOverlay, ModalState } from "../hooks/useOverlayTs";

import { useTransfer } from "../hooks/useTransfer";
import * as types from "../common/types";

type Props = {
  transferId: number;
  parent: types.Parent;
};

type OverlayState = undefined | "fulfill" | "receive" | "addProducts" | "request";

const Transfer = ({ transferId, parent }: Props) => {
  const { ref, overlay, setOverlay }: ModalState<OverlayState> = useOverlay();

  const { locations, isFetchingLocations } = useLocations();
  const {
    addTransferProducts,
    deleteTransferProducts,
    changeTransferStatus,
    updateTransferProduct,
    transfer,
    isFetchingTransfer,
    isTransferModalLoading,
    isUpdatingTransfer,
    notFound,
    allProducts,
  } = useTransfer(transferId);

  const isFulfilled = transfer && transfer.transfer.status === "fulfilled";
  const isReceived = transfer && transfer.transfer.status === "done";

  useEffect(() => {
    if (transfer && !isTransferModalLoading) setOverlay(undefined);
  }, [isTransferModalLoading]);

  if (notFound) return <NotFound />;

  if (!transfer || !locations || isFetchingTransfer || isFetchingLocations) return <Loading />;

  return (
    <div className="max-w-site mb-12">
      <TransferHeader parent={parent} transfer={transfer.transfer} setModal={setOverlay} />
      <div className="my-8 flex items-center">
        <h2>
          <span className="font-bold">Origin location</span>:{" "}
          <span className="capitalize">
            {locations.find(location => location.id === transfer.transfer.from_location_id)?.name}
          </span>
        </h2>
        <span className="px-4">
          <FaArrowRight />
        </span>
        <h2>
          <span className="font-bold">Destination location</span>:{" "}
          <span className="capitalize">
            {locations.find(location => location.id === transfer.transfer.to_location_id)?.name}
          </span>
        </h2>
      </div>
      <TransferTable
        deleteTransferProducts={deleteTransferProducts}
        transfer={transfer}
        updateTransferProduct={updateTransferProduct}
      />
      {!isFulfilled && !isReceived && (
        <div className="mt-4">
          <Button
            iconBefore={<FaPlus />}
            onClick={(e: any) => {
              e.stopPropagation();
              setOverlay("addProducts");
            }}
            size="small"
            value="Add Products"
            variant="primary"
          />
        </div>
      )}
      {overlay && (
        <div ref={ref} className="fixed top-0 right-0 w-full h-screen z-50">
          <AddProductModal
            isOpen={overlay === "addProducts"}
            loading={isTransferModalLoading}
            onClose={() => setOverlay(undefined)}
            onSubmit={products => addTransferProducts(products)}
            currentProductsVariantIds={transfer.products.map(p => p.product_variant_id)}
            allProducts={allProducts}
          />
          <TransferFulfillModal
            isOpen={overlay === "fulfill"}
            loading={isTransferModalLoading}
            onClose={() => setOverlay(undefined)}
            onSubmit={() => changeTransferStatus("fulfilled")}
          />
          <TransferReceiveModal
            isOpen={overlay === "receive"}
            loading={isTransferModalLoading}
            onClose={() => setOverlay(undefined)}
            onSubmit={() => changeTransferStatus("done")}
            transferId={transfer.transfer.id}
          />
          <TransferRequestModal
            isOpen={overlay === "request"}
            loading={isTransferModalLoading}
            onClose={() => setOverlay(undefined)}
            onSubmit={() => changeTransferStatus("requested")}
          />
        </div>
      )}
      <LoadingGlobal loading={isUpdatingTransfer} />
    </div>
  );
};

export { Transfer, OverlayState };
