import React, { Dispatch, SetStateAction, useState } from "react";
import { FaDownload, FaUndo, FaSyncAlt, FaEye, FaFileInvoiceDollar } from "react-icons/fa";

import { Button } from "../../ButtonTs";
import { Header } from "../../Header";
import { Status } from "../../Status";
import { ExportToCsv } from "export-to-csv";
import * as types from "../../../common/types";
import { OrderExtended } from "../../../backend-v3/orders";
import { OverlayState } from "./types";
import { InvoiceButton } from "./OrderHeaderInvoice";
import { getInvoicesAction, invoicesQuery } from "./common";
import { Invoice, InvoiceWithProductsExpanded } from "../../../backend-v3/invoices";
import { Search } from "../../../hooks/useOrderTs";
import { navigate } from "@reach/router";
// import Example from './OrderHeaderInvoice'

type Props = {
  parent: types.Parent;
  isEditing: boolean;
  order: OrderExtended;
  setOverlay: (m: OverlayState) => void;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  isUpdatingXero: boolean;
  currentInvoice: InvoiceWithProductsExpanded | undefined;
  loadInvoice: (invoiceId?: number) => void;
  createInvoice: (invoiceNumber?: string) => void;
  searchInvoice: (invoiceNumber: string) => void;
  search: Search;
  orderInvoices: Invoice[];
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  addExistingInvoice: (invoiceId: number) => void;
  deleteOrderInvoice: (invoiceId: number) => void;
};

const OrderHeader = ({
  isEditing,
  parent,
  order,
  setIsEditing,
  setOverlay,
  isUpdatingXero,
  currentInvoice,
  loadInvoice,
  createInvoice,
  searchInvoice,
  orderInvoices,
  search,
  searchTerm,
  setSearchTerm,
  addExistingInvoice,
  deleteOrderInvoice,
  ...props
}: Props) => {
  const isDraft = order.status === "draft";
  const isSent = order.status === "sent";
  const isPartial = order.status === "partially_received";
  const isReceived = order.status === "received";

  const csvExporter = new ExportToCsv({
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  });

  type CsvData = {
    title: string;
    sku: string;
    supplier_code: string;
    quantity_ordered: string;
  };

  const data: CsvData[] = order.order_products
    .filter(op => op.qty_ordered > 0)
    .map(op => ({
      title: op.product_variant.full_title,
      sku: op.product_variant.sku ?? "",
      supplier_code: op.supplier_product_variant?.code ?? "",
      quantity_ordered: op.qty_ordered.toString(),
    }));

  const dataForCsv: CsvData[] =
    data.length > 0
      ? data
      : [
          {
            title: "",
            sku: "",
            supplier_code: "",
            quantity_ordered: "",
          },
        ];

  // const sendInvoicesStatus = getInvoicesAction(currentInvoice ? [currentInvoice] : order.invoices);

  return (
    <Header
      actions={
        <>
          {/* <InvoiceButton status={props.status} setInvoice={props.setInvoice} /> */}
          {(isSent || isPartial || isReceived) && (
            <InvoiceButton
              createInvoice={createInvoice}
              orderInvoices={orderInvoices}
              currentInvoice={currentInvoice}
              loadInvoice={loadInvoice}
              searchInvoice={searchInvoice}
              search={search}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              addExistingInvoice={addExistingInvoice}
              deleteOrderInvoice={deleteOrderInvoice}
              editable={!isReceived}
            />
          )}
          <Button
            onClick={() => csvExporter.generateCsv(dataForCsv)}
            iconBefore={<FaDownload />}
            rel="noopener noreferrer"
            value="Download"
            variant="ghost"
          ></Button>
          {isDraft && (
            <Button
              className="ml-2"
              onClick={(e: any) => {
                e.stopPropagation();
                setOverlay("send");
              }}
              value="Mark as sent"
            />
          )}

          {isSent && (
            <>
              {
                <>
                  <Button
                    className="ml-2"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setOverlay("receive");
                    }}
                    value="Mark as received"
                    variant="outline"
                  />
                </>
              }
            </>
          )}
          {isPartial && (
            <>
              <Button
                className="ml-2"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setOverlay("receive");
                }}
                value="Mark as received"
              />
            </>
          )}

          {!isDraft && (
            <Button
              className="ml-2"
              iconBefore={<FaUndo />}
              onClick={(e: any) => {
                e.stopPropagation();
                setOverlay("status");
              }}
              value={isPartial ? "Revert to Draft" : "Revert Status"}
              variant="secondary"
            />
          )}

          {(isPartial || isReceived) && (
            <>
              {/* {sendInvoicesStatus && (
                <Button
                  className="ml-2"
                  iconBefore={<FaFileInvoiceDollar />}
                  loading={isUpdatingXero}
                  onClick={() => sendToXero()}
                  value={sendInvoicesStatus}
                  variant="secondary"
                />
              )} */}

              {/* {currentInvoice?.xero_invoice_id && (
                <Button
                  className="ml-2"
                  iconBefore={<FaEye />}
                  onClick={() => {
                    navigate(invoicesQuery(order.invoices));
                  }}
                  value="View in Xero"
                  variant="secondary"
                />
              )} */}
            </>
          )}
        </>
      }
      crumbs={[
        { to: parent?.to ?? "", value: parent?.value ?? "" },
        {
          to: `${parent?.to ?? ""}/${order.order_id}`,
          value: (
            <div>
              {order.order_number} <Status className="mx-2 text-sm" status={order.status} />{" "}
              <span className="text-black inline-block text-sm font-bold">Created at:</span>{" "}
              <span className="text-black inline-block text-sm">{order.order_created_at.format("L")}</span>
            </div>
          ),
        },
      ]}
      {...props}
    />
  );
};

export { OrderHeader };
