import React from "react";
import PropTypes from "prop-types";
import { FaPlus } from "react-icons/fa";

import { Button } from "../../ButtonTs";
import { Header } from "../../Header";
import { PROP_TYPES } from "../../../common/propTypes";

const SupplierHeader = ({ onAddVendor, parent, supplier }) => (
  <Header
    actions={<Button iconBefore={<FaPlus />} onClick={onAddVendor} value="Add vendor" variant="secondary" />}
    crumbs={[
      { to: parent.to, value: parent.value },
      {
        to: `${parent.to}/${supplier.id}`,
        value: supplier.name,
      },
    ]}
  />
);

SupplierHeader.propTypes = {
  onAddVendor: PropTypes.func.isRequired,
  parent: PropTypes.shape({
    to: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  supplier: PROP_TYPES.SUPPLIER.isRequired,
};

export { SupplierHeader };
