import * as moment from "moment";
import { Result, Ok, Err } from "ts-results";
import { gql } from "graphql-request";
import { getAuth } from "./auth";
import { client, legacyRequest, request, result } from "./helpers";
import { Location } from "./locations";
import { Vendor, Supplier, processSupplier, SupplierWithoutVendors } from "./suppliers";
import { toWhole, undefinedOrNullToNumber } from "../helpersTs";
import {
  ProductVariantFieldsFragment,
  OrderFieldsFragment,
  OrderProductsFieldsFragment,
  StockStatsFragment,
} from "../generated/graphql";
import { Invoice, InvoiceWithProductsExpanded, processInvoice, processInvoiceExpense } from "./invoices";

type CsvOrderRow = {
  sku: string;
  qty: number;
};

type OrderProductUpdate = {
  id: number;
  qty_ordered?: number;
};

type OrderUpdate = {
  locationId?: number;
  notes?: string;
  adjustments?: number;
  orderStatus?: OrderStatusUpdate;
};

interface Order {
  location_id: number;
  order_id: number;
  order_created_at: moment.Moment;
  version_created_at: moment.Moment;
  received_at?: moment.Moment;
  order_number: number;
  total: number;
  status: OrderStatus;

  invoices: Invoice[];

  notes?: string;
  order_date: moment.Moment;
  supplier_id: number;

  supplier_short: {
    id: number;
    name: string;
  };
}

type OrderExtra = Order & {
  location: Location;
  supplier: Supplier;
};

interface OrderExtended extends OrderExtra {
  order_products: OrderProduct[];
}

type Product = {
  title: string;
  status: string;
  vendor: Vendor;
  tags: string[];
};

export type BinLocation = {
  product_variant_id: number;
  location_id: number;
  name: string;
};

type ProductVariant = {
  created_at: moment.Moment;
  display_name: string;
  id: number;
  inventory_id: string;
  price: number;
  sku?: string;
  title: string;
  full_title: string;
  url: string;
  updated_at: moment.Moment;
  shopify_product_id: string;
  shopify_product_variant_id: string;
  product: Product;
  deleted_at?: moment.Moment;
  bin_locations: BinLocation[];
  bin_locations_record: Record<number, BinLocation>;
  shop: {
    shop: string;
  };
};

type ProductVariantSupplier = ProductVariant & {
  code?: string;
};

type OrderProduct = {
  id: number;
  paid_per_unit?: number;
  qty_ordered: number;
  qty_received?: number;
  last_paid_per_unit: number;
  product_variant: ProductVariant;
  supplier_product_variant?: {
    code?: string | null;
    expected_cost?: number | null;
  };
};

type ProductStats = {
  sold_qty: number;
  in_stock: number;
  orders_in_transfer: number;
  available_days: number;
};

type ProductStatsRecord = Record<number, ProductStats>;

type ProductVariantInfoWithSupplierCode = ProductVariant & {
  supplier_code?: string;
};

type PrepopulateOption = "vendor" | "assigned";

type NewOrder = {
  locationId: number;
  supplierId: number;
  prepopulateOptions: PrepopulateOption[];
  skuLines?: {
    sku: string;
    qty: number;
  }[];
};

enum OrderStatus {
  Draft = "draft",
  Sent = "sent",
  PartiallyReceived = "partially_received",
  Received = "received",
}

enum OrderStatusUpdate {
  Draft = "draft",
  Sent = "sent",
  Received = "received",
}

const processOrder = (data: OrderFieldsFragment): Order => {
  return {
    location_id: data.location_id!,
    order_id: data.order_id!,
    order_number: data.order_number!,
    total: data.total,
    status: data.status,
    supplier_short: data.supplier!,
    order_created_at: moment(data.order_created_at),
    version_created_at: moment(data.version_created_at),
    received_at: data.first_received_at ? moment(data.first_received_at) : undefined,
    invoices: data.order_invoices.map(x => processInvoice(x.invoice_view!)),
    notes: data.notes === null || data.notes === undefined ? undefined : data.notes,
    supplier_id: data.supplier_id!,
    order_date: moment(data.order_date),
  };
};

const processProductVariant = (x: ProductVariantFieldsFragment): ProductVariant | null => {
  // if product is undefined then ignore pv

  if (x.product === undefined || x.product === null) {
    return null;
  }

  const vendor: Vendor = {
    ...x.product.vendor,
  };

  const product: Product = {
    title: x.product.title,
    status: x.product.status,
    tags: x.product.tags ?? [],
    vendor,
  };

  const product_id = (x.shopify_product_id as string).substring(22);
  const product_variant_id = (x.shopify_product_variant_id as string).substring(29);

  return {
    ...x,
    bin_locations_record: x.bin_locations.reduce<Record<number, BinLocation>>(
      (acc, val) => ({
        ...acc,
        [val.location_id]: val,
      }),
      {}
    ),
    sku: x.sku === null ? undefined : x.sku,
    created_at: moment(x.created_at),
    full_title: `${x.product!.title} - ${x.title}`,
    url: `https://${x.shop.shop}/admin/products/${product_id}?variant=${product_variant_id}`,
    updated_at: moment(x.updated_at),
    product,
    deleted_at: x.product_variant_view?.deleted_at ? moment(x.product_variant_view.deleted_at) : undefined,
  };
};

const getAllProducts = async (): Promise<Result<ProductVariant[], Error>> => {
  const data = await result((await client()).GetProducts());
  if (data.err) return data;
  return Ok(data.val.product_variants.map(processProductVariant).filter((x): x is ProductVariant => x !== null));
};

const getAllAvailableProductsForSupplier = async (supplier: number): Promise<Result<ProductVariant[], Error>> => {
  const data = await result((await client()).GetAllAvailableProducts({ supplier }));
  if (data.err) return data;
  const out = data.val.suppliers_product_variants_all.map(x => {
    return processProductVariant(x.product_variant!);
  });

  return Ok(out.filter((x): x is ProductVariant => x !== null));
};

function processOrderProduct(x: OrderProductsFieldsFragment): OrderProduct | null {
  const product_variant = processProductVariant(x.product_variant!);

  if (product_variant === null) {
    return null;
  }

  return {
    id: x.id!,
    paid_per_unit: x.paid_per_unit,
    qty_ordered: x.qty_ordered!,
    qty_received: x.qty_received,

    supplier_product_variant: x.supplier_product_variant
      ? {
          code: x.supplier_product_variant.code,
          expected_cost: x.supplier_product_variant.expected_cost,
        }
      : undefined,
    last_paid_per_unit: x.last_unit_price ? x.last_unit_price : 0.0,
    product_variant,
  };
}

async function createOrder(newOrder: NewOrder): Promise<Result<number | undefined, Error>> {
  const auth = await getAuth();
  if (auth.err) return auth;

  if (newOrder.skuLines !== undefined) {
    const vars = {
      location_id: newOrder.locationId,
      supplier_id: newOrder.supplierId,
      populate: {
        populate: newOrder.skuLines,
      },
    };
    const c = await result((await client()).InsertNewOrderPopulateCsv(vars));

    if (c.err) return c;
    return Ok(c.val.create_and_populate_order_supplied.pop()?.id);
  } else {
    const vars = {
      location_id: newOrder.locationId,
      supplier_id: newOrder.supplierId,
      vendors: newOrder.prepopulateOptions.includes("vendor"),
      assigned: newOrder.prepopulateOptions.includes("assigned"),
    };
    const c = await result((await client()).InsertNewOrderPopulateOptions(vars));

    if (c.err) return c;
    return Ok(c.val.create_and_populate_order_options.pop()?.id);
  }
}

async function deleteOrder(id: number): Promise<Result<undefined, Error>> {
  const data = await result((await client()).DeleteOrder({ id }));
  if (data.err) return data;
  return Ok(undefined);
}

async function getOrders(): Promise<Result<Array<Order>, Error>> {
  const ordersRaw = await result((await client()).GetOrders());
  if (ordersRaw.err) {
    return ordersRaw;
  }
  const processed: Array<Order> = ordersRaw.val.orders_view.map(processOrder);
  return Ok(processed);
}

const getOrder = async (id: number): Promise<Result<OrderExtended, Error>> => {
  const data = await result((await client()).GetOrder({ id }));
  if (data.err) return data;
  const order = data.val.orders_view[0];
  if (order === undefined) return Err(Error("order doesnt exist"));
  const processed = processOrder(order);
  const orderExtended: OrderExtended = {
    // ...order,
    ...processed,
    location: order.location!,
    supplier: processSupplier(order.supplier!),
    order_products: order.order_products.map(processOrderProduct).filter((x): x is OrderProduct => x !== null),
  };

  return Ok(orderExtended);
};

const build_stock_stats = (
  data: StockStatsFragment[]
): Record<number, { in_stock: number; orders_in_transfer: number }> => {
  console.log(data);
  return data.reduce<Record<number, { in_stock: number; orders_in_transfer: number }>>((acc, cur) => {
    if (cur.product_variant_id) {
      acc[cur.product_variant_id] = {
        in_stock: undefinedOrNullToNumber(cur.in_stock),
        orders_in_transfer: undefinedOrNullToNumber(cur.orders_in_transfer),
      };
      return acc;
    } else return acc;
  }, {});
};
const getSalesAllLocations = async (
  id: number,
  from_ts: moment.Moment,
  to_ts: moment.Moment
): Promise<Result<ProductStatsRecord, Error>> => {
  let data = await result(
    (
      await client()
    ).GetStatsForOrderProductsAllLocations({
      id,
      from_ts: from_ts.format("YYYY-MM-DD"),
      to_ts: to_ts.format("YYYY-MM-DD"),
    })
  );
  if (data.err) return data;
  const order = data.val.orders_view[0];
  if (order === undefined) return Err(Error("order doesnt exist"));

  const stats = build_stock_stats(data.val.stock_levels_for_order_id);

  const products = order.order_products
    .map(x => {
      return {
        product_variant_id: x.product_variant?.id!,
        stats: {
          sold_qty: undefinedOrNullToNumber(x.sales_aggregate.aggregate?.sum?.quantity),
          in_stock: undefinedOrNullToNumber(stats[x.product_variant?.id!]?.in_stock),
          orders_in_transfer: undefinedOrNullToNumber(stats[x.product_variant?.id!]?.orders_in_transfer),
          available_days: undefinedOrNullToNumber(x.product_variant?.inventory_days_available),
        },
      };
    })
    .reduce<ProductStatsRecord>(
      (acc, val) => ({
        ...acc,
        [val.product_variant_id]: val.stats,
      }),
      {}
    );
  return Ok(products);
};

const getSales = async (
  id: number,
  location_id: number,
  from_ts: moment.Moment,
  to_ts: moment.Moment
): Promise<Result<ProductStatsRecord, Error>> => {
  let data = await result(
    (
      await client()
    ).GetStatsForOrderProducts({
      id,
      sales_location_id: location_id,
      from_ts: from_ts.format("YYYY-MM-DD"),
      to_ts: to_ts.format("YYYY-MM-DD"),
    })
  );
  if (data.err) return data;
  const order = data.val.orders_view[0];
  if (order === undefined) return Err(Error("order doesnt exist"));

  const stats = build_stock_stats(data.val.stock_levels_per_location_for_order_id);
  console.log("build stats", stats);
  const products = order.order_products

    .map(x => {
      return {
        product_variant_id: x.product_variant?.id!,
        stats: {
          sold_qty: undefinedOrNullToNumber(x.sales_aggregate.aggregate?.sum?.quantity),
          in_stock: undefinedOrNullToNumber(stats[x.product_variant?.id!]?.in_stock),
          orders_in_transfer: undefinedOrNullToNumber(stats[x.product_variant?.id!]?.orders_in_transfer),
          available_days: undefinedOrNullToNumber(x.product_variant?.inventory_days_available),
        },
      };
    })
    .reduce<ProductStatsRecord>(
      (acc, val) => ({
        ...acc,
        [val.product_variant_id]: val.stats,
      }),
      {}
    );

  return Ok(products);
};

async function updateOrder(id: number, data: OrderUpdate): Promise<Result<OrderExtra, Error>> {
  const set = {
    location_id: data.locationId,
    notes: data.notes,
    adjustments: data.adjustments,
    status: data.orderStatus,
  };

  const c = await result((await client()).UpdateOrder({ id, set }));

  if (c.err) return c;

  const resp = c.val.update_orders_by_pk!.order_view!;

  return Ok({ ...processOrder(resp), location: resp.location!, supplier: processSupplier(resp.supplier!) });
}

async function updateOrderGerProducts(id: number, data: OrderUpdate): Promise<Result<OrderExtended, Error>> {
  const set = {
    location_id: data.locationId,
    notes: data.notes,
    adjustments: data.adjustments,
    status: data.orderStatus,
  };

  const c = await result((await client()).UpdateOrderGetProducts({ id, set }));

  if (c.err) return c;

  const resp = c.val.update_orders_by_pk!.order_view!;

  return Ok({
    ...processOrder(resp),
    location: resp.location!,
    supplier: processSupplier(resp.supplier!),
    order_products: resp.order_products.map(processOrderProduct).filter((x): x is OrderProduct => x !== null),
  });
}

async function deleteOrderProducts(products: number[]): Promise<Result<undefined, Error>> {
  const data = await result((await client()).DeleteOrderProducts({ products }));
  if (data.err) return data;
  return Ok(undefined);
}

async function upsertBinLocation(binLoc: BinLocation): Promise<Result<BinLocation, Error>> {
  const data = await result((await client()).UpsertBinLocation(binLoc));
  if (data.err) return data;
  return Ok(data.val.insert_bin_locations_one!);
}

export async function deleteOrderProductGetInvoice(
  orderProductId: number,
  invoiceId: number,
  orderId: number
): Promise<Result<InvoiceWithProductsExpanded, Error>> {
  const data = await result((await client()).DeleteOrderProductGetInvoice({ orderProductId, invoiceId, orderId }));
  if (data.err) return data;
  let invoice = data.val.delete_order_products_by_pk?.order.order_invoices.pop()?.invoice_view!;
  let products = invoice.order_products_invoices;
  let expenses = invoice.invoice_expenses.map(processInvoiceExpense);

  return Ok({ ...processInvoice(invoice), expenses, products });
}

async function addOrderProducts(order_id: number, pIds: number[]): Promise<Result<OrderExtended, Error>> {
  let products = pIds.map(product_variant_id => ({ order_id, product_variant_id }));
  const data = await result((await client()).AddOrderProducts({ products }));
  if (data.err) return data;
  return await getOrder(order_id);
}

async function updateOrderProducts(orderId: number, updates: OrderProductUpdate[]): Promise<Result<boolean, Error>> {
  type Var = {
    qty_ordered: number | undefined;
  };
  let vars: { [x: string]: number | Var } = {};
  let mutations: string[] = [];
  let args: string[] = [];

  updates.forEach((x, i) => {
    args.push(`$updateOrderProd${i}: Int!, $updateOrderProdSet${i}: order_products_set_input!`);
    mutations.push(
      `uop${i}: update_order_products(where: {id: {_eq: $updateOrderProd${i}}}, _set: $updateOrderProdSet${i}) { affected_rows }`
    );
    vars[`updateOrderProd${i}`] = x.id;
    let update = {
      qty_ordered: x.qty_ordered !== undefined ? toWhole(x.qty_ordered) : undefined,
    };
    vars[`updateOrderProdSet${i}`] = update;
  });

  let argsRendered = args.join(", ");
  if (args.length > 0) argsRendered += ",";
  let mutationsRendered = mutations.join("   ");

  const q = gql`
    mutation OrderProductsUpdate (
      ${argsRendered}
    ) {
      ${mutationsRendered}
    }

  `;

  const res = await request(q, vars);
  if (res.err) return res;
  return Ok(true);
}

export {
  ProductVariant,
  ProductVariantSupplier,
  processProductVariant,
  updateOrderProducts,
  OrderProductUpdate,
  addOrderProducts,
  deleteOrderProducts,
  getAllProducts,
  getAllAvailableProductsForSupplier,
  getOrder,
  getOrders,
  createOrder,
  deleteOrder,
  OrderProduct,
  OrderExtended,
  Order,
  NewOrder,
  ProductVariantInfoWithSupplierCode,
  OrderStatus,
  OrderStatusUpdate,
  Location,
  OrderUpdate,
  updateOrder,
  updateOrderGerProducts,
  OrderExtra,
  processOrder,
  processSupplier,
  CsvOrderRow,
  processOrderProduct,
  getSales,
  ProductStatsRecord,
  PrepopulateOption,
  getSalesAllLocations,
  upsertBinLocation,
};
