import React, { useState, useCallback } from 'react'
import { debounce } from 'throttle-debounce'

import { Input } from './InputTs'

type Props<T> = {
  className: string,
  id: string,
  placeholder: string,
  label?: string,
  getResults: (q: string | undefined) => T[]
  renderResult: (x: T) => JSX.Element
}

const AutoComplete = <T extends unknown>({ className, getResults, id, label, placeholder, renderResult } : Props<T> ) => {
  const [results, setResults] = useState(getResults(undefined))
  const [query, setQuery] = useState('')
  const [queried, setQueried] = useState(false)

  const handleGetResults = useCallback(
    debounce(200, (q: string) => {
      const results = getResults(q);
      setResults(results)
      if (!queried) setQueried(true)
    }),
    [],
  )

  function onChange(q: string) {
    setQuery(q)
    handleGetResults(q.toString().trim().toLowerCase())
  }

  return (
    <div className={`pb-64 ${className}`}>
      <Input
        id={id}
        className="w-full"
        label={label}
        onChange={q => onChange(q)}
        placeholder={placeholder}
        value={query}
      />
      <div className="mt-4 relative">
        <div className="absolute top-0 left-0 w-full h-64 overflow-y-auto">
          {results && results.map(renderResult)}
          {queried && !results.length && <p>No results found.</p>}
        </div>
      </div>
    </div>
  )
}

AutoComplete.displayName = 'AutoComplete'

export { AutoComplete }
