import React from "react";
import PropTypes from "prop-types";
import { Link } from "@reach/router";

type Props = {
  onClick?: () => void;
  className?: string;
  [x: string]: any;
};

const TableRow = ({ className, onClick, ...props }: Props) => (
  <tr
    className={`border-b border-gray-100 ${onClick ? "hover:bg-gray-100 cursor-pointer" : ""} ${className}`}
    onClick={onClick}
    {...props}
  />
);

TableRow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

TableRow.defaultProps = {
  className: "",
  onClick: null,
};

export { TableRow };
