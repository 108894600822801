import { useEffect, useState, useRef } from 'react'
import { getLocations, Location } from '../backend-v3/locations'
import { useFeedback } from './useFeedbackTs'

const useLocations = () => {
  const isMounted = useRef(true)
  const [isFetchingLocations, setIsFetchingLocations] = useState(false);
  const [locations, setLocations] = useState([] as Location[]);
  const { setError } = useFeedback()

  useEffect(() => {
    getData()

    return () => {
      isMounted.current = false
    }
  }, [])

  async function getData() {
    setIsFetchingLocations(true)
    const res = await getLocations()
    if (!isMounted.current) return
    if (res.err) setError(res.val.message)
    else {
      setLocations(res.val)
    }
    setIsFetchingLocations(false)
  }
  return { locations, isFetchingLocations }
}

export { useLocations }
