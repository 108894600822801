import React, { useEffect } from "react";
import { FaTrash } from "react-icons/fa";

import moment from "moment";
import { Checkbox } from "../../Checkbox";
import { Table } from "../../TableTs";
import { TableCell } from "../../TableCellTs";
import { TableHeadCell } from "../../TableHeadCell";
import { TableHeadRow } from "../../TableHeadRow";
import { TableRow } from "../../TableRowTs";
import { TableRowActions } from "../../TableRowActions";

import { roundToTwo } from "../../../helpersTs";
import { useSupplierCode } from "../../../hooks/useSupplierCodeTs";
import { OrderExtended, ProductStatsRecord } from "../../../backend-v3/orders";
import { OrderProductUpdate, StatsQuery } from "../../../hooks/useOrderTs";
import { SupplierCodeData } from "../../../backend-v3/suppliers";
import { InvoiceWithProductsExpanded, UpdateOrderProductInvoice } from "../../../backend-v3/invoices";
import { Mapped, mapProducts } from "../../../hooks/useOrderTs";
import { OrderProductsInvoiceFragment } from "../../../generated/graphql";
import roundTo from "round-to";

type Props = {
  binLocationsActive: boolean;
  isEditing?: boolean;
  isFetchingPosition?: boolean;
  order: OrderExtended;
  updateOrderProduct: (data: OrderProductUpdate) => void;
  handleDeleteProduct: (opId: number) => void;
  deleteOrderProduct: (opId: number) => void;
  deleteOrderProducts: (opId: number[]) => void;
  currentInvoice?: InvoiceWithProductsExpanded;
  orderRefresh: boolean;
  setOrderRefresh: (referesh: boolean) => void;
  refreshOrder: () => void;
  productStats: ProductStatsRecord;
  statsQuery: StatsQuery | undefined;
  updateBinLocation: (pvId: number, bin: string) => void;
  upsertInvoiceProducts: (
    invoiceId: number,
    update: UpdateOrderProductInvoice,
    type: "paid_per_unit" | "qty_received" | "both"
  ) => Promise<void>;
};

const OrderTable = ({
  deleteOrderProduct,
  deleteOrderProducts,
  handleDeleteProduct,
  isEditing = false,
  isFetchingPosition = false,
  order,
  updateOrderProduct,
  currentInvoice,
  upsertInvoiceProducts,
  orderRefresh,
  setOrderRefresh,
  refreshOrder,
  productStats,
  statsQuery,
  binLocationsActive,
  updateBinLocation,
}: Props) => {
  const { updateSupplierCode } = useSupplierCode();

  const mappedProducts: Mapped | undefined = mapProducts(currentInvoice?.products);

  const isDraft = order.status === "draft";
  const isSent = order.status === "sent";
  const isPartial = order.status === "partially_received";
  const isReceived = order.status === "received";

  function handleSupplierCodeChange(data: SupplierCodeData, typ: "code" | "cost") {
    return updateSupplierCode(data, typ);
  }

  // if there were made changes to the invoices we need to refresh order data to get proper amounts
  useEffect(() => {
    if (order && currentInvoice === undefined && orderRefresh) {
      refreshOrder();
      setOrderRefresh(false);
    }
  }, [order, currentInvoice]);

  return (
    order && (
      <Table
        bulkAction={isDraft ? deleteOrderProducts : undefined}
        childIds={order.order_products.map(({ id }) => id)}
        className="mt-6 overflow-hidden"
        renderHead={({ bulkChecked, onBulkChange }) => (
          <TableHeadRow>
            {isDraft && (
              <TableHeadCell
                slim
                width="4"
                value={
                  <Checkbox
                    disabled={!order.order_products.length}
                    checked={bulkChecked.length >= order.order_products.length}
                    onChange={() => onBulkChange("all", bulkChecked.length >= order.order_products.length)}
                  />
                }
              />
            )}
            <TableHeadCell value="Product" width="1/3" />
            {!isDraft && binLocationsActive && <TableHeadCell value="Bin Location" />}
            <TableHeadCell value="SKU" width="32" />
            <TableHeadCell value="Supplier Code" width="32" />
            {isDraft && (
              <>
                <TableHeadCell value="In Stock" />
                <TableHeadCell value="On Order" />
                <TableHeadCell value="Sold" />
                <TableHeadCell value="% Days In Stock" />
              </>
            )}
            <TableHeadCell value={`Qty${!isDraft ? " Ordered" : ""}`} />
            {!isDraft && !isReceived && currentInvoice && <TableHeadCell value="Prev. Received" />}
            {!isDraft && <TableHeadCell value="Qty Received" />}
            <TableHeadCell value="Expected $" />
            <TableHeadCell value="Prev $" />
            {!isDraft && <TableHeadCell value="Paid $" />}
            {isDraft ? <TableHeadCell value="Estimate $" /> : <TableHeadCell value="Total $" />}
            {(isDraft || isSent || isPartial) && <TableHeadCell alignment="right" width="32" value="Actions" />}
          </TableHeadRow>
        )}
        renderBody={({ bulkChecked, onBulkChange }) => (
          <>
            {order.order_products.map(
              ({
                last_paid_per_unit,
                id,
                supplier_product_variant,
                product_variant,
                paid_per_unit,
                qty_ordered,
                qty_received,
              }) => {
                const stats = productStats[product_variant.id];

                const invoiceProduct = mappedProducts ? mappedProducts[product_variant.id] : undefined;

                const receivedOnOtherInvoices = (qty_received ?? 0) - (invoiceProduct?.qty_received ?? 0);
                const stillToReceive = Math.max(
                  (invoiceProduct?.qty_received ?? 0) + (qty_ordered - (qty_received ?? 0)),
                  Math.max(qty_ordered - receivedOnOtherInvoices, 0)
                );

                const percentageDaysAvailable =
                  statsQuery && isDraft
                    ? roundTo(
                        ((stats?.available_days ?? 0) / (statsQuery.date.to.diff(statsQuery.date.from, "days") + 1)) *
                          100,
                        0
                      )
                    : undefined;

                const binLocation = product_variant.bin_locations_record[order.location_id]?.name ?? "";

                return (
                  <TableRow key={id}>
                    {isDraft && (
                      <TableCell
                        slim
                        width="4"
                        value={{
                          type: "element",
                          initial: (
                            <Checkbox
                              checked={bulkChecked.includes(id)}
                              onChange={() => onBulkChange(id, bulkChecked.includes(id))}
                              tabIndex="-1"
                            />
                          ),
                        }}
                      />
                    )}
                    <TableCell
                      width="1/3"
                      value={{ type: "string", initial: product_variant.full_title }}
                      url={product_variant.url}
                      tabIndex={-1}
                    />

                    {!isDraft && binLocationsActive && (
                      <TableCell
                        onChange={currentInvoice ? name => updateBinLocation(product_variant.id, name) : undefined}
                        width="32"
                        capitalize={false}
                        value={{ type: "string", initial: binLocation }}
                      />
                    )}
                    <TableCell width="32" capitalize={false} value={{ type: "string", initial: product_variant.sku }} />

                    <TableCell
                      onChange={value =>
                        handleSupplierCodeChange(
                          {
                            supplier_id: order.supplier_id,
                            product_variant_id: product_variant.id,
                            code: value,
                          },
                          "code"
                        )
                      }
                      width="32"
                      tabIndex={-1}
                      value={{
                        type: "string",
                        initial: supplier_product_variant?.code ? supplier_product_variant?.code : undefined,
                      }}
                    />

                    {isDraft && (
                      <>
                        <TableCell
                          loading={isFetchingPosition}
                          value={{ type: "number", initial: stats?.in_stock ?? 0 }}
                        />
                        <TableCell
                          loading={isFetchingPosition}
                          value={{ type: "number", initial: stats?.orders_in_transfer ?? 0 }}
                        />
                        <TableCell
                          loading={isFetchingPosition}
                          value={{ type: "number", initial: stats?.sold_qty ?? 0 }}
                        />
                        <TableCell
                          loading={isFetchingPosition}
                          value={{ type: "string", initial: `${percentageDaysAvailable ?? 0}%` }}
                        />
                      </>
                    )}
                    <TableCell
                      key={`${id}-${qty_ordered}`}
                      onChange={
                        isDraft
                          ? value =>
                              updateOrderProduct({
                                id,
                                qty_ordered: Number(value),
                              })
                          : undefined
                      }
                      value={{ type: "number", initial: qty_ordered, positiveOnly: true }}
                    />

                    {/* prev received */}

                    {!isDraft && !isReceived && currentInvoice && (
                      <TableCell
                        loading={isFetchingPosition}
                        value={{
                          type: "element",
                          initial: (
                            <div className="flex justify-between">
                              {/* todo */}
                              <span>{receivedOnOtherInvoices}</span>
                              {currentInvoice && (
                                <button
                                  className="px-1 mr-1 border border-black"
                                  title="Fill quantity"
                                  onClick={() => {
                                    upsertInvoiceProducts(
                                      currentInvoice.id,
                                      {
                                        product_variant_id: product_variant.id,
                                        qty_received: stillToReceive,
                                        paid_per_unit: invoiceProduct?.paid_per_unit ?? 0,
                                      },
                                      "qty_received"
                                    );
                                  }}
                                >
                                  &#8594;
                                </button>
                              )}
                            </div>
                          ),
                        }}
                      />
                    )}

                    {/* qty received */}
                    {!isDraft && (
                      <TableCell
                        onChange={
                          !isReceived && currentInvoice
                            ? value => {
                                upsertInvoiceProducts(
                                  currentInvoice.id,
                                  {
                                    product_variant_id: product_variant.id,
                                    qty_received: Number(value),
                                    paid_per_unit: invoiceProduct?.paid_per_unit ?? 0,
                                  },
                                  "qty_received"
                                );
                              }
                            : undefined
                        }
                        cacheDeps={[currentInvoice?.id]}
                        value={{
                          type: "number",
                          initial: currentInvoice ? invoiceProduct?.qty_received ?? 0 : qty_received ?? 0,
                        }}
                      />
                    )}

                    {/* Expected price */}
                    <TableCell
                      onChange={value => {
                        let cost: null | number = Number(value);
                        if (cost === 0) {
                          cost = null;
                        }
                        handleSupplierCodeChange(
                          {
                            supplier_id: order.supplier_id,
                            product_variant_id: product_variant.id,
                            expected_cost: cost,
                          },
                          "cost"
                        );
                      }}
                      flagged={
                        invoiceProduct !== undefined &&
                        supplier_product_variant?.expected_cost !== undefined &&
                        supplier_product_variant.expected_cost !== null &&
                        supplier_product_variant.expected_cost !== invoiceProduct.paid_per_unit
                      }
                      value={{
                        type: "number",
                        nullable: true,
                        initial: supplier_product_variant?.expected_cost
                          ? supplier_product_variant?.expected_cost
                          : undefined,
                        positiveOnly: true,
                      }}
                    />

                    <TableCell
                      value={
                        !isDraft && !isReceived
                          ? {
                              type: "element",
                              initial: (
                                <div className="flex justify-between">
                                  <span>{roundToTwo(last_paid_per_unit)}</span>

                                  {currentInvoice && (
                                    <button
                                      className="px-1 mr-1 border border-black"
                                      title="Fill price"
                                      onClick={() =>
                                        !isReceived
                                          ? upsertInvoiceProducts(
                                              currentInvoice.id,
                                              {
                                                product_variant_id: product_variant.id,
                                                paid_per_unit: Number(roundToTwo(last_paid_per_unit)),
                                                qty_received: invoiceProduct?.qty_received ?? 0,
                                              },
                                              "paid_per_unit"
                                            )
                                          : undefined
                                      }
                                    >
                                      &#8594;
                                    </button>
                                  )}
                                </div>
                              ),
                            }
                          : { type: "string", initial: roundToTwo(last_paid_per_unit) }
                      }
                    />

                    {/* Paid */}
                    {!isDraft && (
                      <TableCell
                        onChange={
                          !isReceived && currentInvoice
                            ? value =>
                                upsertInvoiceProducts(
                                  currentInvoice.id,
                                  {
                                    product_variant_id: product_variant.id,
                                    paid_per_unit: Number(value),
                                    qty_received: invoiceProduct?.qty_received ?? 0,
                                  },
                                  "paid_per_unit"
                                )
                            : undefined
                        }
                        cacheDeps={[currentInvoice?.id]}
                        value={{
                          type: "number",
                          initial: Number(
                            roundToTwo(
                              currentInvoice
                                ? invoiceProduct
                                  ? invoiceProduct.paid_per_unit ?? 0
                                  : 0
                                : paid_per_unit ?? 0
                            )
                          ),
                          positiveOnly: true,
                        }}
                      />
                    )}
                    {/* Total */}
                    {isDraft ? (
                      <TableCell
                        value={{
                          type: "number",
                          initial: currentInvoice
                            ? invoiceProduct
                              ? Number(roundToTwo(last_paid_per_unit * invoiceProduct.qty_received))
                              : 0
                            : Number(roundToTwo(last_paid_per_unit * qty_ordered)),
                        }}
                      />
                    ) : (
                      <TableCell
                        width="32"
                        value={{
                          type: "number",
                          initial: currentInvoice
                            ? invoiceProduct
                              ? Number(
                                  roundToTwo((invoiceProduct.paid_per_unit ?? 0) * invoiceProduct.qty_received ?? 0)
                                )
                              : 0
                            : Number(roundToTwo((paid_per_unit ?? 0) * (qty_received ?? 0))),
                        }}
                      />
                    )}
                    {(isDraft || isSent || isPartial) && (
                      <TableRowActions
                        actions={[
                          {
                            id: "delete",
                            iconBefore: <FaTrash />,
                            onClick: () => handleDeleteProduct(id),
                            tabIndex: "-1",
                          },
                        ]}
                      />
                    )}
                  </TableRow>
                );
              }
            )}
          </>
        )}
      />
    )
  );
};

OrderTable.displayName = "OrderTable";

export { OrderTable };
