import { Result, Ok } from "ts-results";
import { client, result } from "./helpers";

export type Bundle = {
  product_variant_id_parent: number;
  product_variant_id_child: number;
  ratio: number;
};

export async function upsertBundle(bundles: Bundle[]): Promise<Result<Bundle[], Error>> {
  const data = await result((await client()).UpsertBundle({ objects: bundles }));
  if (data.err) return data;
  return Ok(data.val.insert_product_variants_bundle!.returning);
}

export async function deleteBundle(child: { product_variant_id_child: number }): Promise<Result<undefined, Error>> {
  const data = await result((await client()).DeleteBundle(child));
  if (data.err) return data;
  return Ok(undefined);
}

export async function getBundles(): Promise<Result<Bundle[], Error>> {
  const data = await result((await client()).Bundles());
  if (data.err) return data;
  return Ok(data.val.product_variants_bundle);
}
