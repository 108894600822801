import React from 'react'
import PropTypes from 'prop-types'
import { FaPaperPlane } from 'react-icons/fa'

import { Modal } from '../../Modal'

const StocktakeCompleteModal = ({ errors, isOpen, loading, onClose, onSubmit }) => {
  return (
    <Modal
      closeModal={onClose}
      disabled={errors ? true : false}
      heading="Complete Stocktake &amp; Sync Changes with Shopify"
      loading={loading}
      isOpen={isOpen}
      onSubmit={() => onSubmit()}
      submitIcon={<FaPaperPlane />}
      submitValue="Submit"
    >
      <div className="mt-4">
        <p>
          This will change your stocktake status to <code className="text-orange">Counted</code> and sync stock levels
          with Shopify.
        </p>
      </div>
      {errors && (
        <div className="text-sm mt-4 p-4 bg-orange-100 rounded border border-orange overflow-y-auto max-h-1/2">
          <p className="font-bold mb-2">Please fix the following first:</p>
          {errors.map(error => (
            <p key={error} dangerouslySetInnerHTML={{ __html: error }} />
          ))}
        </div>
      )}
    </Modal>
  )
}

StocktakeCompleteModal.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  isOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

StocktakeCompleteModal.defaultProps = {
  errors: null,
  isOpen: false,
  loading: false,
}

export { StocktakeCompleteModal }
