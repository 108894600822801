import * as moment from "moment";
import { Result, Ok, Err } from "ts-results";
import { getAuth } from "./auth";
import { request, client, result } from "./helpers";
import { processProductVariant, ProductVariant } from "./orders";

import { TransferFieldsFragment, TransferProductFieldsFragment } from "../generated/graphql";

type TransferStatus = "draft" | "requested" | "fulfilled" | "done";

type TransferUpdate = {
  status?: TransferStatus;
};

type TransferProduct = {
  id: number;
  product_variant_id: number;
  qty_requested: number;
  qty_fulfilled: number;
  qty_received: number;
  created_at: moment.Moment;
  stock_at_origin: number;
  stock_at_destination: number;
  product_variant: ProductVariant;
  orders_in_transfer: number;
};

type TransferProductUpdate = {
  qty_requested?: number;
  qty_fulfilled?: number;
  qty_received?: number;
};

type Transfer = {
  id: number;
  transfer_number: number;
  from_location_id: number;
  to_location_id: number;
  status: TransferStatus;
  created_at: moment.Moment;
  updated_at: moment.Moment;
  total: number;
};

type TransferExtended = {
  transfer: Transfer;
  products: (TransferProduct & { sales_aggregate?: number | null; inventory_days_available?: number | null })[];
};

type TransferCreate = {
  from_location_id: number;
  to_location_id: number;
};

const processTransfer = (o: TransferFieldsFragment): Transfer => {
  // fields are non-nullable -- the issue stems from hasura misidentifying sql view columns as nullable

  return {
    id: o.id!,
    transfer_number: o.transfer_number!,
    from_location_id: o.from_location_id!,
    to_location_id: o.to_location_id!,
    status: o.status,
    created_at: moment(o.created_at),
    updated_at: moment(o.updated_at),
    total: o.total,
  };
};

const processTransferProduct = (p: TransferProductFieldsFragment): TransferProduct | null => {
  const pv = processProductVariant(p.product_variant!);

  if (pv === null) {
    return null;
  }

  return {
    id: p.id!,
    product_variant_id: p.product_variant_id!,
    qty_requested: p.qty_requested!,
    qty_fulfilled: p.qty_fulfilled!,
    qty_received: p.qty_received!,
    created_at: p.created_at!,
    stock_at_origin: p.stock_at_origin!,
    stock_at_destination: p.stock_at_destination!,
    product_variant: pv,
    orders_in_transfer: p.orders_in_transfer!,
  };
};

async function getTransfers(): Promise<Result<Transfer[], Error>> {
  const transfers = await result((await client()).Transfers());
  if (transfers.err) return transfers;
  return Ok(transfers.val.transfers_view.map(processTransfer));
}

async function getTransfer(id: number): Promise<Result<TransferExtended, Error>> {
  const t = await result((await client()).Transfer({ id }));
  if (t.err) return t;
  const transfer = t.val.transfers_view[0];
  if (transfer === undefined) return Err(Error("transfer doesn't exist"));
  const extra = await result(
    (
      await client()
    ).TransferProductsExtra({
      id,
      from_ts: moment.utc().subtract(90, "days").toISOString(),
      to_ts: moment.utc().toISOString(),
      location_id: transfer.to_location_id!,
    })
  );

  let products = transfer.transfer_products.map(processTransferProduct).filter((x): x is TransferProduct => x !== null);

  let processedTransfer = processTransfer(transfer);

  if (extra.err) {
    return Ok({
      transfer: processedTransfer,
      products,
    });
  }
  const extraView = extra.val.transfers_view[0];

  if (extraView === undefined)
    return Ok({
      transfer: processedTransfer,
      products,
    });

  const withExtra = products.map(p => {
    const extraProduct = extraView.transfer_products.find(tp => tp.product_variant_id === p.product_variant_id);
    if (extraProduct) {
      const pv = extraProduct.product_variant;
      return {
        ...p,
        sales_aggregate: pv?.sales_aggregate.aggregate?.sum?.quantity,
        inventory_days_available: pv?.inventory_days_available,
      };
    } else return p;
  });

  return Ok({
    transfer: processedTransfer,
    products: withExtra,
  });
}

async function deleteTransfer(id: number): Promise<Result<undefined, Error>> {
  let c = await result((await client()).DeleteTransfer({ id }));
  if (c.err) return c;
  return Ok(undefined);
}

async function createTransfer(transfer: TransferCreate): Promise<Result<number, Error>> {
  const auth = await getAuth();
  if (auth.err) return auth;

  let c = await result(
    (
      await client()
    ).CreateTransfer({
      from_location_id: transfer.from_location_id,
      to_location_id: transfer.to_location_id,
      shop_id: Number(auth.val.user.shop!.id),
    })
  );

  if (c.err) return c;

  if (c.val.insert_transfers_one === null || c.val.insert_transfers_one === undefined) return Err(Error("empty id"));
  return Ok(c.val.insert_transfers_one.id);
}

async function updateTransfer(transfer_id: number, transfer: TransferUpdate): Promise<Result<Transfer, Error>> {
  const data = await result(
    (
      await client()
    ).UpdateTransfer({
      transfer_id,
      set: transfer,
    })
  );

  if (data.err) return data;

  return Ok(processTransfer(data.val.update_transfers_by_pk!.transfer_view!));
}

async function addTransferProducts(transfer_id: number, pIds: number[]): Promise<Result<TransferExtended, Error>> {
  let products = pIds.map(product_variant_id => ({ transfer_id, product_variant_id }));
  let c = await result((await client()).AddTransferProducts({ products }));
  if (c.err) return c;
  return await getTransfer(transfer_id);
}

async function updateTransferProduct(
  id: number,
  update: TransferProductUpdate
): Promise<Result<TransferProduct, Error>> {
  const data = await result((await client()).UpdateTransferProduct({ id, update }));
  if (data.err) return data;
  return Ok(processTransferProduct(data.val.update_transfer_products_by_pk!.transfer_product_view!)!);
}

async function deleteTransferProducts(products: number[]): Promise<Result<undefined, Error>> {
  const c = await result((await client()).DeleteTransferProducts({ products }));
  if (c.err) return c;
  return Ok(undefined);
}

export {
  deleteTransferProducts,
  updateTransferProduct,
  addTransferProducts,
  createTransfer,
  getTransfers,
  getTransfer,
  deleteTransfer,
  updateTransfer,
  TransferProductUpdate,
  TransferProduct,
  Transfer,
  TransferStatus,
  TransferCreate,
  TransferExtended,
};
