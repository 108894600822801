import React, { ChangeEvent, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "@reach/router";
import { FaPlus, FaExclamationTriangle } from "react-icons/fa";

import { Dropdown } from "../../DropdownTs";
import { Modal } from "../../Modal";

import { CONSTANTS } from "../../../common/constants";
import { PROP_TYPES } from "../../../common/propTypes";
import { Location } from "../../../backend-v3/locations";
import { CsvOrderRow, NewOrder, PrepopulateOption } from "../../../backend-v3/orders";
import { Supplier, SupplierWithoutVendors } from "../../../backend-v3/suppliers";
import { modalState } from "../../../pages/Orders";
import { Button } from "../../ButtonTs";
import papaparse from "papaparse";

import { styled } from "@stitches/react";
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";
import { Radio } from "../../Radio";
import { UploadCsvProductsWithQty } from "../../UploadCsvProductsWithQty";
import { Checkbox } from "../../CheckboxTs";

type Props = {
  isOpen: boolean;
  loading: boolean;
  locations: Location[];
  onClose: (x: modalState) => void;
  onSubmit: (x: NewOrder) => void;
  suppliers: Supplier[];
};

const CreateOrderModal = ({ isOpen, loading, locations, onClose, onSubmit, suppliers }: Props) => {
  const [location, setLocation] = useState(locations[0]?.id);
  const [supplier, setSupplier] = useState(suppliers[0]?.id);
  const [prepopulateOption, setPrepopulateOption] = useState<PrepopulateOption[]>(["vendor"]);
  const [csvData, setCsvData] = useState<CsvOrderRow[]>([]);
  return (
    <Modal
      closeModal={onClose}
      disabled={!supplier || !location}
      loading={loading}
      heading="New Purchase Order"
      isOpen={isOpen}
      onSubmit={() => {
        if (location && supplier) {
          onSubmit({
            locationId: location,
            supplierId: supplier,
            skuLines: csvData.length > 0 ? csvData : undefined,
            prepopulateOptions: prepopulateOption,
          });
        }
      }}
      submitIcon={<FaPlus />}
      submitValue="Create"
    >
      <Dropdown
        className="mt-6"
        id="new-order-supplier"
        label="Supplier"
        onChange={x => {
          setSupplier(Number(x));
        }}
        options={suppliers.map(({ id: value, name }) => ({
          name,
          value: value.toString(),
        }))}
        value={supplier?.toString()}
      />

      <Dropdown
        className="mt-4"
        id="new-order-location"
        label="Delivery Location"
        onChange={x => setLocation(Number(x))}
        options={locations.map(({ id: value, name }) => ({ value: value.toString(), name: name }))}
        value={location?.toString()}
      />

      {csvData.length === 0 && (
        <Checkbox
          className="mt-4"
          label="Populate order"
          onChange={(option, add) =>
            setPrepopulateOption(options => {
              if (add) {
                return [...options, option];
              } else {
                return options.filter(x => x !== option);
              }
            })
          }
          selected={prepopulateOption}
          options={[
            { id: "vendor", title: "Supplier's vendors' products" },
            {
              id: "assigned",
              title: (
                <>
                  Supplier's{" "}
                  <Link className="underline text-black" to={`/suppliers/${supplier}?tab=products`}>
                    manually assigned
                  </Link>{" "}
                  products
                </>
              ),
            },
          ]}
        />
      )}

      {<UploadCsvProductsWithQty csvData={csvData} setCsvData={setCsvData} />}
    </Modal>
  );
};

export { CreateOrderModal };
