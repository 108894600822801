import React, { ReactElement } from "react";
import PropTypes from "prop-types";
import { FaAngleDown } from "react-icons/fa";

type Props<T> = {
  className?: string;
  label: string;
  onChange: (x: T, add: boolean) => void;
  options: { id: T; title: string | ReactElement }[];
  selected: T[];
};

const Checkbox = <T extends string>({ className = "", label, onChange, options, selected }: Props<T>) => {
  return (
    <div className={className}>
      <label className="font-bold text-sm">{label}</label>
      <fieldset className="mt-2">
        <div className="space-y-2">
          {options.map(option => {
            const checked = selected.includes(option.id);
            return (
              <div key={(option.id as unknown) as string} className="flex items-center">
                {/* <input type="checkbox" checked={checked} onChange={onChange} {...props} /> */}
                <input
                  id={(option.id as unknown) as string}
                  type="checkbox"
                  value={(option.id as unknown) as string}
                  // defaultChecked={def === option.id}
                  checked={checked}
                  onChange={(e: any) => onChange(option.id, !checked)}
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                />
                <label htmlFor={(option.id as unknown) as string} className="ml-3 block text-sm font-medium ">
                  {option.title}
                </label>
              </div>
            );
          })}
        </div>
      </fieldset>
    </div>
  );
};

export { Checkbox };
