import React, { useState } from "react";
import PropTypes from "prop-types";
import { FaPlus } from "react-icons/fa";

import { Input } from "../../InputTs";
import { Modal } from "../../Modal";
import { NewExpense } from "../../../backend-v3/invoices";

type Props = {
  isOpen: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: (newExpense: NewExpense) => void;
};

const CreateExpenseModal = ({ isOpen, loading, onClose, onSubmit }: Props) => {
  const [description, setDescriptionValue] = useState("");
  const [dollars, setDollarValue] = useState(0);
  const [cents, setCentValue] = useState("00");

  return (
    <Modal
      closeModal={onClose}
      disabled={!description || (!dollars && !cents)}
      heading="Add Fixed Expense"
      loading={loading}
      isOpen={isOpen}
      onSubmit={() => onSubmit({ description, dollars, cents })}
      submitIcon={<FaPlus />}
      submitValue="Add"
    >
      <Input
        className="w-64 mt-6"
        id="expense-description"
        label="Description"
        onChange={setDescriptionValue}
        value={description}
      />
      <h3 className="mt-4 font-bold text-sm">Amount</h3>
      <div className="mt-2 flex">
        <div className="flex items-center">
          <span className="inline-block mr-2 font-bold text-lg pt-1">$</span>
          <Input
            alignText="right"
            className="w-16"
            id="expense-dollars"
            onChange={(x: string) => setDollarValue(Number(x))}
            // todo: what is the proper type of e?
            onClick={(e: React.MouseEvent<HTMLInputElement>) => (e.target as HTMLInputElement).select()}
            type="number"
            value={dollars.toString()}
          />
        </div>
        <div className="flex items-center">
          <span className="inline-block font-bold mr-2 text-lg">.</span>
          <Input
            alignText="right"
            className="w-12 text-right"
            id="expense-cents"
            onChange={setCentValue}
            onClick={e => (e.target as HTMLInputElement).select()}
            value={cents}
          />
        </div>
      </div>
    </Modal>
  );
};

export { CreateExpenseModal };
