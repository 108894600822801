import { ChangeEvent, useRef, useState } from "react";
import { CsvOrderRow } from "../backend-v3/orders";
import { Button } from "./ButtonTs";
import { styled } from "@stitches/react";
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";
import papaparse from "papaparse";

const SCROLLBAR_SIZE = 10;

const StyledScrollArea = styled(ScrollAreaPrimitive.Root, {
  height: 150,
  borderRadius: 4,
  overflow: "hidden",
});

const StyledViewport = styled(ScrollAreaPrimitive.Viewport, {
  width: "100%",
  height: "100%",
  borderRadius: "inherit",
  border: "1px solid #cecece",
});

const StyledScrollbar = styled(ScrollAreaPrimitive.Scrollbar, {
  display: "flex",
  // ensures no selection
  userSelect: "none",
  // disable browser handling of all panning and zooming gestures on touch devices
  touchAction: "none",
  padding: 2,
  // background: blackA.blackA6,
  transition: "background 160ms ease-out",
  // "&:hover": { background: blackA.blackA8 },
  '&[data-orientation="vertical"]': { width: SCROLLBAR_SIZE },
  '&[data-orientation="horizontal"]': {
    flexDirection: "column",
    height: SCROLLBAR_SIZE,
  },
});

const StyledThumb = styled(ScrollAreaPrimitive.Thumb, {
  flex: 1,
  background: "#000",
  borderRadius: SCROLLBAR_SIZE,
  // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    minWidth: 44,
    minHeight: 44,
  },
});

const StyledCorner = styled(ScrollAreaPrimitive.Corner, {
  // background: blackA.blackA8,
});

export const ScrollArea = StyledScrollArea;
export const ScrollAreaViewport = StyledViewport;
export const ScrollAreaScrollbar = StyledScrollbar;
export const ScrollAreaThumb = StyledThumb;
export const ScrollAreaCorner = StyledCorner;

const Box = styled("div", {});

type Props = {
  csvData: { sku: string; code: string | null }[];
  setCsvData: (x: { sku: string; code: string | null }[]) => void;
};

export const UploadCsvProducts = ({ csvData, setCsvData }: Props) => {
  // todo replace UploadCsvProducts and UploadCsvProductsWithQtyOrdered with a generic csv parser
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [fileStatus, setFileStatus] = useState<"added" | "processing" | "empty">("empty");

  const processFileAdd = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length !== undefined && event.target.files?.length > 0) {
      const file = event.target.files[0];
      if (file) {
        setFileStatus("processing");
        papaparse.parse(file, {
          complete: results => {
            const parsed: { sku: string; code: string | null }[] = [];

            results.data.forEach((row: any, i) => {
              const sku = row[0];
              const supplierCodeString = row[1];
              const code =
                supplierCodeString === undefined || supplierCodeString === null || supplierCodeString === ""
                  ? null
                  : supplierCodeString;

              if (sku !== undefined && sku !== null && sku !== "") {
                parsed.push({
                  sku,
                  code,
                });
              }
            });
            setCsvData(parsed);
            setFileStatus("added");
          },
        });
      }
    }
  };

  return (
    <div className="mt-4">
      <div className="relative mt-1">
        <div className="flex">
          <Button
            onClick={() => {
              if (inputFile.current) {
                inputFile.current.value = "";
                inputFile.current.click();
              }
            }}
            value={fileStatus === "added" ? "Choose another file" : "Choose file"}
            variant="ghost"
          />
          {fileStatus === "added" && (
            <Button
              onClick={() => {
                setFileStatus("empty");
                setCsvData([]);
              }}
              value="Clear"
              variant="warning"
              className="ml-2"
              size="small"
            />
          )}
          {fileStatus === "processing" && <span className="mt-2 ml-2 font-bold text-md">Processing...</span>}
        </div>

        <p className="text-sm text-orange flex items-center mt-4">
          <span className="">CSV file needs to have SKU and Supplier Code columns. Supplier code is optional.</span>
        </p>
        <input
          accept="text/csv"
          onChange={processFileAdd}
          ref={inputFile}
          id="order"
          name="order"
          type="file"
          className="hidden"
        />
      </div>
      {csvData.length > 0 && (
        <div className="mt-4">
          <span>Products: {csvData.length}</span>
          <ScrollArea className="mt-2">
            <ScrollAreaViewport css={{ backgroundColor: "white" }}>
              <Box style={{ padding: "15px 20px" }}>
                <table className="w-full">
                  <thead>
                    <tr>
                      <td className="w-2/3">SKU</td>
                      <td>Supplier Code</td>
                    </tr>
                  </thead>
                  <tbody>
                    {csvData.map(row => (
                      <tr>
                        <td className="w-1/3">{row.sku}</td>
                        <td>{row.code}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
            </ScrollAreaViewport>
            <ScrollAreaScrollbar orientation="vertical">
              <ScrollAreaThumb />
            </ScrollAreaScrollbar>
            <ScrollAreaScrollbar orientation="horizontal">
              <ScrollAreaThumb />
            </ScrollAreaScrollbar>
            <ScrollAreaCorner />
          </ScrollArea>
        </div>
      )}
    </div>
  );
};
