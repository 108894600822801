import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { animated, useTransition, useSpring } from 'react-spring'
import { FaCheck, FaSyncAlt, FaExclamationTriangle } from 'react-icons/fa'

const Error = props => (
  <div className="flex items-center justify-center text-sm text-red" {...props}>
    <span className="mr-2">Error</span> <FaExclamationTriangle />
  </div>
)

const Saved = props => (
  <div className="flex items-center justify-center text-sm" {...props}>
    <span className="mr-2">Saved</span> <FaCheck />
  </div>
)

const Saving = props => {
  const { transform } = useSpring({
    loop: true,
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
    config: {
      duration: 1000,
    },
  })

  return (
    <div className="flex items-center justify-center text-sm" {...props}>
      <span className="mr-2">Saving...</span>{' '}
      <animated.span
        style={{
          transform,
        }}
      >
        <FaSyncAlt />
      </animated.span>
    </div>
  )
}

const Save = ({ saved }) => {
  const [visible, setVisible] = useState()
  const mountTransitions = useTransition(visible, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const AnimatedError = animated(Error)
  const AnimatedSaved = animated(Saved)
  const AnimatedSaving = animated(Saving)

  useEffect(() => {
    let timer
    if (!saved) setVisible(true)
    if (saved) {
      timer = setTimeout(() => {
        setVisible()
      }, 3000)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [saved])

  return mountTransitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.div
          className={`flex justify-center w-32 rounded-tl ${saved ? ' text-orange' : 'text-gray-800'}`}
          key={key}
          style={props}
        >
          {saved === true && <AnimatedSaved />}
          {saved === 'error' && <AnimatedError />}
          {!saved && <AnimatedSaving />}
        </animated.div>
      ),
  )
}

Save.propTypes = {
  saved: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

Save.defaultProps = {
  saved: true,
}

export { Save }
