import React from 'react'
import PropTypes from 'prop-types'

import { Loading } from './Loading'

const LoadingGlobal = ({ loading }) =>
  loading ? (
    <div className="fixed top-0 left-0 w-full h-screen bg-lighten-800 flex items-center justify-center z-50">
      <div className="w-64">
        <Loading loading />
      </div>
    </div>
  ) : null

LoadingGlobal.propTypes = {
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

LoadingGlobal.defaultProps = {
  loading: false,
}

export { LoadingGlobal }
