import React from 'react'
import PropTypes from 'prop-types'

import { Modal } from '../../Modal'

const StocktakeBeginModal = ({ errors, isOpen, loading, onClose, onSubmit }) => {
  return (
    <Modal
      closeModal={onClose}
      disabled={errors ? true : false}
      heading="Begin Counting Stock"
      loading={loading}
      isOpen={isOpen}
      onSubmit={() => onSubmit()}
      submitValue="Begin"
    >
      <div className="mt-4">
        <p>This will lock in stock levels for this vendor while you count stock.</p>
      </div>
      {errors && (
        <div className="text-sm mt-4 p-4 bg-orange-100 rounded border border-orange overflow-y-auto max-h-1/2">
          <p className="font-bold mb-2">Please fix the following first:</p>
          {errors.map(error => (
            <p key={error} dangerouslySetInnerHTML={{ __html: error }} />
          ))}
        </div>
      )}
    </Modal>
  )
}

StocktakeBeginModal.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  isOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

StocktakeBeginModal.defaultProps = {
  errors: null,
  isOpen: false,
  loading: false,
}

export { StocktakeBeginModal }
