import React from 'react'

import { errorMessageFromStatus } from '../common/errors'

type Props = {
  error: string | undefined
}

const ErrorMessage = ({error} : Props) => (
  <>
    <p className="text-2xl mt-8 font-bold">
      <code className="text-orange">400</code>
    </p>
    {error && (
      <div className="max-h-1/2 overflow-y-auto">
        <p key={0} className="mt-4">
          {error}
        </p>
      </div>
    )}
  </>
)

export { ErrorMessage }
