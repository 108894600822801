import { Invoice } from "../backend-v3/invoices";
import { Header } from "../components/Header";
import { InvoicesTable } from "../components/Layouts/Invoices/InvoicesTable";
import { useInvoices } from "../hooks/useInvoices";
import { location } from "./common";

const Invoices = (location: location) => {
  const { invoices, deleteInvoice, status, sendToXero, processingXero } = useInvoices();
  const query = new URLSearchParams(window.location.search).get("query");

  return (
    <div className="max-w-site mb-8">
      <Header crumbs={[{ to: "/invoices", value: "Invoices" }]} />

      <InvoicesTable
        invoices={invoices}
        deleteInvoice={deleteInvoice}
        sendToXero={sendToXero}
        status={status}
        processingXero={processingXero}
        locationFilterKey={query ? "invoice id" : undefined}
        locationQuery={query ? query : undefined}
      />
    </div>
  );

  return <span>Invoice</span>;
};

export { Invoices };
