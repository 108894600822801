import { useEffect, useState, useRef } from "react";
import { navigate } from "@reach/router";

import { useFeedback } from "./useFeedbackTs";
import { CONSTANTS } from "../common/constants";
import { getInvoice, getInvoices, Invoice, deleteInvoice as destroy, sendToXero as send } from "../backend-v3/invoices";

const useInvoices = () => {
  const isMounted = useRef(true);
  const [status, setStatus] = useState<"updating" | "fetching">();
  const [processingXero, setProcessingXero] = useState<{ [key: number]: boolean | undefined }>({});
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const { setError, setToast } = useFeedback();

  useEffect(() => {
    getData();
    return () => {
      isMounted.current = false;
    };
  }, []);

  async function getData() {
    setStatus("fetching");
    const invoices = await getInvoices();
    if (invoices.err) {
      setError(invoices.val.message);
      return;
    }
    setInvoices(invoices.val);
    setStatus(undefined);
  }

  async function deleteInvoice(invoiceId: number) {
    setStatus("updating");
    const res = await destroy(invoiceId);

    if (res.err) {
      setError(res.val.message);
      return;
    }

    if (!isMounted.current) return;
    setInvoices(prevInvoices => prevInvoices.filter(invoice => invoice.id !== invoiceId));
    setToast("Deleted!");
    setStatus(undefined);
  }

  async function sendToXero(invoiceId: number) {
    setProcessingXero(processingXero => {
      processingXero[invoiceId] = true;
      return processingXero;
    });
    const res = await send(invoiceId);
    if (res.err) {
      setError(res.val.message);
      return;
    }
    setInvoices(prev =>
      prev.map(invoice => (invoice.id === invoiceId ? { ...invoice, xero_invoice_id: res.val } : invoice))
    );
    setToast("Sent to Xero!");
    setProcessingXero(processingXero => {
      delete processingXero[invoiceId];
      return processingXero;
    });
  }

  return {
    invoices,
    deleteInvoice,
    status,
    sendToXero,
    processingXero,
  };
};

export { useInvoices };
