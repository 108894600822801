import React from 'react'
import PropTypes from 'prop-types'
import { FaTrash } from 'react-icons/fa'

import { Modal } from '../../Modal'

type Props = {
  isOpen: boolean,
  loading: boolean,
  onClose: () => void,
  onSubmit: () => void
}

const DeleteProductModal = ({ loading, isOpen, onClose, onSubmit, ...props }: Props ) => (
  <Modal
    closeModal={onClose}
    loading={loading}
    heading="Delete Variant From Order"
    isOpen={isOpen}
    onSubmit={onSubmit}
    submitIcon={<FaTrash />}
    submitValue="Delete"
    {...props}
  >
    <div className="mt-4">
      <p className="uppercase text-2xl mb-2 text-red">
        <strong>Woah, hold up!</strong>
      </p>

      <p>
        Only proceed if you have received an error about this specific variant while attempting to sync. Otherwise
        please cancel.
      </p>
    </div>
  </Modal>
)

export { DeleteProductModal }
