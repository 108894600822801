import React from 'react'
import PropTypes from 'prop-types'

import { CONSTANTS } from '../common/constants'

const Status = ({ className, status }) => {
  function getStatusClasses() {
    switch (status) {
      case CONSTANTS.STATUS.DRAFT:
        return 'border border-gray-300 text-gray-300'
      case CONSTANTS.STATUS.PARTIALLY_RECEIVED:
        return 'border border-orange text-orange'
      case CONSTANTS.STATUS.RECEIVED:
        return 'bg-orange text-white'
      case CONSTANTS.STATUS.SENT:
      default:
        return 'bg-black text-white'
    }
  }
  return (
    <div className={`inline-block px-4 py-1 rounded-full ${getStatusClasses()} ${className}`}>
      {status.toLowerCase().replace(/_/g, ' ')}
    </div>
  )
}

Status.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string.isRequired,
}

Status.defaultProps = {
  className: '',
}

export { Status }
