const CONSTANTS = {
  FIELD_TYPES: {
    FLOAT: "FLOAT",
    INT: "INT",
    MONEY: "MONEY",
    STRING: "STRING",
  },
  STATUS: {
    ARCHIVED: "archived",
    COUNTED: "counted",
    DELETED: "deleted",
    DRAFT: "draft",
    FULFILLED: "fulfilled",
    IN_PROGRESS: "in_progress",
    PARTIALLY_RECEIVED: "partially_received",
    RECEIVED: "received",
    REQUESTED: "requested",
    SENT: "sent",
  },
  KIND: {
    PURCHASE: "PURCHASE",
    TRANSFER: "TRANSFER",
  },
  TAX_MULTIPLIER: 0.15,
};

export { CONSTANTS };
